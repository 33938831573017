import React, { useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom-v5-compat";
import { OrderStatusesFlow, IAddress } from "Models/OrderModels";
import { fullTimeFormat, isEllipsisActive } from "Utils/utils";
import Icon from "../Shared/Icon";
import { isBrand, isFactory, isLabelMaker } from "Models/UserModels";
import { Tooltip } from "react-tooltip";
import ShippedPartShippedIconTooltip from "Components/Shared/ShippedPartShippedIconTooltip";
import sprite from "images/icons.svg";
import GlobalDispatch from "Dispatches/GlobalDispatch";

interface IStateProps {
	accountTypeId: number;
	order: any;
	selectedOrdersForBulkAction: any;
	refVar?: any;

	setLabelMakerData: (data: any) => void;
	setSelectedOrdersForBulkAction: (selectedOrdersForBulkAction: any) => void;
}

const OrdersListItem: React.FunctionComponent<IStateProps> = ({
	accountTypeId,
	order,
	selectedOrdersForBulkAction,
	refVar,

	setLabelMakerData,
	setSelectedOrdersForBulkAction,
}) => {
	const { fromOrders } = useContext(GlobalDispatch);

	const navigate = useNavigate();

	const shipToRef = useRef() as any;
	const refNumber = useRef<HTMLDivElement>(null);
	const noteRef = useRef<HTMLDivElement>(null);

	const {
		id,
		lot_number,
		status,
		brand_name,
		brand_id,
		last_edit_on,
		last_edit_by,
		note,
		factory_id,
		shipping_address,
		billing_address,
		preferred_shipping_method,
	} = order;

	const toggleOrderSelected = (
		id: number,
		orderStatus: any,
		billingAddress: IAddress,
		shippingAddress: IAddress,
		preferredShippingMethod: string,
		factoryId?: number,
		lotNumber?: string,
		brandId?: number,
		brandName?: string
	) => {
		let localSelectedOrdersForBulkAction = { ...selectedOrdersForBulkAction };

		if (localSelectedOrdersForBulkAction[id]) {
			delete localSelectedOrdersForBulkAction[id];
		} else {
			localSelectedOrdersForBulkAction[id] = {
				status: orderStatus,
				billingAddress,
				shippingAddress,
				preferredShippingMethod,
				lotNumber,
				brandId,
				brandName,
			};
			if (isBrand(accountTypeId)) {
				localSelectedOrdersForBulkAction[id].factoryId = factoryId;
			}
			localSelectedOrdersForBulkAction.orderStatus = orderStatus;
		}

		setSelectedOrdersForBulkAction(localSelectedOrdersForBulkAction);
	};

	const changeFromOrders = () => {
		fromOrders.current = true;
	};

	const labelMakerOrder = {
		orderId: id,
		lotNumber: lot_number,
		status,
	};

	const ORDER_LINK =
		status >= OrderStatusesFlow.PLACED.code
			? `../order/preview/${id}`
			: status === OrderStatusesFlow.REVIEW_REQUESTED.code
			? `../order/approve/${id}`
			: `../order/${id}`;

	return (
		<div style={{ borderBottom: "2px solid #f4f4f4" }} ref={refVar}>
			<div>
				<article
					className={`list-item ${
						isLabelMaker(accountTypeId)
							? "list-item--label-makers-orders"
							: isFactory(accountTypeId)
							? "list-item--factory-orders"
							: "list-item--orders"
					}`}
				>
					{/* Bulk action checkbox  */}
					{!isLabelMaker(accountTypeId) && (
						<div className="list-item__details">
							<div className="form-check">
								<input
									className="form-check__input"
									style={{ marginRight: "25px" }}
									type="checkbox"
									id={`checkbox${id}`}
									checked={selectedOrdersForBulkAction[id] ? true : false}
									onChange={() =>
										toggleOrderSelected(
											id,
											status,
											billing_address,
											shipping_address,
											preferred_shipping_method,
											factory_id,
											lot_number,
											brand_id,
											brand_name
										)
									}
								/>
								<label
									className="form-check__label"
									htmlFor={`checkbox${id}`}
								></label>
							</div>
						</div>
					)}

					{/* Reference number + Note */}
					<Link
						className="list-item__details"
						to={ORDER_LINK}
						onClick={() => {
							changeFromOrders();
						}}
					>
						<div
							className="truncate mr--sm"
							ref={refNumber}
							data-tooltip-id={`refNumber${id}`}
							data-event="none"
							data-iscapture="true"
						>
							{lot_number}
							{/* Empty div added to prevent default title behavior on Safari with text-overflow: ellipsis  */}
							<div></div>
						</div>
						<div className="noteLink">
							<small
								className="truncate mr--sm"
								ref={noteRef}
								data-tooltip-id={`noteRef${id}`}
								data-event="none"
								data-iscapture="true"
							>
								{note}
								{/* Empty div added to prevent default title behavior on Safari with text-overflow: ellipsis  */}
								<div></div>
							</small>
						</div>
					</Link>

					{!isBrand(accountTypeId) && (
						<Link
							className="list-item__details"
							to={ORDER_LINK}
							onClick={() => {
								changeFromOrders();
							}}
						>
							{brand_name}
						</Link>
					)}

					{/* Last edit by/on */}
					<Link
						className="list-item__details"
						to={ORDER_LINK}
						onClick={() => {
							changeFromOrders();
						}}
					>
						{last_edit_by}
						<div className="small">
							{last_edit_on ? fullTimeFormat(last_edit_on) : last_edit_on}
						</div>
					</Link>

					{/* Ship To */}
					<Link
						className="list-item__details"
						to={ORDER_LINK}
						onClick={() => {
							changeFromOrders();
						}}
					>
						<div className="flex">
							<div className="truncate">
								{shipping_address?.company_name || "---"}
							</div>
							{shipping_address?.company_name && (
								<div className="flex-center-secondary-axis flex-1">
									<svg
										className="icon ml--xs mr--base"
										data-tooltip-id={`shipTo${id}`}
										data-event="none"
										data-iscapture="true"
										ref={shipToRef}
										style={{
											fill: "rgba(52,52,52,0.5)",
										}}
									>
										<use xlinkHref={`${sprite}#icon-info-rounded`} />
									</svg>
								</div>
							)}
						</div>
					</Link>

					{/* Label Makers actions */}
					{isLabelMaker(accountTypeId) && (
						<div
							className="mt--xs"
							style={{ display: "flex", height: "100%", alignItems: "center" }}
						>
							<div
								onClick={() => setLabelMakerData({ labelMakerOrder, status })}
								className="labelMakerSetDataButton"
								role="button"
								title="Update status"
							>
								<Icon name="cog" />
							</div>

							<div
								className="list-item__details"
								style={{ flex: 1 }}
								onClick={() => navigate(ORDER_LINK)}
							></div>
						</div>
					)}

					{/* Status + Label Maker options */}
					<div
						className="list-item__details ordersStatusListItem"
						style={{ alignItems: "flex-end" }}
						onClick={() => {
							changeFromOrders();
							navigate(ORDER_LINK);
						}}
					>
						<div style={{ display: "flex" }}>
							{status === OrderStatusesFlow.OPEN.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.OPEN.name}
								</span>
							)}

							{status === OrderStatusesFlow.REVIEW_REQUESTED.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.REVIEW_REQUESTED.name}
								</span>
							)}

							{status === OrderStatusesFlow.CHANGE_REQUESTED.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.CHANGE_REQUESTED.name}
								</span>
							)}

							{status === OrderStatusesFlow.APPROVED.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.APPROVED.name}
								</span>
							)}

							{status === OrderStatusesFlow.PLACED.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.PLACED.name}
								</span>
							)}
							{status === OrderStatusesFlow.IN_PRODUCTION.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.IN_PRODUCTION.name}
								</span>
							)}

							{status === OrderStatusesFlow.PARTIALLY_SHIPPED.code && (
								<div className="flex-center-secondary-axis">
									<ShippedPartShippedIconTooltip id={id} status={status} />
									<span className="statusQuiet">
										<Icon name="exclamation" className="statusIcon" />
										{OrderStatusesFlow.PARTIALLY_SHIPPED.name}
									</span>
								</div>
							)}

							{status === OrderStatusesFlow.SHIPPED.code && (
								<div className="flex-center-secondary-axis">
									<ShippedPartShippedIconTooltip id={id} status={status} />
									<span className="statusQuiet">
										{OrderStatusesFlow.SHIPPED.name}
									</span>
								</div>
							)}

							{status === OrderStatusesFlow.COMPLETED.code && (
								<span className="statusQuiet statusCompleted">
									{OrderStatusesFlow.COMPLETED.name}
								</span>
							)}
							{status === OrderStatusesFlow.CANCELED.code && (
								<span className="statusQuiet voidOrder">
									{OrderStatusesFlow.CANCELED.name}
								</span>
							)}
							{status === OrderStatusesFlow.GENERATING_LAYOUTS.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.GENERATING_LAYOUTS.name}
								</span>
							)}
							{status === OrderStatusesFlow.GENERATING_LAYOUTS_FAILED.code && (
								<span className="statusQuiet voidOrder">
									GEN. LAYOUTS FAILED
								</span>
							)}
							{status === OrderStatusesFlow.PRINT_READY.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.PRINT_READY.name}
								</span>
							)}
							{status === OrderStatusesFlow.PRINTING.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.PRINTING.name}
								</span>
							)}
							{status === OrderStatusesFlow.PRINTING_STOPPED.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.PRINTING_STOPPED.name}
								</span>
							)}
							{status === OrderStatusesFlow.PARTIALLY_PRINTED.code && (
								<span className="statusQuiet">
									{OrderStatusesFlow.PARTIALLY_PRINTED.name}
								</span>
							)}
							{status === OrderStatusesFlow.PRINTED.code && (
								<span className="statusQuiet statusCompleted">
									{OrderStatusesFlow.PRINTED.name}
								</span>
							)}
							{status === OrderStatusesFlow.PRINTING_CANCELED.code && (
								<span className="statusQuiet voidOrder">
									{OrderStatusesFlow.PRINTING_CANCELED.name}
								</span>
							)}
						</div>
					</div>
				</article>
			</div>

			{/* Ship to tooltip */}
			{shipping_address?.company_name && (
				<Tooltip
					id={`shipTo${id}`}
					className="reactTooltip whiteBackground"
					variant="light"
					offset={5}
					clickable={true}
					place="bottom"
				>
					<div className="flex" style={{ marginBottom: "15px" }}>
						<div>
							<strong>Bill to address:</strong>
							{billing_address &&
								Object.values(billing_address).map(
									(item: any, index: number) => {
										return <div key={item + index}>{item}</div>;
									}
								)}
							<br />
						</div>
						<div style={{ marginLeft: "15px" }}>
							<strong>Shipping address:</strong>
							{shipping_address &&
								Object.values(shipping_address).map(
									(item: any, index: number) => {
										return <div key={item + index}>{item}</div>;
									}
								)}
						</div>
					</div>
					<strong>Preferred shipping method: </strong>
					{preferred_shipping_method}
				</Tooltip>
			)}

			{/* Reference number tooltip */}
			{isEllipsisActive(refNumber) && (
				<Tooltip
					id={`refNumber${id}`}
					className="reactTooltip whiteBackground"
					variant="light"
					offset={5}
					clickable={true}
					place="bottom"
					delayShow={400}
				>
					<div>
						<div>{lot_number}</div>
					</div>
				</Tooltip>
			)}

			{/* Note tooltip */}
			{isEllipsisActive(noteRef) && (
				<Tooltip
					id={`noteRef${id}`}
					className="reactTooltip whiteBackground"
					variant="light"
					offset={5}
					clickable={true}
					place="bottom"
					delayShow={400}
				>
					<div style={{ maxWidth: "300px" }}>
						<div>{note}</div>
					</div>
				</Tooltip>
			)}
		</div>
	);
};

export default OrdersListItem;
