import React, { useState, useContext } from "react";

import CareInstructions from "./FabricAndCare/CareInstructions";
import FabricContent from "./FabricAndCare/FabricContent";
import HelpTooltip from "Components/Shared/HelpTooltip";
import SuggestedCareInstructions from "./FabricAndCare/SuggestedCareInstructions";
import { IDefaultOption } from "Models/OrderModels";
import OrderDispatch from "Dispatches/OrderDispatch";

interface IProps {
	fabricContentList: IDefaultOption[];
	careInstructionsList: IDefaultOption[];
	additionalComponentsList: IDefaultOption[];
	currentOrderItemIndex: number;
	componentsList: IDefaultOption[];
	fibersList: IDefaultOption[];
}

const FabricAndCare: React.FunctionComponent<IProps> = ({
	fabricContentList,
	careInstructionsList,
	additionalComponentsList,
	currentOrderItemIndex,
	componentsList,
	fibersList,
}) => {
	const [showCareInstructionsModal, setShowCareInstructionsModal] =
		useState(false);

	const { orderItem } = useContext(OrderDispatch);
	const { fabricContent } = orderItem;
	const fabricContentObject = fabricContentList.find(
		(fabricContentObject: IDefaultOption) =>
			fabricContentObject.id === fabricContent
	);

	return (
		<>
			<div
				className="pretitle flex-center-secondary-axis"
				data-testid="careContentSection"
			>
				<span style={{ lineHeight: 1 }}>Care Content</span>
				<HelpTooltip helpFor="ContentComposition" />
			</div>
			<fieldset className="box box--light box--shadowed mb--md">
				<FabricContent
					fabricContentList={fabricContentList}
					setShowCareInstructionsModal={setShowCareInstructionsModal}
					additionalComponentsList={additionalComponentsList}
					currentOrderItemIndex={currentOrderItemIndex}
					componentsList={componentsList}
					fibersList={fibersList}
				/>

				<SuggestedCareInstructions
					careInstructionsList={careInstructionsList}
					showCareInstructionsModal={showCareInstructionsModal}
					setShowCareInstructionsModal={setShowCareInstructionsModal}
					fabricContentText={
						fabricContentObject ? fabricContentObject.name : ""
					}
				/>
				<CareInstructions careInstructionsList={careInstructionsList} />
			</fieldset>
		</>
	);
};

export default FabricAndCare;
