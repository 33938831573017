import React, { useContext, useEffect, useState } from "react";
import DropDownSelection from "../SharedComponents/DropDownSelection";
import LabelTemplateDispatch from "Dispatches/LabelTemplateDispatch";
import Icon from "Components/Shared/Icon";
import { ITemplateState } from "Models/TemplateModels";

const DefaultSettings: React.FunctionComponent = () => {
	const { template, setTemplate, checkGeneralLabelSettings, showMissingData } =
		useContext(LabelTemplateDispatch);

	const isDataMissing = (itemToCheck: string) => {
		return showMissingData && checkGeneralLabelSettings().includes(itemToCheck);
	};

	const [defaultSettings, setDefaultSettings] = useState({
		capitalization:
			template?.sectionDefaultSettings?.capitalization >= 0
				? template?.sectionDefaultSettings?.capitalization
				: -1,
		align: template?.sectionDefaultSettings?.align || "",
		splitCharacter:
			template?.sectionDefaultSettings?.splitCharacter >= 0
				? template?.sectionDefaultSettings?.splitCharacter
				: -1,
		separator: template?.sectionDefaultSettings?.separator || 0,
	});

	useEffect(() => {
		setTemplate((prevTemplate: ITemplateState) => ({
			...prevTemplate,
			sectionDefaultSettings: defaultSettings,
		}));
	}, [defaultSettings]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="flex-2 ml--sm">
			{/* <div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>Default settings</span>
				<HelpTooltip helpFor="FontSettings" />
			</div> */}
			<fieldset className="flex">
				<div className="pos-relative mr--base">
					<div
						className={`alignment-title pos-absolute ${
							isDataMissing("Alignment") && "text--danger"
						}`}
					>
						Alignment
					</div>
					<div
						className={`flex mt--base ${
							isDataMissing("Alignment") && "alignment-danger"
						}`}
					>
						<div
							className={`alignment-icons-wrapper ${
								defaultSettings.align === "left" && "selected"
							}`}
							onClick={(e) => {
								setDefaultSettings({ ...defaultSettings, align: "left" });
							}}
						>
							<Icon name="left-alignment" className="alignment-icons" />
						</div>
						<div
							className={`alignment-icons-wrapper  ml--base ${
								defaultSettings.align === "center" && "selected"
							}`}
							onClick={(e) => {
								setDefaultSettings({ ...defaultSettings, align: "center" });
							}}
						>
							<Icon name="center-alignment" className="alignment-icons" />
						</div>
						<div
							className={`alignment-icons-wrapper  ml--base ${
								defaultSettings.align === "right" && "selected"
							}`}
							onClick={(e) => {
								setDefaultSettings({ ...defaultSettings, align: "right" });
							}}
						>
							<Icon name="right-alignment" className="alignment-icons" />
						</div>
					</div>
				</div>
				<div className="mr--sm">
					<DropDownSelection
						name="defaultCapitalization"
						options={[
							"ALL CAPITALIZED",
							"FIRST LETTER CAPITALIZED",
							"EVERY FIRST LETTER CAPITALIZED",
						]}
						state={defaultSettings}
						setState={setDefaultSettings}
						item={defaultSettings}
					/>
				</div>

				<div className="mr--sm">
					<DropDownSelection
						name="defaultSplit character"
						options={["NEW LINE", "/", "COMMA + SPACE", "DOT + SPACE"]}
						state={defaultSettings}
						setState={setDefaultSettings}
						item={defaultSettings}
					/>
				</div>

				<div
					className={`form-group ${isDataMissing("Separator") && "has-danger"}`}
				>
					<input
						type="number"
						className="form-control"
						name="defaultSeparator"
						id="defaultSeparator"
						value={
							defaultSettings.separator <= 0 ? "" : defaultSettings.separator
						}
						data-empty={false}
						onChange={(e) => {
							setDefaultSettings({
								...defaultSettings,
								separator: Number(e.target.value),
							});
						}}
						min={0}
						step={0.1}
					/>
					<label className="form-control-label" htmlFor="defaultSeparator">
						Separator (mm)
					</label>
				</div>
			</fieldset>
		</div>
	);
};

export default DefaultSettings;
