import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import OrderDispatch from "Dispatches/OrderDispatch";

// import { EndpointPrefix, isLabelMaker } from "Models/UserModels"

import { IOrderState, ORDER_PREVIEW_STATES } from "Models/OrderModels";

import useDataApi from "Hooks/fetchHook";
import { transformOrderItemForPost } from "Utils/utils";

interface IProps {
	drawOne: boolean;
	order: IOrderState;
	currentOrderItemIndex: number;

	setOrder: (order: IOrderState) => void;
	setIsDrawOneDisabled: (isDrawOneDisabled: boolean) => void;
}

const BackendDrawing: React.FunctionComponent<IProps> = ({
	drawOne,
	order,
	currentOrderItemIndex,
	setOrder,
	setIsDrawOneDisabled,
}) => {
	const { showPreviewOrder, setShowPreviewOrder } = useContext(OrderDispatch);

	const useGetLabelImages = useDataApi();

	/* **************************************************** */
	useEffect(() => {
		async function localUpdateLabelImages() {
			if (
				showPreviewOrder === ORDER_PREVIEW_STATES.GENERATING_LAYOUTS &&
				!useGetLabelImages.isLoading
			) {
				const localOrderItems = drawOne
					? [transformOrderItemForPost(order.orderItems[currentOrderItemIndex])]
					: order.orderItems.map((item) => transformOrderItemForPost(item));

				const { labelTemplate, lotNumber, brandId } = order;

				useGetLabelImages.doFetch(
					`/draw`,
					{
						order_items: localOrderItems,
						label_template_id: labelTemplate?.id,
						lot_number: lotNumber,
						brand_id: brandId,
					},
					"POST"
				);
			}
		}
		localUpdateLabelImages();
	}, [showPreviewOrder, drawOne]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************************* Draw Layouts *********************************** */
	useEffect(() => {
		const { data } = useGetLabelImages;
		if (data && data.message) {
			let localOrder = order;
			let { orderItems } = localOrder;

			if (drawOne) {
				if (!data.message[0]) {
					toast.error("Invalid response from the server!");
					return;
				}
				orderItems[currentOrderItemIndex] = {
					...orderItems[currentOrderItemIndex],
					labelImages: data.message[0],
				};
			} else {
				for (let i = 0; i < orderItems.length; ++i) {
					if (!data.message[i]) {
						toast.error("Invalid response from the server.");
						return;
					}
					orderItems[i] = {
						...orderItems[i],
						labelImages: data.message[i],
					};
				}
			}

			setOrder({ ...localOrder, orderItems });
			setShowPreviewOrder(ORDER_PREVIEW_STATES.SHOW);
			setIsDrawOneDisabled(false);
		}
	}, [useGetLabelImages.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetLabelImages;

		if (error) {
			toast.error(
				`Unable to get label images. ${
					error[0].toUpperCase() + error.slice(1)
				}.`
			);
			setIsDrawOneDisabled(false);
		}
	}, [useGetLabelImages.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* **************************************************** */

	return <div></div>;
};

export default BackendDrawing;
