import React from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import Icon from "../../Shared/Icon";

interface IProps {
	selectedItemsIds: any;
	allItems: any;
	itemId: string;
	testId: string;

	removeItem: (item: any) => void;
	changeOrderOfItems: (ids: any) => void;
}

const SelectedItemsList: React.FunctionComponent<IProps> = ({
	selectedItemsIds,
	allItems,
	removeItem,
	itemId,
	testId,
	changeOrderOfItems,
}) => {
	const handleOnDragEnd = (result: any) => {
		if (!result.destination) return;

		const items = Array.from(selectedItemsIds);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		changeOrderOfItems(items);
	};

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId="selectedItems">
				{(provided) => (
					<div
						className="uploaded-images"
						style={{ marginTop: "20px" }}
						data-testid={`${testId}-selectedItemsList-parent`}
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{selectedItemsIds.map((itemId: number, index: number) => {
							const item = allItems.find((item: any) => item.id === itemId);
							let itemName = undefined as any;
							if (item) {
								itemName = item.name;
							}
							return (
								<Draggable key={itemId} draggableId={`${itemId}`} index={index}>
									{(provided) => (
										<div
											id={`${index}`}
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="box box--bordered navigation__menu__item uploaded-images__figure"
											style={{
												flexBasis: "100%",
												justifyContent: "space-between",
												padding: "5px",
												cursor: "move",
												marginTop: "-2px",
												backgroundColor: "#ffffff",
												...provided.draggableProps.style,
											}}
											data-testid={`${testId}-selectedItemsList-${itemName}`}
										>
											<span>{itemName}</span>

											<button
												className="uploaded-images__remove btn-no-style"
												style={{ position: "relative" }}
												onClick={() => removeItem(itemId)}
												data-testid={`remove-${testId}-selectedItemsList-${itemName}`}
											>
												<Icon name="cross-rounded-filled" />
											</button>
										</div>
									)}
								</Draggable>
							);
						})}

						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default SelectedItemsList;
