import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";

import LabelImagesPreview from "Components/Shared/LabelImagesPreview";
import Loading from "Components/Shared/Loading";
import OrderApproveHeader from "Components/OrderApprove/OrderApproveHeader";

import useDataApi from "Hooks/fetchHook";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import { IOrderState, OrderStatusesFlow } from "Models/OrderModels";
import { EndpointPrefix, isLabelMaker } from "Models/UserModels";

import "./styles/OrderApprove.css";
import {
	transformOrderItemForPost,
	transformOrderItemForUseOnFrontend,
} from "Utils/utils";

const OrderApprove = () => {
	const {
		setError,
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const navigate = useNavigate();

	const useGetOrder = useDataApi();
	const useGetLabelImages = useDataApi();

	const [order, setOrder] = useState<IOrderState>({} as IOrderState);
	const [showRejectNote, setShowRejectNote] = useState(false);

	const { orderId: orderIdFromParams } = useParams() as any;

	/* ************************* Load an existing order and draw images on backend ********************************* */
	useEffect(() => {
		useGetOrder.doFetch(
			`/${EndpointPrefix[account_type_id]}/orders/${orderIdFromParams}`
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetOrder;
		if (data && data.message) {
			const {
				order_items,
				status,
				brand_id,
				log,
				label_template,
				lot_number,
				extra_info,
				billing_address,
				shipping_address,
				preferred_shipping_method,
				placed_by,
				note,
			} = data.message;

			if (!label_template?.id || !brand_id) {
				setError({
					title: "Something Went Wrong",
					text: "Received incomplete data. Please try again later.",
					primary: {
						text: "Back to orders",
						action: () => navigate(`/orders`),
					},
					secondary: {
						text: "Refresh page",
						action: () =>
							navigate(`/order/${orderIdFromParams}`, { replace: true }),
					},
				});
			}

			if (order_items) {
				const orderItemsForState = order_items.map((orderItem: any) =>
					transformOrderItemForUseOnFrontend(orderItem)
				);
				setOrder({
					orderId: orderIdFromParams,
					labelTemplate: label_template,
					lotNumber: lot_number,
					orderItems: orderItemsForState,
					brandId: brand_id,
					status,
					log,
					extraInfo: extra_info,
					billingAddress: billing_address,
					shippingAddress: shipping_address,
					preferredShippingMethod: preferred_shipping_method,
					placedBy: placed_by,
					note,
				});
			}

			if (status !== OrderStatusesFlow.REVIEW_REQUESTED.code) {
				navigate(`/order/${orderIdFromParams}`);
			}
		}
	}, [useGetOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetOrder;
		if (error) {
			setError({
				title: "Order Not Found",
				text: "",
				primary: {
					text: "Back to orders",
					action: () => navigate(`/orders`),
				},
				secondary: {
					text: !isLabelMaker(account_type_id) ? "Create a New Order" : "",
					action: () => navigate(`/order/`),
				},
			});
		}
	}, [useGetOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		async function localUpdateLabelImages() {
			const localOrderItems = order.orderItems.map((item: any) =>
				transformOrderItemForPost(item)
			);

			const { labelTemplate, lotNumber, brandId } = order;

			useGetLabelImages.doFetch(
				`/draw`,
				{
					order_items: localOrderItems,
					label_template_id: labelTemplate?.id,
					lot_number: lotNumber,
					brand_id: brandId,
				},
				"POST"
			);
		}
		if (Object.keys(order).length > 0) localUpdateLabelImages();
	}, [order.orderId]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************************* Draw Layouts *********************************** */
	useEffect(() => {
		const { data } = useGetLabelImages;
		if (data && data.message) {
			let localOrder = order;
			let { orderItems } = localOrder;

			for (let i = 0; i < orderItems.length; ++i) {
				if (!data.message[i]) {
					toast.error("Invalid response from the server.");
					return;
				}
				orderItems[i] = {
					...orderItems[i],
					labelImages: data.message[i],
				};
			}

			setOrder({ ...localOrder, orderItems });
		}
	}, [useGetLabelImages.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetLabelImages;

		if (error) {
			toast.error(
				`Unable to get label images. ${
					error[0].toUpperCase() + error.slice(1)
				}.`
			);
		}
	}, [useGetLabelImages.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************************************************** */

	return (
		<div className="orderPreviewContainer">
			<Loading
				show={
					useGetOrder.isLoading ||
					useGetLabelImages.isLoading ||
					(order?.orderItems &&
						order?.orderItems[0].labelImages &&
						!order?.orderItems[0].labelImages.length)
				}
				text={`Loading...`}
				imgClass="block-center"
				divClass=""
			/>
			{!useGetOrder.isLoading &&
				!useGetLabelImages.isLoading &&
				order?.orderItems &&
				order?.orderItems[0].labelImages &&
				order?.orderItems[0].labelImages.length > 0 && (
					<div className="orderPreviewContent">
						<OrderApproveHeader
							showRejectNote={showRejectNote}
							order={order}
							setShowRejectNote={setShowRejectNote}
						/>
						<div
							className={` imagePreviewTopMarginNoNote  ${
								showRejectNote && "imagePreviewTopMarginWithNote"
							}`}
						>
							<div>
								<div className="orderApproveTitle">
									<h1>Viewing {`order ${order?.lotNumber}`}</h1>
								</div>
								<div className="quickPreviewContainer">
									<LabelImagesPreview
										drawOne={false}
										order={order}
										currentOrderItemIndex={0}
										reorderStyleNumber={() => 0}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
		</div>
	);
};

export default OrderApprove;
