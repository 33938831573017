import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../react-query/constants";
import {
	FETCH_GET_PARAMS,
	URL_BASE,
	CustomError,
} from "../../Models/QueryModels";
import useErrorHandler from "Hooks/queryHooks/useErrorHandler";
import { toast } from "react-toastify";
import { USER_ACCOUNT_TYPE_VALUES } from "../../Models/UserModels";

async function manageError(response: Response) {
	if (!response.ok) {
		let responseJSON = await response.json();
		throw new CustomError(responseJSON.message, response.status);
	}
	return response;
}

let url = "";

async function getLanguages(
	account_type_id: USER_ACCOUNT_TYPE_VALUES
): Promise<any> {
	url = `${URL_BASE.address}/brand/data/lang`;
	const result = await fetch(url, FETCH_GET_PARAMS).then(manageError);
	return await result.json();
}

export function useFetchLanguages(
	account_type_id: USER_ACCOUNT_TYPE_VALUES,
	setError: any
): any {
	const fallback: any = [];
	const errorHandler = useErrorHandler();

	const {
		data = fallback,
		isLoading,
		fetchStatus,
		isError,
		error,
	} = useQuery({
		queryKey: [QUERY_KEYS.BRANDS],
		queryFn: () => getLanguages(account_type_id),
		...{
			enabled: true,
			gcTime: 86400000, // 1 day
			staleTime: 86400000, // 1 day
		},
	});

	if (isError) {
		let customError = error as CustomError;
		let errorMessage = errorHandler(
			customError.status,
			customError.message,
			url
		);
		toast.error(`Unable to get list of languages. ${errorMessage}`);
		setError({
			title: "Something Went Wrong",
			text: "Please try again later",
			primary: {
				text: "",
				action: () => {},
			},
			secondary: {
				text: "",
				action: () => {},
			},
		});
	}
	return {
		languages: data.message,
		isLoadingLanguages: isLoading && fetchStatus !== "idle",
	};
}
