import Icon from "Components/Shared/Icon";
import Loading from "Components/Shared/Loading";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import useDataApi from "Hooks/fetchHook";
import { OrderStatusesFlow } from "Models/OrderModels";
import { EndpointPrefix } from "Models/UserModels";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { fullTimeFormat } from "Utils/utils";

interface IStateProps {
	id: number;
	status: number;
}

const ShippedPartShippedIconTooltip = ({ id, status }: IStateProps) => {
	const shipmentDetailsRef = useRef() as any;

	const useGetShipmentDetails = useDataApi();
	const { user } = useContext(GlobalDispatch);

	const [shipmentDetails, setShipmentDetails] = useState([
		{
			shipper_name: "",
			tracking_code: 0,
			tracking_url: "",
			comment: "",
			sentAt: "",
		},
	]) as any;

	useEffect(() => {
		const { data } = useGetShipmentDetails;
		if (data && data.message) {
			setShipmentDetails(data.message);
		}
	}, [useGetShipmentDetails.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetShipmentDetails;
		if (error) {
			toast.error(`Unable to get shipment details. ${error}`);
		}
	}, [useGetShipmentDetails.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const linkHandler = (e: any) => {
		e.stopPropagation();
	};

	return (
		<div data-tooltip-id={`truckIcon${id}`}>
			<div ref={shipmentDetailsRef} data-event="none" data-iscapture="true">
				<Icon
					name={
						status === OrderStatusesFlow.SHIPPED.code
							? "truck-blue"
							: "truck-gray"
					}
					className="truckIcon"
				/>
			</div>

			{/* Shipment details tooltip */}
			{(status === OrderStatusesFlow.PARTIALLY_SHIPPED.code ||
				status === OrderStatusesFlow.SHIPPED.code) && (
				<Tooltip
					id={`truckIcon${id}`}
					className="reactTooltip whiteBackground"
					variant="light"
					offset={5}
					clickable={true}
					place="bottom"
					afterShow={() => {
						useGetShipmentDetails.doFetch(
							`/${EndpointPrefix[user.account_type_id]}/orders/shipping/${id}`
						);
					}}
					delayHide={500}
				>
					<div style={{ width: "250px" }}>
						<Loading
							show={useGetShipmentDetails.isLoading}
							text={"Loading..."}
							imgClass="block-center"
							divClass="main__content"
						/>
						{useGetShipmentDetails.isLoading && (
							<div style={{ textAlign: "center" }}>Getting info...</div>
						)}
						{!useGetShipmentDetails.isLoading &&
							shipmentDetails[0]?.shipper_name && (
								<div>
									{status === OrderStatusesFlow.PARTIALLY_SHIPPED.code && (
										<div className="upcase popupHeading">
											{OrderStatusesFlow.PARTIALLY_SHIPPED.name}
										</div>
									)}
									{shipmentDetails.map((shipment: any, index: number) => {
										const {
											id,
											shipper_name,
											tracking_code,
											tracking_url,
											comment,
											last_edit_on: sentAt,
										} = shipment;
										return (
											<div
												key={id}
												className={`${
													index < shipmentDetails.length - 1 &&
													"shippedPartShippedTooltipSpacing"
												}`}
											>
												<p>
													<span className="font-weight-500">Shipped with:</span>{" "}
													{shipper_name}
												</p>
												<p>
													<span className="font-weight-500">Sent at:</span>{" "}
													{fullTimeFormat(sentAt)}
												</p>
												{comment && (
													<p className="">
														<span className="font-weight-500">Comment:</span>{" "}
														{comment}
													</p>
												)}
												{tracking_url && tracking_code && (
													<a
														onClick={linkHandler}
														href={`${tracking_url}${tracking_code}`}
														target="_blank"
														rel="noopener noreferrer"
													>
														Track order
													</a>
												)}
											</div>
										);
									})}
								</div>
							)}
					</div>
				</Tooltip>
			)}
		</div>
	);
};

export default ShippedPartShippedIconTooltip;
