import React, { useState, useEffect, useRef, useContext } from "react";
import LabelTemplateDispatch from "Dispatches/LabelTemplateDispatch";

function useOutsideAlerter(ref: any, setShowSelection: any) {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowSelection(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]); // eslint-disable-line react-hooks/exhaustive-deps
}

interface IProps {
	name: string;
	options: string[];
	state: any;
	setState: any;
	item: any;
}

const DropDownSelection: React.FunctionComponent<IProps> = ({
	name,
	options,
	state,
	setState,
	item,
}) => {
	let id: number = 0;

	const { checkGeneralLabelSettings, showMissingData } = useContext(
		LabelTemplateDispatch
	);

	const [showSelection, setShowSelection] = useState(false);
	const [focusOnSearchList, setFocusOnSearchList] = useState(false);
	const [selectedItem, setSelectedItem] = useState(0);

	const isDataMissing = (itemToCheck: string) => {
		if (
			name === "Fonts" ||
			name === "defaultCapitalization" ||
			name ||
			"defaultSplit character"
		) {
			let itemToCheckFiltered = () => {
				if (name === "Fonts") {
					return "Font family";
				} else if (name === "defaultCapitalization") {
					return "Capitalization";
				} else if (name === "defaultSplit character") {
					return "Split Character";
				}
			};
			return (
				showMissingData &&
				checkGeneralLabelSettings().includes(itemToCheckFiltered())
			);
		}
	};

	const listRef = useRef(null) as any;

	useEffect(() => {
		if (focusOnSearchList) {
			listRef?.current?.firstElementChild?.focus();
			setSelectedItem(0);
		}
	}, [focusOnSearchList, listRef]);

	useOutsideAlerter(listRef, setShowSelection);

	const onKeyDownInput = (event: any) => {
		if (event.key === "ArrowDown") {
			event.preventDefault();
			setFocusOnSearchList(true);
		}
		if (event.key === "Tab") {
			setFocusOnSearchList(false);
			setShowSelection(false);
		}
		if (event.key === "Enter") {
			event.preventDefault();
		}
	};

	const onKeyDownResults = (e: any) => {
		e.preventDefault();
		const items = listRef.current.children;
		let focusedElement = items[0];

		if (e.key === "ArrowDown") {
			if (selectedItem < items.length - 1) {
				setSelectedItem(selectedItem + 1);
				focusedElement = items[selectedItem + 1];
			} else if (selectedItem === items.length - 1) {
				setSelectedItem(0);
				focusedElement = items[0];
			}
		} else if (e.key === "ArrowUp") {
			if (selectedItem > 0) {
				setSelectedItem(selectedItem - 1);
				focusedElement = items[selectedItem - 1];
			}
		}

		if (e.key === "Enter") {
			focusedElement = e.target;
			if (name.includes("default")) {
				if (name.includes("Capitalization")) {
					setState({
						...state,
						capitalization: focusedElement.getAttribute("value"),
					});
				} else {
					setState({
						...state,
						splitCharacter: focusedElement.getAttribute("value"),
					});
				}
			} else if (
				!name.includes("Capitalization") &&
				!name.includes("Split character")
			)
				setState({ ...state, value: focusedElement.getAttribute("value") });
			else if (name.includes("Capitalization")) {
				let tmp = [...state];
				let index = tmp.findIndex((i: any) => i.id === item.id);
				tmp[index].capitalization = focusedElement.getAttribute("value");
				setState(tmp);
			} else {
				let tmp = [...state];
				let index = tmp.findIndex((i: any) => i.id === item.id);
				tmp[index].splitCharacter = focusedElement.getAttribute("value");
				setState(tmp);
			}
			setShowSelection(false);
			setFocusOnSearchList(false);
		}

		if (focusedElement) {
			focusedElement.focus();
		}
	};

	return (
		<>
			<div className={`form-group ${isDataMissing(name) && "has-danger"}`}>
				<figure
					id="incrementalSearchInput"
					className={
						(item && item.capitalization !== "") ||
						(!item && state.style !== "") ||
						(item && item.splitCharacter !== "")
							? "uploaded-images__figure"
							: ""
					}
				>
					<input
						type="text"
						id={!item ? "selection" : "selection" + name + item.id}
						name={!item ? "selection" : "selection" + name + item.id}
						className="form-control textInput"
						style={{ backgroundColor: "white" }}
						data-empty={
							!item
								? state.style === ""
								: name.includes("Capitalization")
								? item.capitalization === ""
								: item.splitCharacter === ""
						}
						value={
							!item
								? state.style
								: name.includes("Capitalization")
								? options[item.capitalization] || ""
								: options[item.splitCharacter] || ""
						}
						onClick={(e) => setShowSelection(true)}
						onKeyDown={(e) => onKeyDownInput(e)}
						onChange={(e) => {}}
						readOnly
					/>
					<label
						className="form-control-label"
						htmlFor={!item ? "selection" : "selection" + name + item.id}
					>
						{name.includes("Capitalization")
							? "Capitalization"
							: name.includes("Split character")
							? "Split character"
							: "Font"}
					</label>
					{/* <button
						className="uploaded-images__remove btn-no-style"
						id={!item ? "clear" : "clear" + item.id}
						style={{ top: "auto" }}
						onClick={(e) => {
							if (!item) setState({ ...state, value: "" });
							else if (name.includes("Capitalization")) {
								let tmp = [...state];
								let index = tmp.findIndex((i: any) => i.id === item.id);
								tmp[index].capitalization = "";
								let input = document.getElementById(
									!item ? "selection" : "selection" + name + item.id
								) as HTMLInputElement;
								input.value = "";
								setState(tmp);
							} else {
								let tmp = [...state];
								let index = tmp.findIndex((i) => i.id === item.id);
								tmp[index].splitCharacter = "";
								setState(tmp);
							}
						}}
					>
						<Icon name="cross-rounded-filled" className=" searchClearIcon" />
					</button> */}
				</figure>
				{showSelection && (
					<ul
						style={{ width: "100%" }}
						className="dropdown-menu dropdown-menu show"
						ref={listRef}
						onKeyDown={(e) => onKeyDownResults(e)}
					>
						{options.map((option) => {
							return (
								<li
									key={id++}
									value={option}
									tabIndex={-1}
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										if (name.includes("default")) {
											if (name.includes("Capitalization")) {
												setState({
													...state,
													capitalization: options.findIndex(
														(o: any) => o === option
													),
												});
											} else {
												setState({
													...state,
													splitCharacter: options.findIndex(
														(o: any) => o === option
													),
												});
											}
										} else if (name.includes("Fonts")) {
											setState({ ...state, style: option });
										} else if (name.includes("Capitalization")) {
											let index = state.findIndex((i: any) => i.id === item.id);
											state[index].capitalization = options.findIndex(
												(o: any) => o === option
											);
											setState(state);
										} else {
											let index = state.findIndex((i: any) => i.id === item.id);
											state[index].splitCharacter = options.findIndex(
												(o: any) => o === option
											);
											setState(state);
										}

										setShowSelection(false);
									}}
								>
									{option}
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</>
	);
};

export default DropDownSelection;
