import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import useDataApi from "Hooks/fetchHook";
import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import {
	IDefaultOption,
	IOrderState,
	OrderStatusesFlow,
} from "Models/OrderModels";

import GlobalDispatch from "Dispatches/GlobalDispatch";

import IncrementalSearchInput from "../../OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import IncrementalSearchResults from "../../OrderSharedComponents/IncrementalSearch/IncrementalSearchResults";
import Loading from "Components/Shared/Loading";
import { EndpointPrefix, isBrand } from "Models/UserModels";

interface IProps {
	order: IOrderState;

	setOrder: (order: any) => void;
}

const ChooseFactory: React.FunctionComponent<IProps> = ({
	order,
	setOrder,
}) => {
	const { user } = useContext(GlobalDispatch);
	const { factoryId, factoryName, status } = order;

	const useGetFactoriesForBrand = useDataApi();

	const [showFactoryInput, setShowFactoryInput] = useState(false);
	const [showFactoryName, setShowFactoryName] = useState(false);
	const [factoriesForBrand, setFactoriesForBrand] = useState([]);
	const [chosenFactory, setChosenFactory] = useState(undefined) as any;
	const [focusOnSearchList, setFocusOnSearchList] = useState(false);

	const incrementalSearchHookProps =
		useIncrementalSearchHook(factoriesForBrand);

	const { setInputText, showList, setShowList } = incrementalSearchHookProps;

	useEffect(() => {
		const { account_type_id } = user;
		if (isBrand(account_type_id) && status === OrderStatusesFlow.OPEN.code) {
			useGetFactoriesForBrand.doFetch(
				`/${EndpointPrefix[account_type_id]}/data/factories`
			);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (factoryId) {
			setShowFactoryInput(false);
			setShowFactoryName(true);
			setChosenFactory({ id: factoryId, name: factoryName });
			setInputText(factoryName);
		}
	}, [factoryId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetFactoriesForBrand;
		if (data && data.message) {
			setFactoriesForBrand(data.message);
		}
	}, [useGetFactoriesForBrand.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetFactoriesForBrand;
		if (error) {
			toast.error(`Unable to get factories for brand. ${error}`);
		}
	}, [useGetFactoriesForBrand.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const onEditFactory = () => {
		setShowFactoryInput(true);
		setShowFactoryName(false);
	};

	const onChooseItemCallback = (item: any) => {
		setInputText(item?.name);
		setChosenFactory(item);
		setOrder({ ...order, factoryId: item.id, factoryName: item.name });
		setShowFactoryInput(false);
		setShowFactoryName(true);
	};

	const onChooseItemFromList = (item: any) => {
		onChooseItemCallback(item);
		setShowList(false);
	};

	const onSaveFactory = (item: IDefaultOption) => {
		if (!item && !showList) {
			setOrder({ ...order, factoryId: undefined });
			setChosenFactory(undefined);
			setShowFactoryInput(false);
			setInputText("");
		} else if (item?.id && item.id !== factoryId) {
			onChooseItemCallback(item);
		}
	};

	const onChangeInputText = () => {
		setOrder({ ...order, factoryId: undefined });
		setChosenFactory(undefined);
	};

	return (
		<>
			<Loading
				show={useGetFactoriesForBrand.isLoading}
				text={`Loading...`}
				imgClass="imgLoading center"
				divClass="col-sm-12"
			/>

			{!useGetFactoriesForBrand.isLoading && (
				<>
					{!showFactoryInput && !chosenFactory && (
						<div className="orderInfoInput">
							<div className="upcase text--quiet" id="orderInfoHeading">
								Factory
							</div>
							<button
								className="btn-no-style btn-link"
								onClick={onEditFactory}
								data-testid="chooseFactory"
							>
								Choose Factory
							</button>
						</div>
					)}

					{showFactoryInput && (
						<div
							className="form-group mb-sm"
							style={{
								maxHeight: "40px",
								margin: 0,
								marginTop: "22px",
								marginBottom: "12px",
							}}
						>
							<IncrementalSearchInput
								labelText="Factory"
								name="factory"
								setFocusOnSearchList={setFocusOnSearchList}
								onChangeInputText={onChangeInputText}
								onInputBlur={onSaveFactory}
								incrementalSearchHookProps={incrementalSearchHookProps}
							/>

							<IncrementalSearchResults
								focusOnSearchList={focusOnSearchList}
								testId="chooseFactory"
								onChooseItemCallback={onChooseItemFromList}
								incrementalSearchHookProps={incrementalSearchHookProps}
							/>
						</div>
					)}

					{factoryId && showFactoryName && (
						<div className="orderInfoInput">
							<div
								className="upcase text--quiet"
								style={{
									fontSize: "10px",
									letterSpacing: 0,
									paddingTop: "2px",
								}}
							>
								Factory
							</div>
							<small id="flexElement">
								<div
									data-testid="chooseFactoryValue"
									className="orderInfoValue"
								>
									{chosenFactory ? chosenFactory.name : ""}
								</div>
								{status === OrderStatusesFlow.OPEN.code && (
									<button
										className="btn-no-style btn-link"
										onClick={onEditFactory}
										data-testid="edit-chooseFactory"
									>
										Edit
									</button>
								)}
							</small>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default ChooseFactory;
