import React, {
	useRef,
	useEffect,
	useCallback,
	useContext,
	useState,
} from "react";
import { toast } from "react-toastify";

import {
	DEFAULT_STYLE_NAME,
	MAX_ITEMS_IN_ORDER,
	IDefaultOption,
} from "Models/OrderModels";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import OrderDispatch from "Dispatches/OrderDispatch";

import useOnClickOutside from "Hooks/outsideClickHook";
import useDataApi from "Hooks/fetchHook";
import { EndpointPrefix, isFactory } from "Models/UserModels";
import Loading from "Components/Shared/Loading";
import Icon from "Components/Shared/Icon";
import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import sprite from "images/icons.svg";
import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";
import {
	checkMissingTranslationByName,
	isDpp,
	languagesForTranslationsCheck,
	splitContentCompositionTranslations,
	transformOrderItemForUseOnFrontend,
} from "Utils/utils";
import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";

interface IProps {
	addedStyleNumbers: string[];

	setShowAddExistingStyleModal: (showAddExistingStyleModal: boolean) => void;
	setCurrentOrderItemIndex: (orderItemIndex: number) => void;
}

const AddExistingStyleModal: React.FunctionComponent<IProps> = ({
	addedStyleNumbers,

	setShowAddExistingStyleModal,
	setCurrentOrderItemIndex,
}) => {
	const { setOrderItem, order, setOrder } = useContext(OrderDispatch);

	const useGetStyleNumbers = useDataApi();
	const useGetTranslations = useDataApi();

	const { initialValues } = useFetchInitialValues(order, order.brandId);
	const [newStyleMarking, setNewStyleMarking] = useState(false);

	const markedStyleRef = useRef(null) as any;

	const { user, setMissingTranslations, missingTranslations } =
		useContext(GlobalDispatch);

	const { account_type_id } = user;

	const [styleNumbers, setStyleNumbers] = useState([]) as any;
	const { orderItems, labelTemplate, brandId } = order;

	const [showSearch, setShowSearch] = useState(false);

	const [
		filteredListWithoutSelectedStyles,
		setFilteredListWithoutSelectedStyles,
	] = useState<any[]>([]);

	const incrementalSearchHookProps = useIncrementalSearchHook(styleNumbers);

	const { inputText, setInputText, showList, filteredList } =
		incrementalSearchHookProps;

	const timer = useRef(null) as any;

	const doubledStyles = orderItems.filter(
		(orderItem: { styleNumber: string }) => {
			return (
				orderItem.styleNumber.toLowerCase() !== "" &&
				orderItem.styleNumber.toLowerCase() === inputText.toLowerCase()
			);
		}
	);

	const {
		missingMadeIn,
		missingCareInstructions,
		missingContentComposition,
		missingAdditionalComponents,
	} = missingTranslations;

	const useSaveFabricContent = useDataApi();

	/* ************************* On Mount START ************************** */
	useEffect(() => {
		if (
			(labelTemplate?.settings?.form_settings?.units?.hide_size &&
				orderItems.length >= MAX_ITEMS_IN_ORDER.WITHOUT_SIZE) ||
			(!labelTemplate?.settings?.form_settings?.units?.hide_size &&
				orderItems.length >= MAX_ITEMS_IN_ORDER.WITH_SIZE)
		) {
			alert(
				"Maximum number of items in order reached. If you need to order more items please create a new order."
			);
			setShowAddExistingStyleModal(false);
			return;
		}

		setShowSearch(!showSearch);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************************* On Mount END ************************** */

	/* ************************* Get list of existing style numbers START ************************** */

	useEffect(() => {
		searchStyleNumber();
	}, [inputText]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useSaveFabricContent;
		if (data.message) {
			const { missingTranslations: missingContentComposition } = data.message;

			if (missingContentComposition) {
				setMissingTranslations({
					...missingTranslations,
					missingContentComposition,
				});
			} else {
				queryClient.invalidateQueries({
					queryKey: [QUERY_KEYS.INITIAL_VALUES, account_type_id, brandId],
				});
			}
		}
	}, [useSaveFabricContent.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveFabricContent;
		if (error) {
			toast.error(`Unable to get content composition translations. ${error}`);
		}
	}, [useSaveFabricContent.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetStyleNumbers;
		if (error) {
			toast.error(`Unable to get existing style numbers. ${error}`);
		}
	}, [useGetStyleNumbers.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetStyleNumbers;
		if (data && data.message) {
			const styleNumbers = [];
			const { styles } = data.message;

			for (const style of styles) {
				const {
					style_number,
					style_description,
					season,
					made_in,
					fabric_content,
					care_instructions,
					additional_components,
					include_qr_code,
				} = style;
				styleNumbers.push({
					/* For incremental search */
					id: style.style_number,
					name: style.style_number,
					/* ********************** */
					style_number,
					style_description,
					season,
					made_in: made_in?.id,
					made_in_name: made_in?.name,
					fabric_content: fabric_content?.id,
					fabric_content_name: fabric_content?.name,
					care_instructions: care_instructions.map(
						(careInstruction: any) => careInstruction.id
					),
					care_instruction_names: care_instructions.map(
						(careInstruction: any) => careInstruction.name
					),
					additional_components: additional_components.map(
						(additionalComponent: any) => additionalComponent.id
					),
					additional_component_names: additional_components.map(
						(additionalComponent: any) => additionalComponent.name
					),
					include_qr_code,
				});
			}
			setStyleNumbers(styleNumbers);
			filterSearchedStyles(styleNumbers);
		}
	}, [useGetStyleNumbers.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************************* Get list of existing style numbers END ************************** */

	const modalRef = useRef(null) as any;

	const selectedStyleListRef = useRef() as any;

	const searchStyleNumber = () => {
		clearTimeout(timer.current);
		if (inputText.length > 2) {
			if (doubledStyles.length === 0) {
				timer.current = setTimeout(() => {
					const brandIdForFactory = isFactory(account_type_id)
						? `&brandId=${brandId}`
						: "";

					useGetStyleNumbers.doFetch(
						`/${
							EndpointPrefix[account_type_id]
						}/styles?filter=${encodeURIComponent(
							inputText
						)}${brandIdForFactory}`
					);
				}, 500);
			}
		} else {
			setStyleNumbers([]);
			setFilteredListWithoutSelectedStyles([]);
		}
	};

	const onDeleteOrderItem = (index: number, deletedStyleNumber: string) => {
		setNewStyleMarking(false);
		if (window.confirm("Are you sure you want to delete this order item?")) {
			const selectedOrderItem = order.orderItems[index];
			if (selectedOrderItem) {
				const items = order.orderItems.filter(
					(item: any, i: number) => i !== index
				);
				setOrder({ ...order, orderItems: items });
				setOrderItem(items[0]);
				setCurrentOrderItemIndex(0);
			}

			// check if current search style list contains deleted style
			let deletedStyle = filteredList.find((item: any) => {
				return item.name === deletedStyleNumber;
			});

			if (deletedStyle) {
				setFilteredListWithoutSelectedStyles([
					...filteredListWithoutSelectedStyles,
					deletedStyle,
				]);
			}
		}
	};

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCancelAddExistingStyleModal();
		}
	};

	const showMissingTranslationsModal =
		(missingContentComposition &&
			Object.values(missingContentComposition).length > 0) ||
		(missingCareInstructions &&
			Object.values(missingCareInstructions).length > 0) ||
		(missingMadeIn && Object.values(missingMadeIn).length > 0) ||
		(missingAdditionalComponents &&
			Object.values(missingAdditionalComponents).length > 0);

	const onCancelAddExistingStyleModal = () => {
		if (!showMissingTranslationsModal) {
			setShowAddExistingStyleModal(false);
		}
	};

	useEffect(() => {
		if (
			(doubledStyles.length > 0 || newStyleMarking) &&
			markedStyleRef &&
			markedStyleRef.current
		) {
			markedStyleRef.current.scrollIntoView();
		}

		doubledStyles.length > 0 && setFilteredListWithoutSelectedStyles([]);
	}, [inputText, doubledStyles.length, orderItems.length, newStyleMarking]);

	useEffect(() => {
		setNewStyleMarking(false);
	}, [doubledStyles.length]);

	const onInputBlur = (item: any) => {
		if (!item && !showList) {
			setInputText("");
		}
	};

	const checkAvailableTranslations = (item: any, languages: any) => {
		let missingComponentTranslations = {};
		let missingFabricTranslations = {};
		let missingAdditionalComponentsTranslations = checkMissingTranslationByName(
			initialValues.additionalComponents,
			item.additional_component_names,
			languages
		);
		let missingCareInstructionTranslations = checkMissingTranslationByName(
			initialValues.careInstructions,
			item.care_instruction_names,
			languages
		);
		let missingMadeInTranslations = checkMissingTranslationByName(
			initialValues.madeIns,
			[item.made_in_name],
			languages
		);
		let missingContentCompositionTranslations = checkMissingTranslationByName(
			initialValues.fabricContent,
			[item.fabric_content_name],
			languages
		);

		if (Object.keys(missingContentCompositionTranslations).length > 0) {
			({
				missingComponent: missingComponentTranslations,
				missingFabric: missingFabricTranslations,
			} = splitContentCompositionTranslations(
				item.fabric_content_name,
				initialValues.components,
				initialValues.fibers,
				languages
			));
		}
		return {
			missingAdditionalComponentsTranslations,
			missingCareInstructionTranslations,
			missingMadeInTranslations,
			missingComponentTranslations,
			missingFabricTranslations,
			missingContentCompositionTranslations,
		};
	};

	const onChooseItemCallback = (item: any) => {
		setNewStyleMarking(false);
		if (
			(labelTemplate?.settings?.form_settings?.units?.hide_size &&
				orderItems.length >= MAX_ITEMS_IN_ORDER.WITHOUT_SIZE) ||
			(!labelTemplate?.settings?.form_settings?.units?.hide_size &&
				orderItems.length >= MAX_ITEMS_IN_ORDER.WITH_SIZE)
		) {
			alert(
				"Maximum number of items in order reached. If you need to order more items please create a new order."
			);
			return;
		}

		const { all_languages, default_languages, qr_phase } =
			labelTemplate?.settings?.default_values;

		const defaultSymbolsType =
			labelTemplate?.settings?.default_values?.symbols_type;

		const addedOrderItem = transformOrderItemForUseOnFrontend(item);
		addedOrderItem.includeQRCode = item?.include_qr_code;
		addedOrderItem.allLabelLanguages = all_languages;
		addedOrderItem.chosenLabelLanguages = default_languages || all_languages;

		if (defaultSymbolsType) {
			addedOrderItem.extraInfo = { symbolsType: defaultSymbolsType };
		}

		let languagesArrayForTranslationsCheck = languagesForTranslationsCheck(
			addedOrderItem.allLabelLanguages,
			addedOrderItem.chosenLabelLanguages,
			isDpp(qr_phase)
		);

		let {
			missingAdditionalComponentsTranslations,
			missingCareInstructionTranslations,
			missingMadeInTranslations,
			missingComponentTranslations,
			missingFabricTranslations,
			missingContentCompositionTranslations,
		} = checkAvailableTranslations(item, languagesArrayForTranslationsCheck);

		let createFabricContent = () => {
			const brandIdForFactory = isFactory(account_type_id)
				? `?brandId=${brandId}`
				: "";
			useSaveFabricContent.doFetch(
				`/${EndpointPrefix[account_type_id]}/translations/fabricContent${brandIdForFactory}`,
				{
					fabricContent: item.fabric_content_name,
					languages: languagesArrayForTranslationsCheck,
				},
				"POST"
			);
		};

		// check if content composition id is present in initial data
		// if not present send request to backend to check if there is translation available
		// this situation could happen when new style is imported and initial data are not refetched after
		let contentCompositionInInitialData: IDefaultOption | undefined = undefined;
		contentCompositionInInitialData = initialValues.fabricContent.find(
			(option: IDefaultOption) => option.id === item.fabric_content
		);
		if (contentCompositionInInitialData) {
			if (
				Object.keys(missingComponentTranslations).length > 0 ||
				Object.keys(missingFabricTranslations).length > 0
			) {
				addedOrderItem.fabricContent = -1;
			} else if (
				Object.keys(missingContentCompositionTranslations).length > 0
			) {
				createFabricContent();
			}
		} else {
			createFabricContent();
		}

		if (
			Object.keys(missingMadeInTranslations).length > 0 ||
			Object.keys(missingCareInstructionTranslations).length > 0 ||
			Object.keys(missingAdditionalComponentsTranslations).length > 0 ||
			Object.keys(missingComponentTranslations).length > 0 ||
			Object.keys(missingFabricTranslations).length > 0
		)
			setMissingTranslations({
				missingMadeIn: missingMadeInTranslations,
				missingCareInstructions: missingCareInstructionTranslations,
				missingAdditionalComponents: missingAdditionalComponentsTranslations,
				missingComponents: missingComponentTranslations,
				missingFibers: missingFabricTranslations,
			});

		const itemWithAllInfo = {
			...addedOrderItem,
			labelImages: [],
			units: [
				{
					upc: "",
					color: "",
					size: "",
					quantity: order.labelTemplate.settings.form_settings.quantity.minimum,
					blackLabel:
						labelTemplate?.settings?.default_values?.default_black_label ||
						false,
				},
			],
		};

		setOrderItem(itemWithAllInfo);
		setCurrentOrderItemIndex(orderItems?.length);
		const newItems = [...orderItems, { ...itemWithAllInfo }];
		setOrder({ ...order, orderItems: newItems });
		setShowSearch(false);
		setNewStyleMarking(true);
	};

	const filterSearchedStyles = (arrayToFilter: any[]) => {
		// arrayToFilter - original search list from backend ( left - show off )
		// filteredListWithoutSelectedStyles - arrayTofilter without selected syles ( left - show on )
		// addedStyleNumbers - selected styles ( right - show on )

		// create Object with same key/value pairs from addedStyleNumbers array
		const obj: { [key: string]: string } = addedStyleNumbers.reduce(
			(acc: any, cur: any) => ({ ...acc, [cur]: cur }),
			{}
		);

		let arr = arrayToFilter.filter((arrayItem: any) => {
			return arrayItem.name !== obj[arrayItem.name];
		});

		setFilteredListWithoutSelectedStyles(arr);
	};

	useEffect(() => {
		filterSearchedStyles(filteredListWithoutSelectedStyles);
	}, [orderItems.length]); // eslint-disable-line react-hooks/exhaustive-deps

	useOnClickOutside(
		modalRef,
		useCallback(onCancelAddExistingStyleModal, [onCancelAddExistingStyleModal])
	); // eslint-disable-line react-hooks/exhaustive-deps

	let numberOfRefsForDoubledStyle = 0;

	return (
		<div
			className="modal flex-center-both-axis"
			onKeyDown={onKeyDown}
			style={{ zIndex: 1000 }}
		>
			<div
				className="modal-content modal-content-add-existing-style show"
				ref={modalRef}
				tabIndex={1}
			>
				<div className="flex row reverse" style={{ height: "40px" }}>
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label="Close"
						onClick={onCancelAddExistingStyleModal}
						style={{ position: "relative" }}
					>
						<Icon name="cross-rounded" />
					</button>
				</div>
				<div className="center mt--base ">
					<h1>Add existing style</h1>
				</div>

				<div className="flex">
					{/* filtered styles */}

					<div
						style={{
							flex: "6",
						}}
					>
						<div style={{ height: "71px" }}>
							<div className="form-group-add-style" style={{ marginTop: "0" }}>
								<IncrementalSearchInput
									labelText="&nbsp;&nbsp;Search style number"
									name="styleNumbers"
									type="search"
									onInputBlur={onInputBlur}
									incrementalSearchHookProps={incrementalSearchHookProps}
								/>
							</div>
						</div>

						<div className="box--bordered">
							{useGetStyleNumbers.isLoading && (
								<div
									style={{ height: "250px", padding: "15px" }}
									className="flex-center-both-axis"
								>
									<Loading
										show={true}
										text={`Loading...`}
										imgClass="imgLoading center"
										divClass="col-sm-12"
									/>
								</div>
							)}
							{!useGetStyleNumbers.isLoading && (
								<div
									className="overflow-y-auto"
									style={{ height: "250px", padding: "15px" }}
								>
									{filteredListWithoutSelectedStyles &&
									filteredListWithoutSelectedStyles.length > 0 ? (
										filteredListWithoutSelectedStyles.map(
											(item: any, index: any) => {
												return (
													<div
														className="list-item-with-action"
														key={item.style_number}
														style={
															useGetTranslations.isLoading
																? { cursor: "initial" }
																: undefined
														}
														onClick={() => {
															if (!useGetTranslations.isLoading)
																onChooseItemCallback(item);
														}}
													>
														<div
															className=""
															key={`${index}-${item.styleNumber}`}
														>
															{item.style_number}
														</div>
													</div>
												);
											}
										)
									) : (
										<div>
											{doubledStyles.length > 0
												? doubledStyles.length === 1
													? "Style is already added to the list."
													: "Styles are already added to the list."
												: "No results"}
										</div>
									)}
								</div>
							)}
						</div>
					</div>

					<div style={{ flex: "1" }}></div>

					{/* selected styles */}
					<div
						style={{
							flex: "6",
						}}
					>
						<div style={{ height: "71px" }}>
							<div
								style={{
									borderBottom: "2px solid #dfdfdf",
									fontSize: "20px",
									paddingTop: "9px",
								}}
							>
								Selected styles ({addedStyleNumbers.length})
							</div>
						</div>
						<div className="box--bordered">
							<div
								className="overflow-y-auto"
								style={{
									height: "250px",
									padding: "15px",
								}}
								ref={selectedStyleListRef}
							>
								{useGetTranslations.isLoading && (
									<div>
										<Loading
											show={useGetTranslations.isLoading}
											text={`Loading...`}
											imgClass="imgLoading center"
											divClass="col-sm-3"
										/>
									</div>
								)}
								{addedStyleNumbers.map((styleNumber: string, index: number) => {
									let styleNumberOrDefaultStyleName = styleNumber
										? styleNumber
										: DEFAULT_STYLE_NAME;

									let markStyleDoubled = false;

									if (doubledStyles.length > 0) {
										markStyleDoubled =
											doubledStyles[0].styleNumber.toLowerCase() ===
											styleNumber.toLowerCase();
									}

									const newStyle = addedStyleNumbers.length - 1 === index;

									if (markStyleDoubled) {
										numberOfRefsForDoubledStyle++;
									}

									const markStyleNew = newStyle && newStyleMarking;

									const attachRef =
										(markStyleDoubled && numberOfRefsForDoubledStyle === 1) ||
										markStyleNew;

									return (
										<div
											className={`list-item-with-action ${
												markStyleDoubled && "doubled-style"
											} ${markStyleNew && "new-style"}`}
											style={{ cursor: "auto" }}
											key={`${index}-${styleNumber}`}
											ref={attachRef ? markedStyleRef : null}
											onAnimationEnd={() => {
												setNewStyleMarking(false);
											}}
										>
											<span className="strong">
												{styleNumberOrDefaultStyleName}
											</span>
											<svg
												className="flex-center-secondary-axis action-list-item"
												onClick={() => onDeleteOrderItem(index, styleNumber)}
												role="button"
												style={{
													height: "16px",
													width: "16px",
													fill: "red",
												}}
											>
												<use
													xlinkHref={`${sprite}#icon-${"cross-rounded-filled"}`}
												/>
											</svg>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<button
					className={"button button--primary mt--md"}
					type="button"
					onClick={() => onCancelAddExistingStyleModal()}
				>
					Done
				</button>
			</div>
		</div>
	);
};

export default AddExistingStyleModal;
