import { createContext } from "react";

interface IWizardDispatch {
	wizardState: any;

	setWizardState: React.Dispatch<React.SetStateAction<any>>;
	setIsNextOrFinishDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	setOnFinish: React.Dispatch<React.SetStateAction<any>>;
	setIsLoadingNextOrFinishButton: React.Dispatch<React.SetStateAction<boolean>>;
	setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WizardDispatch = createContext<IWizardDispatch>(
	{} as IWizardDispatch
);

export default WizardDispatch;
