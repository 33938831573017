import React, { useContext, useEffect, useState } from "react";

import OrderDispatch from "Dispatches/OrderDispatch";

import {
	checkIfFabricContentHasFurOrigin,
	checkIfFabricContentHasInsulationOrFeathers,
	deepCopy,
} from "Utils/utils";
import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";

import FurOrigin from "./FabricContentExtraInfo/FurOrigin";
import InsulationWeight from "./FabricContentExtraInfo/InsulationWeight";
import SterilizationPermitNumber from "./FabricContentExtraInfo/SterilizationPermitNumber";

const FabricContentExtraInfo: React.FunctionComponent = () => {
	const { orderItem, setOrderItem, order } = useContext(OrderDispatch);

	const { initialValues } = useFetchInitialValues(order, order.brandId);

	const {
		fabricContent,
		selectedAdditionalComponents,
		extraInfo: orderItemExtraInfo,
	} = orderItem;

	const [showInsulationWeight, setShowInsulationWeight] = useState(false);
	const [showSterilizationPermitNumber, setShowSterilizationPermitNumber] =
		useState(false);
	const [showFurOrigin, setShowFurOrigin] = useState(false);

	useEffect(() => {
		if (initialValues.fabricContent.length === 0) {
			return;
		}

		const [insulationWeightExist, hasFeathers] =
			checkIfFabricContentHasInsulationOrFeathers(
				fabricContent,
				initialValues.fabricContent
			);

		// Check if the fabric content has insulation
		if (insulationWeightExist) {
			setShowInsulationWeight(true);
		} else {
			setShowInsulationWeight(false);
			const localExtraInfo = deepCopy(orderItemExtraInfo);
			if (localExtraInfo?.insulationWeight) {
				localExtraInfo.insulationWeight = undefined;
				setOrderItem({ extraInfo: localExtraInfo });
			}
		}

		// Check if the fabric content has feathers
		if (hasFeathers) {
			setShowSterilizationPermitNumber(true);
		} else {
			setShowSterilizationPermitNumber(false);
			const localExtraInfo = deepCopy(orderItemExtraInfo);
			if (localExtraInfo?.sterilizationPermitNumber) {
				localExtraInfo.sterilizationPermitNumber = undefined;
				setOrderItem({ extraInfo: localExtraInfo });
			}
		}
	}, [fabricContent, initialValues.fabricContent]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (initialValues.fabricContent.length > 0) {
			if (
				checkIfFabricContentHasFurOrigin(
					fabricContent,
					selectedAdditionalComponents,
					initialValues.fabricContent,
					initialValues.additionalComponents
				)
			) {
				setShowFurOrigin(true);
			} else {
				setShowFurOrigin(false);
				const localExtraInfo = deepCopy(orderItemExtraInfo);
				if (localExtraInfo?.furOrigin) {
					localExtraInfo.furOrigin = undefined;
					setOrderItem({ extraInfo: localExtraInfo });
				}
			}
		}
	}, [
		fabricContent,
		selectedAdditionalComponents,
		initialValues.fabricContent,
		initialValues.additionalComponents,
		orderItemExtraInfo,
		setOrderItem,
	]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div style={{ marginTop: "20px" }}>
			{showFurOrigin && <FurOrigin />}
			{showInsulationWeight && <InsulationWeight />}
			{showSterilizationPermitNumber && <SterilizationPermitNumber />}
		</div>
	);
};

export default FabricContentExtraInfo;
