import { FC, useContext, useEffect, useState } from "react";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import OrderDispatch from "Dispatches/OrderDispatch";
import WizardDispatch from "Dispatches/WizardDispatch";

import ChooseDetailsOption from "./Components/ChooseDetailsOption";
import Icon from "Components/Shared/Icon";

import { useFetchShippingMethods } from "Hooks/queryHooks/useFetchShippingMethods";

import { IAddress } from "Models/OrderModels";
import { isFactory } from "Models/UserModels";
import { addressJSONToString } from "Utils/utils";

interface IProps {
	addresses: IAddress[];
	showAddressForm: boolean;

	setShowAddressForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderPlaceWizardShippingDetails: FC<IProps> = ({
	addresses,
	showAddressForm,

	setShowAddressForm,
}) => {
	const {
		user: { account_type_id },
		setError,
	} = useContext(GlobalDispatch);

	const {
		setIsNextOrFinishDisabled,
		wizardState,
		setWizardState,
		setIsModalActive,
	} = useContext(WizardDispatch);

	const { shippingMethods } = useFetchShippingMethods(
		account_type_id,
		setError
	);

	const { order } = useContext(OrderDispatch);

	const getDefaultAddress = () => {
		return addresses.find((address: IAddress) => address.default === true);
	};

	const [billingAddress, setBillingAddress] = useState(undefined) as any;
	const [shippingAddress, setShippingAddress] = useState(undefined) as any;
	const [shippingMethod, setShippingMethod] = useState(undefined) as any;

	useEffect(() => {
		if (showAddressForm) {
			setIsModalActive(true);
		} else setIsModalActive(false);
	}, [showAddressForm]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			wizardState?.billingAddress &&
			wizardState?.shippingAddress &&
			wizardState?.shippingMethod
		) {
			setBillingAddress(wizardState.billingAddress);
			setShippingAddress(wizardState.shippingAddress);
			setShippingMethod(wizardState.shippingMethod);
		} else {
			const defaultAddress = getDefaultAddress();

			const defaultAddressString = addressJSONToString(defaultAddress);

			if (defaultAddress) {
				setBillingAddress({
					value: defaultAddress,
					name: defaultAddressString,
				});
			}

			if (defaultAddress) {
				setShippingAddress({
					value: defaultAddress,
					name: defaultAddressString,
				});
			}
		}
	}, [addresses.length]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setWizardState((prevWizardState: any) => {
			return {
				...prevWizardState,
				billingAddress,
				shippingAddress,
				shippingMethod,
			};
		});

		if (!billingAddress || !shippingAddress || !shippingMethod) {
			setIsNextOrFinishDisabled(true);
		} else {
			setIsNextOrFinishDisabled(false);
		}
	}, [billingAddress, shippingAddress, shippingMethod]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!billingAddress || !shippingAddress || !shippingMethod) {
			setIsNextOrFinishDisabled(true);
		} else {
			setIsNextOrFinishDisabled(false);
		}
	}, [billingAddress, shippingAddress, shippingMethod]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div style={{ height: "500px" }}>
			<div className="mt--base">
				<h1 className="text--lg mb--base">Shipping & Billing Details</h1>
				{(!order.factoryId ||
					(order.factoryId && isFactory(account_type_id))) && (
					<div
						className="button button-xs"
						id="addStyleNumberButton"
						data-toggle="drawer"
						style={{ marginBottom: "10px", width: "180px" }}
						onClick={() => setShowAddressForm(true)}
					>
						<div className="blueIcon">
							<Icon className="icon mr--xs valign-middle" name="plus-rounded" />
						</div>
						<span className="strong">Add New Address</span>
					</div>
				)}
				<fieldset className="box--light">
					<div className="shipping-modal-addresses-wrapper mt--base">
						<div className="flex between-lg">
							<div className="flex-1 mr--base">
								<ChooseDetailsOption
									detail={billingAddress}
									inputLabelText="Choose Bill-to Address"
									options={addresses.map((address: IAddress) => ({
										name: addressJSONToString(address),
										value: address,
									}))}
									detailName="billingAddress"
									setDetail={setBillingAddress}
									setShowAddressForm={setShowAddressForm}
								/>
							</div>
							<div className="flex-1 ml--base">
								<ChooseDetailsOption
									detail={shippingAddress}
									inputLabelText="Choose Ship-to Address"
									options={addresses.map((address: IAddress) => ({
										name: addressJSONToString(address),
										value: address,
									}))}
									detailName="shippingAddress"
									setDetail={setShippingAddress}
									setShowAddressForm={setShowAddressForm}
								/>
							</div>
						</div>

						<div className="shipping-method">
							<span className="heading--choose-option text--quiet upcase">
								Shipping Method
							</span>
							<div className="pos-relative">
								<select
									style={{
										background: "none",
										padding: "6px 0 8px",
									}}
									className="form-control shipping-method-select"
									value={shippingMethod?.name}
									onChange={(e) => {
										setShippingMethod(
											e.target.value ? { name: e.target.value } : undefined
										);
									}}
								>
									<option value={undefined}></option>
									{shippingMethods.map((shippingMethod: string) => {
										return (
											<option style={{ padding: "5px" }} key={shippingMethod}>
												{shippingMethod}
											</option>
										);
									})}
								</select>
								<span className="shipping-method-select-change">Change</span>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export default OrderPlaceWizardShippingDetails;
