import { FC, useContext, useEffect, useRef, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom-v5-compat";
import { useIsFetching } from "@tanstack/react-query";
import { toast } from "react-toastify";

import GlobalDispatch from "Dispatches/GlobalDispatch";

import Loading from "Components/Shared/Loading";
import Icon from "Components/Shared/Icon";

import useDataApi from "Hooks/fetchHook";
import useWindowSize from "Hooks/windowSizeHook";
import { useFetchHelpVideoNames } from "Hooks/queryHooks/useFetchHelpVideoNames";
import usePageTitle from "Hooks/pageTitleHook";

import { EndpointPrefix } from "Models/UserModels";

const HelpVideos: FC = () => {
	const [autoplay, setAutoplay] = useState(true);
	const [currentVideoNameWidth, setCurrentVideoNameWidth] = useState(0);

	const videoElementRef = useRef<any>(null);
	const helpVideoTitle = useRef<any>(null);

	const [width, height] = useWindowSize();

	const location = useLocation();

	let locationArr = location.pathname.split("/");

	let videoId = locationArr[3];

	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const navigate = useNavigate();

	const useGetLinkForVideo = useDataApi();

	const [currentVideoName, setCurrentVideoName] = useState("");
	const [isLoadingLinkForVideo, setIsLoadingLinkForVideo] = useState(false);
	const [currentVideo, setCurrentVideo] = useState(undefined);

	const parseVideoName = (name: string) => {
		const splitVideoName = name.split(".");
		return splitVideoName[0] + "." + splitVideoName[1];
	};
	usePageTitle(`Cacotec Help Video - ${parseVideoName(currentVideoName)}`);

	// *************** START Get list of videos names and play video on mount *********************************

	const videosNames = useFetchHelpVideoNames();

	const dataIsFetching = useIsFetching();

	useEffect(() => {
		if (videosNames?.length) {
			if (parseInt(videoId) > videosNames.length) {
				playVideo(videosNames[0].name);
			} else if (videoId) {
				playVideo(videosNames[parseInt(videoId) - 1].name);
			} else {
				playVideo(videosNames[0].name);
			}
		}
	}, [videosNames]); // eslint-disable-line react-hooks/exhaustive-deps

	// *************** END Get list of videos names and play video on mount *********************************

	// *************** START Get video link *********************************

	useEffect(() => {
		setIsLoadingLinkForVideo(useGetLinkForVideo.isLoading);
	}, [useGetLinkForVideo.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetLinkForVideo;
		if (error) {
			toast.error(`Unable to get video link . ${error}`);
		}
	}, [useGetLinkForVideo.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetLinkForVideo;
		if (data.message) {
			setCurrentVideo(data.message.url);
		}
	}, [useGetLinkForVideo.data]); // eslint-disable-line react-hooks/exhaustive-deps

	// *************** END Get video link *********************************

	// *************** START Handle pseudo URL *********************************

	useEffect(() => {
		if (
			videosNames &&
			videosNames?.length &&
			isLoadingLinkForVideo &&
			currentVideoName
		) {
			let videNameForUrl = currentVideoName
				.split(".")[1]
				.replace(" ", "")
				.replaceAll(" ", "-")
				.toLocaleLowerCase();

			navigate(`${currentVideoName.split(".")[0]}/${videNameForUrl}`, {
				replace: true,
			});
		}
	}, [
		videosNames,
		isLoadingLinkForVideo,
		currentVideoName,
		locationArr.length,
		videoId,
		navigate,
	]);

	// *************** END Handle pseudo URL *********************************

	useEffect(() => {
		if (!autoplay) {
			setAutoplay(true);
		}
	}, [autoplay, currentVideoName]);

	useEffect(() => {
		if (videoElementRef && videoElementRef.current) {
			setCurrentVideoNameWidth(videoElementRef.current.clientWidth);
		}
	}, [width, height, isLoadingLinkForVideo]);

	const onChooseItemCallback = (item: any) => {
		if (item) {
			playVideo(item.name);
		}

		if (helpVideoTitle && helpVideoTitle.current) {
			helpVideoTitle.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const playVideo = (name: string) => {
		setCurrentVideoName(name);
		useGetLinkForVideo.doFetch(
			`/${EndpointPrefix[account_type_id]}/help/video/${name}`
		);
	};

	let otherVideos;
	if (videosNames?.length) {
		otherVideos = (
			<div style={{ pointerEvents: "auto" }}>
				<h1 className="helpSubtitle">Other videos:</h1>

				{videosNames.map((videoName: any) => {
					let videoNameForUrl = videoName.name
						.split(".")[1]
						.replace(" ", "")
						.replaceAll(" ", "-")
						.toLocaleLowerCase();

					let currentVideoLink =
						videoName.name.split(".")[0] === currentVideoName.split(".")[0];

					return (
						<div
							className={`mt--sm flex-center-secondary-axis box--light pd--sm ${
								!currentVideoLink && "cursor-pointer video-card"
							}`}
							key={videoName.id}
							title={parseVideoName(videoName.name)}
							onClick={() => {
								!currentVideoLink && onChooseItemCallback(videoName);
							}}
						>
							{currentVideoLink ? (
								<Icon
									className="icon--lg-2 valign-middle play-icon-current-video"
									name="play"
								/>
							) : (
								<Link to={`${videoName.name.split(".")[0]}/${videoNameForUrl}`}>
									<Icon
										className="icon--lg-2 valign-middle icon-hover"
										name="play"
									/>
								</Link>
							)}
							<div
								style={{
									height: "65px",
								}}
								className="video-name-vertical-align"
							>
								<span className="truncate-three-lines video-list-name">
									{parseVideoName(videoName.name)}{" "}
								</span>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	return (
		<div>
			<h2 className="helpTitle" ref={helpVideoTitle}>
				Help videos
			</h2>
			<div className="mb--md  mt--sm scroll-into-view-adjustment">
				{!!dataIsFetching && (
					<div className="flex-center-both-axis" style={{ height: "360px" }}>
						<Loading show={true} text={`Loading...`} imgClass="" divClass="" />
					</div>
				)}
				{!dataIsFetching && videosNames && videosNames.length > 0 && (
					<div className="flex">
						<div className="video">
							<div className="video-wrapper flex">
								<div className="video-fallback-player-wrapper">
									<div className="aspect-ratio-wrapper ">
										<div className="center-wrapper">
											{isLoadingLinkForVideo ? (
												<div className="player box--black player-border flex-center-both-axis">
													<Loading
														show={true}
														text={`Loading...`}
														imgClass=""
														divClass=""
													/>
												</div>
											) : (
												<video
													ref={videoElementRef}
													className="player player-border"
													src={currentVideo}
													controls
													autoPlay={autoplay}
													controlsList="nodownload"
													muted
												>
													Your browser does not support HTML video.
												</video>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="mt--base current-video-name flex-center-both-axis">
								<span style={{ width: `${currentVideoNameWidth}px` }}>
									{parseVideoName(currentVideoName)}
								</span>
							</div>
						</div>
						<div
							className="show-over-breakpoint list pl--base"
							style={{ marginTop: "-50px" }}
						>
							{otherVideos}
						</div>
					</div>
				)}
			</div>
			<div className="remove-over-breakpoint">{otherVideos}</div>
		</div>
	);
};

export default HelpVideos;
