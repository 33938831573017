import { useContext } from "react";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import { isLabelMaker, isFactory, isBrand } from "Models/UserModels";
interface IProps {
	headerRef: any;
	scrollPadding: number;
}
const OrdersListHeader = ({ headerRef, scrollPadding }: IProps) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);
	return (
		<section
			ref={headerRef}
			className={`listing-header ${
				isLabelMaker(account_type_id)
					? "listing-header--label-makers-orders"
					: isFactory(account_type_id)
					? "listing-header--factory"
					: "listing-header--orders"
			}`}
			style={{
				paddingRight: scrollPadding > 0 ? scrollPadding + "px" : 0,
			}}
		>
			{!isLabelMaker(account_type_id) && (
				<span className="listing-header__label"></span>
			)}
			<span className="listing-header__label">Reference number</span>
			{!isBrand(account_type_id) && (
				<span className="listing-header__label">Brand</span>
			)}
			<span className="listing-header__label">Last edit</span>

			{isLabelMaker(account_type_id) && (
				<span className="listing-header__label">Ship to</span>
			)}

			<span className="listing-header__label">
				{isLabelMaker(account_type_id) ? "Actions" : "Ship to "}
			</span>
			<span className="listing-header__label ordersStatusListItem">Status</span>
		</section>
	);
};

export default OrdersListHeader;
