import { useRef, FC, useState } from "react";
import Icon from "./Icon";
import Loading from "./Loading";
import useOnKeyUp from "Hooks/onKeyUpHook";
import WizardDispatch from "Dispatches/WizardDispatch";

interface IProps {
	finishButtonText?: string;
	children: any;

	setShowWizard: React.Dispatch<React.SetStateAction<boolean>>;
}

const Wizard: FC<IProps> = ({
	finishButtonText = "Finish",
	children,

	setShowWizard,
}) => {
	const modalRef = useRef(null) as any;

	let childrenArray = Array.isArray(children) ? children : [children];

	// ********************************************************************************
	// Wizard context props

	// Global Wizard state
	const [wizardState, setWizardState] = useState<any>(undefined);
	// Custom function for onFinish event
	const [onFinish, setOnFinish] = useState<Function>(() => {});
	// Disable/enable Next or Finish button
	const [isNextOrFinishDisabled, setIsNextOrFinishDisabled] = useState(false);
	// Is Loading Next or Finish button
	const [isLoadingNextOrFinishButton, setIsLoadingNextOrFinishButton] =
		useState(false);
	// Is modal in some of the Wizard component active, info needed for on Escape handler
	const [isModalActive, setIsModalActive] = useState(false);
	// ********************************************************************************

	const [currentStep, setCurrentStep] = useState(0);

	const incrementStep = () => {
		setCurrentStep((prevStep) => ++prevStep);
	};

	const decrementStep = () => {
		setCurrentStep((prevStep) => --prevStep);
	};

	const onCancelWizard = () => {
		if (isModalActive) return;

		if (
			window.confirm(
				"If you exit the place wizard now, you will have to start over. Are you sure you want to exit?"
			)
		) {
			setShowWizard(false);
		}
	};

	useOnKeyUp("Escape", onCancelWizard);

	return (
		<>
			<div className="modal flex-center-both-axis">
				<div
					className="modal-content show"
					style={{ overflowX: "hidden" }}
					ref={modalRef}
					tabIndex={1}
				>
					<WizardDispatch.Provider
						value={{
							wizardState,
							setWizardState,
							setIsNextOrFinishDisabled,
							setOnFinish,
							setIsLoadingNextOrFinishButton,
							setIsModalActive,
						}}
					>
						<div
							className="flex row reverse position-relative"
							style={{ height: "50px" }}
						>
							<button
								className="drawer__close btn-no-style"
								data-dismiss="drawer"
								aria-label="Close"
								onClick={onCancelWizard}
								style={{
									marginTop: "-15px",
									zIndex: 1,
								}}
							>
								<Icon name="cross-rounded" />
							</button>
						</div>
						<div>{childrenArray[currentStep]}</div>
						<div
							className="flex"
							style={{
								justifyContent: "space-between",
								padding: "20px 0",
							}}
						>
							{!!currentStep ? (
								<button
									className={`button--wizard ${
										isLoadingNextOrFinishButton && "button--disabled"
									}`}
									onClick={decrementStep}
								>
									Back
								</button>
							) : (
								<button className="button--wizard" onClick={onCancelWizard}>
									Cancel
								</button>
							)}
							{!isLoadingNextOrFinishButton && (
								<>
									{currentStep < childrenArray.length - 1 ? (
										<button
											className={`button--wizard ${
												isNextOrFinishDisabled && "button--disabled"
											}`}
											onClick={incrementStep}
										>
											Next
										</button>
									) : (
										<button
											className={`button--wizard ${
												isNextOrFinishDisabled && "button--disabled"
											}`}
											onClick={() => {
												onFinish();
											}}
										>
											{finishButtonText}
										</button>
									)}
								</>
							)}
							<Loading
								show={isLoadingNextOrFinishButton}
								text={`Loading...`}
								imgClass="saveOrderLoading"
								divClass=""
							/>
						</div>
					</WizardDispatch.Provider>
				</div>
			</div>
		</>
	);
};

export default Wizard;
