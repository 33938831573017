import React, { useState, useEffect, useRef } from "react";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom-v5-compat";
import { Prompt } from "react-router-dom";

import LabelTemplateHeader from "../Components/LabelTemplate/LabelTemplateHeader";
import GeneralLabelSettings from "Components/LabelTemplate/GeneralLabelTemplateSettings";
import LabelTemplateDrawingArea from "Components/LabelTemplate/LabelTemplateDrawingArea";
import Loading from "Components/Shared/Loading";

import { ITemplateState, initialTemplate } from "Models/TemplateModels";
import { ILabelImage } from "Models/OrderModels";

import { deepCopy, deepIsEqual } from "Utils/utils";

import usePageTitle from "Hooks/pageTitleHook";
import useDataApi from "Hooks/fetchHook";

import "./styles/LabelTemplate.css";
import LabelTemplateDispatch from "Dispatches/LabelTemplateDispatch";

const LabelTemplate: React.FunctionComponent = () => {
	// ************************************ STATES START ************************************

	const [template, setTemplate] = useState<ITemplateState>(initialTemplate);
	const [templateForDeepEqual, setTemplateForDeepEqual] =
		useState<ITemplateState>(initialTemplate);

	const { labelTemplateId: labelTemplateIdFromParams } = useParams() as any;

	const [isDirty, setIsDirty] = useState(false);

	const [isLoadingLabelTemplate, setIsLoadingLabelTemplate] = useState(true);

	const [activeTab, setActiveTab] = useState("label");

	const [showMissingData, setShowMissingData] = useState(false);

	const [imagesSectionsData, setImagesSectionsData] = useState<{
		sections: any[];
		margins: {};
	}>({
		sections: [],
		margins: {},
	});

	const [images, setImages] = useState([] as ILabelImage[]);

	let saveTemplateDisabled =
		checkGeneralLabelSettings().length > 0 ||
		!template.name ||
		!template.sections.length;

	const isNewLabelTemplate = !labelTemplateIdFromParams;

	const [accordionIsOpen, setAccordionIsOpen] = useState(isNewLabelTemplate);

	const timer = useRef(null) as any;

	// ************************************ STATES END ************************************

	// ************************************ HOOKS START ************************************

	const useGetLabelImages = useDataApi();
	const useGetLabelTemplate = useDataApi();

	let isLoadingDrawTemplate = useGetLabelImages.isLoading;

	usePageTitle(isNewLabelTemplate ? "New Template" : "Edit Template");

	// ************************************ HOOKS END ************************************

	// ************************************ USE EFFECTS START ************************************

	useEffect(() => {
		if (!isNewLabelTemplate) {
			getLabelTemplate();
		}
	}, [labelTemplateIdFromParams]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			isNewLabelTemplate ||
			(!isNewLabelTemplate && !useGetLabelTemplate.isLoading && template?.name)
		) {
			setIsLoadingLabelTemplate(false);
		}
	}, [isNewLabelTemplate, useGetLabelTemplate.isLoading, template.name]);

	// adding event listeners for leaving the page
	useEffect(() => {
		const onReloadPage = (e: any) => {
			if (isDirty) {
				e.preventDefault();
				e.returnValue =
					"Unsaved data will be lost. Are you sure you want to leave this page?";
			}
		};

		window.addEventListener("beforeunload", onReloadPage);
		return () => {
			window.removeEventListener("beforeunload", onReloadPage);
		};
	}, [isDirty]);

	useEffect(() => {
		if (!deepIsEqual(template, templateForDeepEqual)) {
			setIsDirty(true);
		} else {
			setIsDirty(false);
		}
	}, [template]); // eslint-disable-line react-hooks/exhaustive-deps

	const getImages = (template: any) => {
		useGetLabelImages.doFetch(
			"/draw/template",
			{ labelTemplate: template },
			"POST"
		);
	};

	const getLabelTemplate = () => {
		useGetLabelTemplate.doFetch(
			`/brand/data/labelTemplates/${labelTemplateIdFromParams}`
		);
	};

	useEffect(() => {
		const { data } = useGetLabelImages;

		if (data && data.message) {
			setImages(data.message.labelImages);
			setImagesSectionsData({
				sections: data.message.hoverImageData,
				margins: data.message.margins,
			});
		}
	}, [useGetLabelImages.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetLabelImages;
		if (error) {
			toast.error(`Unable to get label images . ${error}`);
		}
	}, [useGetLabelImages.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetLabelTemplate;

		if (data && data.message) {
			setTemplate(deepCopy(data.message));
			setTemplateForDeepEqual(deepCopy(data.message));
		}
	}, [useGetLabelTemplate.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetLabelTemplate;
		if (error) {
			toast.error(`Unable to get label template . ${error}`);
		}
	}, [useGetLabelTemplate.error]); // eslint-disable-line react-hooks/exhaustive-deps

	let getImagesDataVerified = checkGeneralLabelSettings().length === 0;

	let depArray = [
		template.languages,
		template.margins,
		template.dimensions,
		template.fonts.blackGround,
		template.fonts.lineSpacing,
		template.fonts.style,
		getImagesDataVerified,
	];

	useEffect(() => {
		if (getImagesDataVerified) {
			clearTimeout(timer.current);

			timer.current = setTimeout(() => {
				getImages(template);
			}, 500);
		}
	}, depArray); // eslint-disable-line react-hooks/exhaustive-deps

	// *************** USE EFFECT END ****************************

	// ************************************ FUNCTION START ************************************

	function checkGeneralLabelSettings() {
		let missingInfo: string[] = [];

		if (template.name === "") missingInfo.push("Template name");

		if (template.dimensions.width < 25) missingInfo.push("Label width");

		if (template.dimensions.multiplePages && template.dimensions.height < 25)
			missingInfo.push("Label height");

		if (template.fonts.style === "") missingInfo.push("Font family");

		if (template.fonts.lineSpacing <= 0) missingInfo.push("Line space");

		if (template.fonts.size < 4.5) missingInfo.push("Font size");

		if (!template.sectionDefaultSettings?.align) missingInfo.push("Alignment");

		if (template.sectionDefaultSettings?.capitalization === -1)
			missingInfo.push("Capitalization");

		if (template.sectionDefaultSettings?.splitCharacter === -1)
			missingInfo.push("Split Character");

		if (template.sectionDefaultSettings?.separator === 0)
			missingInfo.push("Separator");

		if (template.languages.length === 0) missingInfo.push("Languages");

		return missingInfo;
	}

	// ************************************ FUNCTION END ************************************

	return (
		<>
			<Prompt
				when={isDirty}
				message={(location) => {
					return location.pathname.startsWith("/labelTemplate/")
						? true
						: "Template not saved. Are you sure you want to leave?";
				}}
			/>
			{isLoadingLabelTemplate ? (
				<Loading
					show={true}
					text="Loading..."
					imgClass="block-center"
					divClass="main__content label-template-loading"
				/>
			) : (
				<LabelTemplateDispatch.Provider
					value={{
						images,
						imagesSectionsData,
						getImages,
						template,
						setTemplate,
						activeTab,
						setActiveTab,
						accordionIsOpen,
						setAccordionIsOpen,
						checkGeneralLabelSettings,
						showMissingData,
						setShowMissingData,
						isLoadingDrawTemplate,
					}}
				>
					<div className="main__content" style={{ paddingBottom: 0 }}>
						<div
							className="container container-narrow"
							style={{ padding: "0px" }}
						>
							<LabelTemplateHeader
								saveTemplateDisabled={saveTemplateDisabled}
								setIsDirty={setIsDirty}
							/>
							<GeneralLabelSettings />
						</div>
						<LabelTemplateDrawingArea />
					</div>
				</LabelTemplateDispatch.Provider>
			)}
		</>
	);
};

export default LabelTemplate;
