import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";

import { EndpointPrefix, isBrand, isFactory } from "Models/UserModels";
import useDataApi from "Hooks/fetchHook";
import useWindowSize from "Hooks/windowSizeHook";
import usePageTitle from "Hooks/pageTitleHook";
import GlobalDispatch from "Dispatches/GlobalDispatch";

import Loading from "Components/Shared/Loading";
import Icon from "Components/Shared/Icon";
import TemplatesList from "Components/Templates/TemplatesList";
import SearchFilter from "Components/Shared/SearchFilter";
import TemplatesHeaderFilters from "Components/Templates/TemplatesHeaderFilters";

import { PSEUDO_URL_QUERY_VALUES } from "Models/OrderModels";
import { toast } from "react-toastify";

// use cases for templates page
// 1. go to templates page - on mount - step_1, step_2
// 2. scroll down ( infinite scroll ) - step_scroll_down, step_3

// 3. apply filter
//       a) while offset = 0   - step_3a
//       b) while offset > 0   - step_3b, step_4

// 4. reset by clicking templates link in nav bar
//       a) while offset = 0 and no filter is set    - step_5
//       b) while offset = 0 and some filter is set  - step_5, step_3a
//       c) while offset > 0 and no filter is set    - step_5, step_4
//       d) while offset > 0 and some filter is set  - step_5, step_3b, step_4

// 5. force refresh on app refresh button
//       a) while offset = 0 and no filter is set    - step_6a
//       b) while offset > 0 and no filter is set    - step_6b

// 6. window size change - step_1, step_2, step_4

// 7. refresh browser tab ( handled with use Case 1 ) - step_1, step_2

// 8. copy/paste link ( handled with use Case 1 ) - step_1, step_2

const Templates: React.FunctionComponent = () => {
	const {
		user: { account_type_id },
		setResetTemplatesFilters,
		resetTemplatesFilters,
	} = useContext(GlobalDispatch);

	const useGetTemplates = useDataApi();

	const windowSize = useWindowSize();

	const navigate = useNavigate();
	const location = useLocation();

	const [templates, setTemplates] = useState<any[]>([]);
	const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

	const [filteredStatus, setFilteredStatus] = useState(undefined) as any;
	const [offset, setOffset] = useState(0);
	const [templatesHeight, setTemplatesHeight] = useState(0);

	const [searchText, setSearchText] = useState("");

	const [previousFilterQuery, setPreviousFilterQuery] = useState(
		undefined
	) as any;

	const [limitTemplatesPerPage, setLimitTemplatesPerPage] = useState(0);

	const [currentData, setCurrentData] = useState(undefined);

	const PAGE_HEADER_HEIGHT = 115;
	const TABLE_HEADER_HEIGHT = 20;

	usePageTitle("Templates");

	/* ******************** Use cases steps START ************************ */

	// step_1
	useEffect(() => {
		const globalPaddingMargin = 50; // top + bottom margin + table header top margin
		const LIST_ITEM_ROW_SIZE = 50;

		if (windowSize[1] > 0) {
			setTemplatesHeight(
				windowSize[1] - PAGE_HEADER_HEIGHT - globalPaddingMargin
			);
		}

		if (templatesHeight && limitTemplatesPerPage === 0) {
			setLimitTemplatesPerPage(
				// get round down number of orders and add two more so we have scroll
				(((templatesHeight - TABLE_HEADER_HEIGHT * 2) / LIST_ITEM_ROW_SIZE) >>
					0) +
					2
			);
		}
	}, [windowSize[1], templatesHeight]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_2
	useEffect(() => {
		if (
			limitTemplatesPerPage > 0 &&
			!getAndSetFilters() &&
			templates &&
			templates.length === 0
		) {
			setTemplates([]);
			if (offset !== 0) {
				// trigger step_4 by reset offset to 0
				setOffset(0);
			} else {
				// trigger step_4 manually when offset is already 0 ( on mount or other situation )
				refreshTemplates();
			}
		} else if (limitTemplatesPerPage > 0 && templates.length > 0) {
			setOffset(templates.length);
		}
	}, [limitTemplatesPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_3
	useEffect(() => {
		// step_3a
		if (offset === 0 && limitTemplatesPerPage > 0) {
			refreshTemplates(false, true);
		}
		//step_3b
		else if (offset > 0 && limitTemplatesPerPage > 0) {
			setTemplates([]);
			setOffset(0);
		}
	}, [searchText, filteredStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_4
	useEffect(() => {
		// we want to skip calling refreshTemplates() on mount
		// while limitTemplatesPerPage is still not calculated, we do it in step_2
		if (limitTemplatesPerPage > 0) {
			refreshTemplates();
		}
	}, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_5
	useEffect(() => {
		if (resetTemplatesFilters) {
			setResetTemplatesFilters(false);

			if (filteredStatus || searchText) {
				setFilteredStatus(undefined);
				setSearchText("");
			} else if (offset > 0) {
				setTemplates([]);
				setOffset(0);
			}

			navigate(
				{
					pathname: location.pathname,
					search: `${PSEUDO_URL_QUERY_VALUES.OFFSET}=0&${PSEUDO_URL_QUERY_VALUES.LIMIT}=${limitTemplatesPerPage}`,
				},
				{ replace: true }
			);
		}
	}, [resetTemplatesFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ******************** Use cases END ************************ */

	/* ******************** Get templates API call ************************ */
	useEffect(() => {
		const { error } = useGetTemplates;
		if (error) {
			toast.error(`Unable to get templates. ${error}`);
		}
	}, [useGetTemplates.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetTemplates;
		if (data.message) {
			const customTemplates = [] as any;
			for (const template of data.message) {
				const {
					id,
					brand_id,
					name,
					settings,
					last_edit_on,
					last_edit_by,
					status,
					hash,
					reference_number,
					last_used_on,
				} = template;
				customTemplates.push({
					id,
					brand_id,
					name,
					settings,
					last_edit_on,
					last_edit_by,
					status,
					hash,
					reference_number,
					last_used_on,
				});
			}

			setCurrentData(customTemplates);

			setTemplates((prevStyles) => [...prevStyles, ...customTemplates]);
		}
	}, [useGetTemplates.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setIsLoadingTemplates(useGetTemplates.isLoading);
	}, [useGetTemplates.isLoading]);

	/* ****************************************************************** */

	/* ******************** functions START ***************************/

	const getAndSetFilters = () => {
		const params = new URLSearchParams(location.search);

		const status = params.get(PSEUDO_URL_QUERY_VALUES.STATUS);
		const searchText = params.get(PSEUDO_URL_QUERY_VALUES.SEARCH_TEXT);

		status && setFilteredStatus(status);
		searchText && setSearchText(searchText);

		return status || searchText;
	};

	const refreshTemplates = (
		forceRefresh: boolean = false,
		resetTemplatesList: boolean = false,
		forceOffset: number = -1
	) => {
		const searchTextCleared = searchText?.trim();
		const filterBySearchText =
			searchTextCleared?.length > 0
				? `&filter=${encodeURIComponent(searchTextCleared)}`
				: "";
		const filterByStatus = filteredStatus
			? `&status=${encodeURIComponent(filteredStatus)}`
			: "";

		const urlOffset = forceOffset > -1 ? forceOffset : offset;
		const filterQuery = `/${EndpointPrefix[account_type_id]}/data/customLabelTemplates?offset=${urlOffset}&limit=${limitTemplatesPerPage}${filterBySearchText}${filterByStatus}`;

		if (resetTemplatesList && filterQuery !== previousFilterQuery)
			setTemplates([]);

		if (filterQuery !== previousFilterQuery || forceRefresh) {
			if (forceRefresh) {
				setTemplates([]);
			}

			// set pseudo URL
			navigate(
				{
					pathname: location.pathname,
					search: toPseudonymUrlQuery(filterQuery),
				},
				{ replace: true }
			);

			useGetTemplates.doFetch(filterQuery);
			setPreviousFilterQuery(filterQuery);
		}
	};

	const toPseudonymUrlQuery = (filterQuery: string) => {
		const params = new URLSearchParams(filterQuery);

		const offset = params.get(
			`/${EndpointPrefix[account_type_id]}/data/customLabelTemplates?offset`
		);

		const searchText = params.get("filter")
			? `&${PSEUDO_URL_QUERY_VALUES.SEARCH_TEXT}=${params.get("filter")}`
			: "";
		const status = params.get("status")
			? `&${PSEUDO_URL_QUERY_VALUES.STATUS}=${params.get("status")}`
			: "";

		const pseudoFilterQuery = `${PSEUDO_URL_QUERY_VALUES.OFFSET}=${offset}&${PSEUDO_URL_QUERY_VALUES.LIMIT}=${limitTemplatesPerPage}${searchText}${status}`;

		return pseudoFilterQuery;
	};

	const forceRefreshTemplates = () => {
		//step_6b
		if (offset > 0) {
			setTemplates([]);
			setOffset(0);
		}
		// step_6a
		else {
			refreshTemplates(true);
		}
	};

	return (
		<React.Fragment>
			<div
				className="main__content"
				style={{ overflow: "hidden", paddingBottom: "0", position: "unset" }}
			>
				<div className="container">
					<header style={{ height: PAGE_HEADER_HEIGHT }}>
						<div className="row">
							<div className="col-sm-6" style={{ display: "flex" }}>
								<h1 className="section__title">Your Templates</h1>
								{(isBrand(account_type_id) || isFactory(account_type_id)) && (
									<div>
										<button
											className="button btn-link pd--none ml--md mt--sm transparent"
											onClick={() => {
												navigate(
													{
														pathname: "/labelTemplate",
													},
													{ replace: true }
												);
											}}
										>
											<Icon
												name="plus-rounded"
												className="icon valign-middle mr--xs"
											/>
											New Template
										</button>
									</div>
								)}
							</div>
							<div
								className="col-sm-6"
								style={{ display: "flex", justifyContent: "end" }}
							>
								<SearchFilter
									setSearchText={setSearchText}
									searchTextValue={searchText}
									placeholderText="Enter template name"
								/>
							</div>
						</div>
						<div className="toolbarAndFiltersContainer">
							<div className="toolbar">
								{/* Refresh action */}
								<button
									className="mr--md left btn-no-style"
									title="Refresh templates"
									onClick={forceRefreshTemplates}
								>
									<div className="pill pill--outline-quiet pill--toolbar">
										<div className="toolbarIconContainer">
											<Icon name="refresh" className="toolbarIcon" />
										</div>
									</div>
								</button>
							</div>
							<TemplatesHeaderFilters
								filteredStatus={filteredStatus}
								setFilteredStatus={setFilteredStatus}
							/>
						</div>
					</header>

					{isLoadingTemplates && templates.length === 0 && (
						<div
							className="flex-center-both-axis"
							style={{ height: templatesHeight }}
						>
							<Loading
								show={isLoadingTemplates}
								text={`Loading...`}
								imgClass=""
								divClass=""
							/>
						</div>
					)}

					{templates && templates.length > 0 && (
						<TemplatesList
							templates={templates}
							templatesListHeight={templatesHeight}
							templatesListHeaderHeight={TABLE_HEADER_HEIGHT}
							isLoadingTemplates={isLoadingTemplates}
							offset={offset}
							setOffset={setOffset}
							limitTemplatesPerPage={limitTemplatesPerPage}
							currentData={currentData}
							refreshTemplates={refreshTemplates}
						/>
					)}

					{!isLoadingTemplates && templates.length === 0 && (
						<div style={{ height: templatesHeight }}>
							<div
								className="box box--sm box--bordered"
								id="emptyOrders"
								style={{ marginTop: "10px" }}
							>
								<h1 className="text--quiet">No templates</h1>
								<Icon name="order-item" className="noOrdersIcon" />
								<div
									className="txtc text-sm text--quiet"
									style={{ marginTop: "20px", whiteSpace: "pre-line" }}
								>
									You have no label templates at the moment.
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Templates;
