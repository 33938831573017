import { FC, useContext } from "react";

import OrderDispatch from "Dispatches/OrderDispatch";
import IncrementalSearchComponent from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchComponent";

interface IProps {
	additionalInformationName: string;
	list: any[];
	selectedItemsValues: any;
}

const AdditionalInformationComponent: FC<IProps> = ({
	additionalInformationName,
	list,
	selectedItemsValues,
}) => {
	const { setOrderItem } = useContext(OrderDispatch);

	const orderItemPropName = `selectedAdditionalInformation---${additionalInformationName}`;

	const onChooseItemCallback = (item: any) => {
		if (item) {
			let filteredListItems = selectedItemsValues || [];
			if (!selectedItemsValues.includes(item.id)) {
				filteredListItems = [...selectedItemsValues, item.id];
				setOrderItem({
					[orderItemPropName]: filteredListItems,
				});
			}
		}
	};

	const onChangeOrderOfSelectedItems = (ids: any) => {
		setOrderItem({
			[orderItemPropName]: ids.map((id: string) => Number(id)),
		});
	};

	const onRemoveItem = (itemId: number) => {
		const filteredItems = selectedItemsValues.filter(
			(chosenItemId: number) => chosenItemId !== itemId
		);
		setOrderItem({
			[orderItemPropName]: filteredItems,
		});
	};

	return (
		<>
			<fieldset className="box box--light box--shadowed mb--md">
				<IncrementalSearchComponent
					list={list}
					buttonName={additionalInformationName}
					selectedItemsValues={selectedItemsValues}
					fieldName={additionalInformationName}
					onChooseItemCallbackCustom={onChooseItemCallback}
					onChangeOrderOfSelectedItems={onChangeOrderOfSelectedItems}
					onRemoveItem={onRemoveItem}
				/>
			</fieldset>
		</>
	);
};

export default AdditionalInformationComponent;
