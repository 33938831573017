import React, { useContext, useEffect, useState } from "react";
import LabelTemplateDispatch from "Dispatches/LabelTemplateDispatch";
import { ITemplateState } from "Models/TemplateModels";

const LabelMargins: React.FunctionComponent = () => {
	const { template, setTemplate } = useContext(LabelTemplateDispatch);

	const [marginState, setMarginState] = useState({
		top: template?.margins?.top || 0,
		left: template?.margins?.left || 0,
		right: template?.margins?.right || 0,
		bottom: template?.margins?.bottom || 0,
	});

	useEffect(() => {
		setTemplate((prevTemplate: ITemplateState) => ({
			...prevTemplate,
			margins: marginState,
		}));
	}, [marginState]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="flex-1 ml--sm">
			{/* <div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>Label margins</span>
				<HelpTooltip helpFor="LabelMargins" />
			</div> */}
			<fieldset className="mb--md flex">
				<div className="flex flex-column mr--sm">
					<div className="form-group mb--base">
						<input
							type="number"
							className="form-control"
							name="labelMarginTop"
							id="labelMarginTop"
							value={marginState.top}
							data-empty={marginState.top < 0}
							onChange={(e) => {
								setMarginState({ ...marginState, top: Number(e.target.value) });
							}}
							min={0}
							step={0.1}
						/>
						<label className="form-control-label" htmlFor="labelMarginTop">
							Top margin (mm)
						</label>
					</div>

					<div className="form-group">
						<input
							type="number"
							className="form-control"
							name="labelMarginLeft"
							id="labelMarginLeft"
							value={marginState.left}
							data-empty={marginState.left < 0}
							onChange={(e) => {
								setMarginState({
									...marginState,
									left: Number(e.target.value),
								});
							}}
							min={0}
							step={0.1}
						/>
						<label className="form-control-label" htmlFor="labelMarginLeft">
							Left margin (mm)
						</label>
					</div>
				</div>
				<div className="flex flex-column">
					<div className="form-group  mb--base">
						<input
							type="number"
							className="form-control"
							name="labelMarginRight"
							id="labelMarginRight"
							value={marginState.right}
							data-empty={marginState.right < 0}
							onChange={(e) => {
								setMarginState({
									...marginState,
									right: Number(e.target.value),
								});
							}}
							min={0}
							step={0.1}
						/>
						<label className="form-control-label" htmlFor="labelMarginRight">
							Right margin (mm)
						</label>
					</div>
					<div className="form-group">
						<input
							type="number"
							className="form-control"
							name="labelMarginBottom"
							id="labelMarginBottom"
							value={marginState.bottom}
							data-empty={marginState.bottom < 0}
							onChange={(e) => {
								setMarginState({
									...marginState,
									bottom: Number(e.target.value),
								});
							}}
							min={0}
							step={0.1}
						/>
						<label className="form-control-label" htmlFor="labelMarginBottom">
							Bottom margin (mm)
						</label>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export default LabelMargins;
