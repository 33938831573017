import { useContext, useEffect } from "react";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import useDataApi from "Hooks/fetchHook";
import { IOrderState } from "Models/OrderModels";
import { toast } from "react-toastify";
import { isFactory } from "./../../Models/UserModels";
import { deepCopy } from "Utils/utils";

const useUpdateOrder = (
	order: IOrderState,
	showSaveResultNotificationRef: { current: any },
	setOrder: any,
	setLoadedOrder: any,
	saveOrderFinishedSuccessfully: React.MutableRefObject<boolean>
) => {
	const useUpdateOrder = useDataApi();
	const { user } = useContext(GlobalDispatch);

	useEffect(() => {
		const { data } = useUpdateOrder;
		if (data.status === "ok") {
			if (showSaveResultNotificationRef.current) {
				toast.success("The order was saved successfully.");
			}

			let newOrderStatus = order.status;
			if (
				isFactory(user.account_type_id) &&
				order.status === 4 &&
				showSaveResultNotificationRef.current
			) {
				newOrderStatus = 1;
			}

			const updatedOrder: IOrderState = {
				...order,
				log: {
					...order.log,
					lastChangeBy: user.name,
					lastChangeAt: "a few moments ago",
				},
				status: newOrderStatus,
			};

			setOrder(updatedOrder);
			setLoadedOrder(deepCopy(updatedOrder));
			if (!showSaveResultNotificationRef.current) {
				saveOrderFinishedSuccessfully.current = true;
			}
		}
	}, [useUpdateOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error, details } = useUpdateOrder;

		if (details) {
			const { orderStatus, userName, doneAt } = JSON.parse(details);

			let newDate = new Date(doneAt); // create Date object format in users locale time zone

			let newDateTransformed = newDate.toLocaleString(); // format Date to browsers languages settings

			let errorMessage = `This order is now in the ${orderStatus} status, updated by ${userName} at ${newDateTransformed}. Please refresh the page to see the latest information.`;
			toast.error(errorMessage);
		}

		if (error) {
			let {
				shippingAddress,
				billingAddress,
				preferredShippingMethod,
				...orderWithoutShippingDetails
			} = order;
			setOrder(orderWithoutShippingDetails);
		}
	}, [useUpdateOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps

	let updateOrderDoFetch = useUpdateOrder.doFetch;
	let updateOrderIsLoading = useUpdateOrder.isLoading;

	return { updateOrderDoFetch, updateOrderIsLoading };
};

export default useUpdateOrder;
