import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../react-query/constants";
import {
	FETCH_GET_PARAMS,
	URL_BASE,
	CustomError,
} from "../../Models/QueryModels";
import useErrorHandler from "Hooks/queryHooks/useErrorHandler";
import { toast } from "react-toastify";
import {
	EndpointPrefix,
	isBrand,
	USER_ACCOUNT_TYPE_VALUES,
} from "../../Models/UserModels";

async function manageError(response: Response) {
	if (!response.ok) {
		let responseJSON = await response.json();
		throw new CustomError(responseJSON.message, response.status);
	}
	return response;
}

let url = "";

async function getAccExec(
	account_type_id: USER_ACCOUNT_TYPE_VALUES | undefined
): Promise<any> {
	if (account_type_id) {
		url = `${URL_BASE.address}/${EndpointPrefix[account_type_id]}/data/account_executives`;
		const result = await fetch(url, FETCH_GET_PARAMS).then(manageError);
		return await result.json();
	}
}

export function useFetchAccExec(
	account_type_id: USER_ACCOUNT_TYPE_VALUES | undefined,
	setError: any
): any {
	const fallback: any = [];
	const errorHandler = useErrorHandler();

	const enabledCriteria = account_type_id && !isBrand(account_type_id);

	const {
		data = fallback,
		isLoading,
		fetchStatus,
		isError,
		error,
	} = useQuery({
		queryKey: [QUERY_KEYS.ACCOUNT_EXECUTIVES, account_type_id],
		queryFn: () => getAccExec(account_type_id),
		...{
			enabled: !!enabledCriteria,
			gcTime: 604800000, // 7 days
			staleTime: 86400000, // 1 day
		},
	});

	if (isError) {
		let customError = error as CustomError;
		let errorMessage = errorHandler(
			customError.status,
			customError.message,
			url
		);
		toast.error(`Unable to get account executives. ${errorMessage}`);
		setError({
			title: "Something Went Wrong",
			text: "Please try again later",
			primary: {
				text: "",
				action: () => {},
			},
			secondary: {
				text: "",
				action: () => {},
			},
		});
	}

	return {
		accountExecutivesInfo: data.message,
		isLoadingAccountExecutives: isLoading && fetchStatus !== "idle",
	};
}
