import React, {
	useRef,
	useEffect,
	useCallback,
	useState,
	useContext,
} from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";

import Icon from "Components/Shared/Icon";
import Loading from "Components/Shared/Loading";

import useOnClickOutside from "Hooks/outsideClickHook";
import useDataApi from "Hooks/fetchHook";
import useOnKeyDown from "Hooks/onKeyDownHook";

import { ILabelImage, IOrderState } from "Models/OrderModels";

import sprite from "images/icons.svg";
import Accordion from "Components/Shared/Accordion";
import HelpTooltip from "Components/Shared/HelpTooltip";
import { EndpointPrefix } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import OrderDispatch from "Dispatches/OrderDispatch";

interface IProps {
	order: IOrderState;
	isDirty: boolean;
	isLoadingSaveOrder: boolean;
	printData: any;

	saveOrder: (shouldShowSaveResultNotification: boolean) => void;
	setPrintData: (printData: any) => void;
}

const OrderPrintSummaryModal: React.FunctionComponent<IProps> = ({
	order,
	isDirty,
	isLoadingSaveOrder,
	printData,

	saveOrder,
	setPrintData,
}) => {
	const {
		user: { account_type_id },
		fromOrders,
	} = useContext(GlobalDispatch);

	const { saveOrderFinishedSuccessfully } = useContext(OrderDispatch);

	const navigate = useNavigate();

	const usePrintOrder = useDataApi();

	const modalRef = useRef(null) as any;

	const [isLoadingPrintingProcess, setIsLoadingPrintingProcess] =
		useState(false);

	const { lotNumber, note, orderId, labelTemplate } = order;

	const labelOrientation =
		labelTemplate?.settings?.default_values?.label_orientation;

	const [printBatches, setPrintBatches] = useState<any[]>(printData);

	useEffect(() => {
		if (
			(isLoadingSaveOrder || usePrintOrder.isLoading) &&
			!isLoadingPrintingProcess
		) {
			setIsLoadingPrintingProcess(true);
		}
	}, [isLoadingSaveOrder, usePrintOrder.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************************** */

	// If the order was dirty, we had to save it first and then initiate setPrintingStatus
	useEffect(() => {
		if (saveOrderFinishedSuccessfully.current && !isDirty) {
			sendToPrint();
			saveOrderFinishedSuccessfully.current = false;
		}
	}, [isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ******************** Handle Print Order API call ***************************/
	useEffect(() => {
		const { data } = usePrintOrder;
		if (data.status === "ok") {
			fromOrders.current = false;
			navigate(`../order/preview/${orderId}`);
		}
	}, [usePrintOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = usePrintOrder;
		if (error) {
			toast.error(`Unable to create print data. ${error}`);
		}
	}, [usePrintOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	const onSendToPrint = () => {
		if (isDirty) {
			saveAndSendToPrint();
		} else {
			sendToPrint();
		}
	};

	/* *******************************************************************************/
	const sendToPrint = () => {
		const { orderId } = order;

		usePrintOrder.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printingConfirmed`,
			{
				orderId,
			},
			"POST"
		);
	};

	/* *******************************************************************************/
	const labelTemplateRibbonWidth = () => {
		if (
			order.labelTemplate.settings.default_values.label_orientation ===
			"horizontal"
		) {
			return order.labelTemplate.settings.default_values.height;
		} else {
			return order.labelTemplate.settings.default_values.width;
		}
	};

	/* *******************************************************************************/
	const saveAndSendToPrint = () => {
		saveOrder(false);
	};

	/* *******************************************************************************/
	const onCancelPrintSummary = () => {
		setPrintData(undefined);
	};

	/* *******************************************************************************/

	useOnKeyDown("Escape", onCancelPrintSummary);

	/* *******************************************************************************/
	useOnClickOutside(
		modalRef,
		useCallback(onCancelPrintSummary, [setPrintData])
	); // eslint-disable-line react-hooks/exhaustive-deps

	const handleAccordion = (printBatchIndex: number, printJobIndex: number) => {
		setPrintBatches((prevPrintBatches: any) => {
			let currentPrintBatches = [...prevPrintBatches];
			let currentPrintBatch = { ...currentPrintBatches[printBatchIndex] };

			currentPrintBatch.printJobs[printJobIndex].showImages =
				!currentPrintBatch.printJobs[printJobIndex]?.showImages;

			currentPrintBatches[printBatchIndex] = currentPrintBatch;

			return currentPrintBatches;
		});
	};

	return (
		<div className="modal flex-center-both-axis">
			<div
				className="modal-content-order-summary modal-content-print-order-summary show"
				ref={modalRef}
				tabIndex={1}
				style={{ overflowX: "hidden" }}
			>
				<>
					<div className="flex row reverse" style={{ height: "40px" }}>
						<button
							className="drawer__close btn-no-style"
							data-dismiss="drawer"
							aria-label="Close"
							onClick={onCancelPrintSummary}
							style={{ position: "relative" }}
						>
							<Icon name="cross-rounded" />
						</button>
					</div>

					<div className="mt--md flex-center-both-axis">
						<h1>Print summary for order #{lotNumber}</h1>
					</div>

					<div className="flex ml--lg mr--lg">
						<div className="mt--sm flex-1">
							<b>Layout:</b> <div>{order.labelTemplate.name}</div>
						</div>

						<div className="mt--sm flex-1">
							<b>Note:</b> <div>{note ? note : "/"}</div>
						</div>
					</div>

					<div className="mt--md">
						<div className="pretitle flex-center-secondary-axis">
							<span style={{ lineHeight: 1 }}>Printing details</span>
							<HelpTooltip helpFor="PrintSummaryModal" />
						</div>

						{printBatches.length > 0 &&
							printBatches.map(
								({ color: labelColor, printJobs }, printBatchIndex) => {
									let totalQuantity = 0;
									let totalPrintQuantity = 0;

									return (
										<div
											className="pd--base mt--base"
											style={{ border: "2px solid #DFDFDF" }}
											key={labelColor}
										>
											<h2>
												{labelColor === "white" ? "White" : "Black"} /{" "}
												{labelTemplateRibbonWidth()} mm
											</h2>
											<div className="flex text--quiet print-summary-column-titles">
												<span className="flex-1">Style</span>
												<span className="flex-1">Size</span>
												<span className="flex-2 flex-center-secondary-axis">
													<span>Quantity (Print) </span>

													<div style={{ display: "inline-block" }}>
														<HelpTooltip helpFor="PrintQuantity" />
													</div>
												</span>
												<span className="flex-1">Label images</span>
											</div>
											{printJobs.map((printJob: any, index: number) => {
												totalQuantity += printJob.quantity;
												totalPrintQuantity += printJob.print_quantity;
												return (
													<div
														style={{
															borderBottom: `${
																index < printJobs.length - 1
																	? "2px solid rgb(244, 244, 244)"
																	: ""
															}`,
															paddingBottom: "10px",
														}}
														key={printJob.style + "-" + printJob.size}
													>
														<div className="flex mt--sm">
															<span className="flex-1">{printJob.style}</span>
															<span className="flex-1">{printJob.size}</span>
															<span className="flex-2">
																{printJob.quantity} ({printJob.print_quantity})
															</span>
															<span className="flex-1">
																<span
																	className="cursor-pointer flex"
																	style={{
																		justifyContent: "flex-end",
																		paddingRight: "30px",
																	}}
																	onClick={() => {
																		handleAccordion(printBatchIndex, index);
																	}}
																>
																	<svg
																		style={{
																			border: "solid 2px gray",
																			marginTop: 0,
																		}}
																		className={`accordion-arrow-transform print-summary-accordion-arrow ${
																			printJob?.showImages
																				? "rotate-90"
																				: "rotate-270"
																		}`}
																	>
																		<use
																			xlinkHref={`${sprite}#icon-double-left-arrow`}
																		/>
																	</svg>
																</span>
															</span>
														</div>
														<Accordion isOpen={!!printJob?.showImages}>
															<div className="mt--sm">
																{printJob?.images.map(
																	(imageObject: ILabelImage, index: number) => {
																		return (
																			<div
																				key={`${imageObject.name}-${index}`}
																				style={{
																					width: `${
																						!imageObject.image ||
																						labelOrientation === "horizontal"
																							? "100%"
																							: "49.5%"
																					}`,
																				}}
																			>
																				<div className="pd--sm">
																					<img
																						className="labelContainer"
																						alt="Label"
																						src={imageObject.image}
																						style={{
																							maxWidth: "100%",
																						}}
																					/>
																				</div>
																			</div>
																		);
																	}
																)}
															</div>
														</Accordion>
													</div>
												);
											})}
											<div className="flex font-weight-500 print-summary-total">
												<span className="flex-1"></span>
												<span className="flex-1">Total</span>
												<span className="flex-2">
													{totalQuantity} ({totalPrintQuantity})
												</span>
												<span className="flex-1"></span>
											</div>
										</div>
									);
								}
							)}
					</div>

					<div
						className="flex-center-secondary-axis around-lg mt--md mb--md"
						style={{ padding: "0 50px" }}
					>
						{!isLoadingPrintingProcess && (
							<div
								className="button button--primary button--lg"
								onClick={onSendToPrint}
							>
								Confirm
							</div>
						)}
						<Loading
							show={isLoadingPrintingProcess}
							text={`Loading...`}
							imgClass="saveOrderLoading"
							divClass=""
						/>
						<div
							className="button button--primary button--lg"
							onClick={onCancelPrintSummary}
						>
							Cancel
						</div>
					</div>
				</>
			</div>
		</div>
	);
};

export default OrderPrintSummaryModal;
