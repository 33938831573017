import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import Icon from "Components/Shared/Icon";

import { addressJSONToString } from "Utils/utils";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import LabelMakerEditStatusModal from "Components/Shared/LabelMakerEditStatusModal";

import { useFetchBrands } from "Hooks/queryHooks/useFetchBrands";
import useDataApi from "Hooks/fetchHook";

import { IUnit, OrderStatusesFlow } from "Models/OrderModels";

import jszip from "jszip";
import jsFileDownload from "js-file-download";

interface OrderItemForZip {
	styleNumber: string;
	styleQty: number;
}
interface IProps {
	order: any;

	setOrder: (order: any) => void;
}

const OrderPreviewAdditionalInfo = ({ order, setOrder }: IProps) => {
	const {
		user: { account_type_id },
		setError,
	} = useContext(GlobalDispatch);

	const getLayoutsUrl = useDataApi();
	const getRecreateLabelImages = useDataApi();

	const [zippingInProgress, setZippingInProgress] = useState(false);

	const { brands } = useFetchBrands(account_type_id, setError);

	const { orderId, lotNumber, status } = order;

	const [labelMakerData, setLabelMakerData] = useState({
		labelMakerOrder: {},
		status: -1,
	});

	let labelMakerOrder: { orderId: any; lotNumber: any; status: string };

	const onUpdateStatus = () => {
		if (order && Object.keys(order).length > 0) {
			labelMakerOrder = {
				orderId,
				lotNumber,
				status,
			};
		}
		setLabelMakerData({ labelMakerOrder, status });
	};

	/* ************************* Get Layouts *********************************** */
	useEffect(() => {
		const { data } = getLayoutsUrl;
		if (data && data.message) {
			if (!data.message.url) {
				toast.error("Invalid response from the server.");
			} else {
				const { url } = data.message;

				fetch(url, {
					method: "GET",
				})
					.then(async (response) => {
						setZippingInProgress(true);
						const json = await response.json();
						zipAndDownload(json);
					})
					.catch(() => {
						setZippingInProgress(false);
						toast.error(
							"Error while downloading layouts. Please try again later."
						);
					});
			}
		}
	}, [getLayoutsUrl.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = getLayoutsUrl;
		if (error) {
			toast.error(
				"Unable to download layouts at this time. Please try again later."
			);
		}
	}, [getLayoutsUrl.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* ************************************************************************* */

	/* ************************* Recreate Layouts *********************************** */
	useEffect(() => {
		const { data } = getRecreateLabelImages;
		if (data && data.message) {
			if (data.message === "Done") {
				toast.success("Layouts recreated");
			} else {
				toast.error(
					"Unknown error while recreating layouts. Please try again later."
				);
			}
		}
	}, [getRecreateLabelImages.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = getRecreateLabelImages;
		if (error) {
			toast.error(
				"Unable to recreate layouts at this time. Please try again later."
			);
		}
	}, [getRecreateLabelImages.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* ************************************************************************* */

	const zipAndDownload = (images: any) => {
		const zip = jszip() as any;

		console.log("zipAndDownload");

		const { lotNumber, brandId, orderItems } = order;

		let orderBrand = brands?.find((brand: any) => {
			return brand.id === brandId;
		});

		if (!orderBrand) {
			orderBrand = "Cacotec";
		}

		const zipRoot = zip.folder(
			`${orderBrand?.name} - ${lotNumber}`.replaceAll("/", "_")
		);

		const orderArrayForZip: OrderItemForZip[] = [];

		for (const [currIndex, orderItem] of orderItems.entries()) {
			const { styleNumber } = orderItem;
			let zipFolder = zipRoot.folder(`${styleNumber}`.replaceAll("/", "_"));

			let foundIndex = orderArrayForZip.findIndex(
				(x) => x.styleNumber === styleNumber
			);
			if (foundIndex === -1) {
				let orderItemForZip = {} as OrderItemForZip;
				orderItemForZip.styleNumber = styleNumber;
				orderItemForZip.styleQty = 0;
				orderArrayForZip.push(orderItemForZip);
			} else {
				orderArrayForZip[foundIndex].styleQty += 1;
				zipFolder = zipRoot.folder(
					`${styleNumber}(${orderArrayForZip[foundIndex].styleQty})`.replaceAll(
						"/",
						"_"
					)
				);
			}

			for (const imageObject of images[currIndex]) {
				var data = atob(
						imageObject.image?.substring("data:image/png;base64,".length)
					),
					asArray = new Uint8Array(data.length);

				for (var i = 0, len = data.length; i < len; ++i) {
					asArray[i] = data.charCodeAt(i);
				}

				var blob = new Blob([asArray.buffer], { type: "image/png" });

				zipFolder.file(imageObject.name.replaceAll("/", "_"), blob);
			}
		}

		zip.generateAsync({ type: "blob" }).then((blob: any) => {
			const fileDownload = jsFileDownload;
			fileDownload(
				blob,
				`${orderBrand?.name} - ${lotNumber}.zip`.replaceAll("/", "_")
			);
		});

		setZippingInProgress(false);
	};

	const onRecreateLayouts = () => {
		getRecreateLabelImages.doFetch(`/maker/draw/drawAgain/${orderId}`);
	};

	const copyToClipboardOrderSummary = () => {
		const {
			billingAddress,
			shippingAddress,
			shippingMethod,
			placedBy,
			note,
			lotNumber,
		} = order as any;

		let value = "";
		const billingAddressString = addressJSONToString(billingAddress);
		const shippingAddressString = addressJSONToString(shippingAddress);

		value += `LOT: ${lotNumber} \n`;
		value += note ? `Note: ${note}\n` : "";
		value += billingAddressString ? `Bill to: ${billingAddressString}\n` : "";
		value += shippingAddressString ? `Ship to: ${shippingAddressString}\n` : "";
		value += shippingMethod ? `Shipping method: ${shippingMethod}\n` : "";
		value += placedBy ? `Placed by: ${placedBy}\n` : "";

		copyToClipboardAsync(value, `Copied order summary`).catch((rej) => {
			console.log(rej);
		});
	};

	const copyToClipboardStylesInfo = () => {
		const { orderItems } = order as any;
		let value = "";

		value += `LOT: ${order.lotNumber} \n\n`;

		orderItems.forEach((orderItem: any) => {
			let totalQuantityWhite = 0;
			let totalQuantityBlack = 0;

			value += `Style number: ${orderItem.styleNumber} \n\n`;

			orderItem.units.map((unit: IUnit) => {
				const { size, quantity, blackLabel } = unit;
				if (blackLabel) {
					totalQuantityBlack += Number(quantity);
				} else {
					totalQuantityWhite += Number(quantity);
				}
				value += `${size ? `Size: ${size} ; ` : ""}${
					blackLabel ? "Black ground label" : "White ground label"
				} - ${quantity} pcs \n`;
				return undefined;
			});

			value += `\nTotal quantity white: ${totalQuantityWhite} pcs \n`;
			value += `Total quantity black: ${totalQuantityBlack} pcs \n`;

			value += `\nTotal quantity: ${
				totalQuantityWhite + totalQuantityBlack
			} pcs \n\n\n`;
		});
		copyToClipboardAsync(value, `Copied styles info`).catch((rej) => {
			console.log(rej);
		});
	};

	const copyToClipboardAsync = (str: string, toastMessage: string) => {
		if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
			toast.success(toastMessage);
			return navigator.clipboard.writeText(str);
		} else {
			return Promise.reject("The Clipboard API is not available.");
		}
	};

	const downloadZip = () => {
		const { orderId } = order as any;
		getLayoutsUrl.doFetch(`/maker/orders/downloadLayouts/${orderId}`);
	};

	const onChangeStatus = (status: number | undefined) => {
		setOrder((prevOrder: any) => {
			return { ...prevOrder, status };
		});
	};

	return (
		<div>
			{labelMakerData?.status > 0 && (
				<LabelMakerEditStatusModal
					labelMakerData={labelMakerData}
					setLabelMakerData={setLabelMakerData}
					onChangeStatus={onChangeStatus}
				/>
			)}
			<div className="pretitle flex-center-secondary-axis mt--base">
				<span className="line-height-1">ADDITIONAL INFO</span>
			</div>
			<div className="bg--light pd--base">
				<div className="flex">
					<div className="mb--sm flex-1">
						<div className="text-title-order-preview">Placed by:</div>
						{order.placedBy || " / "}
					</div>
					<div className="mb--sm flex-1">
						<div className="text-title-order-preview">Order summary</div>
						<button
							className="orderPreviewCopySummaryInfo btn-no-style"
							onClick={() => copyToClipboardOrderSummary()}
						>
							<p>Copy info</p>
							<span
								className="inline-block valign-middle ml--sm"
								title="Copy to clipboard"
							>
								<Icon name="copy" />
							</span>
						</button>
					</div>
				</div>
				<div className="flex">
					<div className="flex-1">
						{status !== OrderStatusesFlow.CANCELED.code && (
							<div>
								<div className="text-title-order-preview">Label images</div>
								<button
									className="orderPreviewCopySummaryInfo btn-no-style"
									onClick={() => downloadZip()}
									title="Download ZIP"
								>
									{!getLayoutsUrl.isLoading && !zippingInProgress && (
										<p>Download ZIP</p>
									)}
									{getLayoutsUrl.isLoading && <p>Getting layouts...</p>}
									{zippingInProgress && <p>Packing...</p>}
									<p>
										<span className="inline-block ml--xs valign-middle ml--sm">
											<Icon name="download" />
										</span>
									</p>
								</button>

								<button
									className="orderPreviewCopySummaryInfo btn-no-style"
									onClick={() => onRecreateLayouts()}
									title="Recreate layouts"
								>
									{!getRecreateLabelImages.isLoading && <p>Recreate layouts</p>}
									{getRecreateLabelImages.isLoading && <p>Recreating...</p>}
									<p>
										<span className="inline-block ml--xs valign-middle ml--sm">
											<Icon name="refresh" />
										</span>
									</p>
								</button>
							</div>
						)}
					</div>
					<div className="mb--sm flex-1">
						<div>
							<div className="text-title-order-preview">Styles summary</div>
							<button
								className="orderPreviewCopySummaryInfo btn-no-style"
								onClick={() => copyToClipboardStylesInfo()}
							>
								<p>Copy info</p>
								<p>
									<span
										className="inline-block valign-middle ml--sm"
										title="Copy to clipboard"
									>
										<Icon name="copy" />
									</span>
								</p>
							</button>
						</div>
					</div>
				</div>
				<div className="flex-1">
					<div className="text-title-order-preview">Status</div>
					<div
						onClick={onUpdateStatus}
						className="flex-center-secondary-axis fill-icon-blue"
						role="button"
						title="Update status"
					>
						<button className="btn-no-style mr--sm">Update status</button>
						<Icon name="cog" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default OrderPreviewAdditionalInfo;
