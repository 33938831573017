import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import Icon from "../Shared/Icon";
import Loading from "../Shared/Loading";

import useDataApi from "Hooks/fetchHook";
import { EndpointPrefix } from "Models/UserModels";
import { OrderStatusesFlow, initialShipment } from "Models/OrderModels";

interface IProps {
	status: number;
	orderId: number;
	shippingMethodsObj: any;
	labelMakerData: any;

	setModalIsDirty: (modalIsDirty: boolean) => void;
	onCloseModal: () => void;
}

const LabelMakerShipment: React.FunctionComponent<IProps> = ({
	status,
	orderId,
	shippingMethodsObj,
	labelMakerData,

	setModalIsDirty,
	onCloseModal,
}) => {
	const { user } = useContext(GlobalDispatch);
	const { account_type_id } = user;

	const useSaveShipment = useDataApi();
	const useDeleteShipment = useDataApi();
	const useGetShipmentDetails = useDataApi();

	const [shipmentDetails, setShipmentDetails] = useState([]) as any;
	const [shipmentDetailsForIsDirty, setShipmentDetailsForIsDirty] = useState(
		[]
	) as any;

	const [currentShipment, setCurrentShipment] = useState(
		initialShipment
	) as any;

	const [currentShipmentIndex, setCurrentShipmentIndex] = useState(0);

	const { shippingMethod, trackingCode, comment } = currentShipment;

	let isStatusShipped =
		labelMakerData.status === OrderStatusesFlow.SHIPPED.code;

	useEffect(() => {
		if (shipmentDetails.length > 0) {
			const newShipmentDetails = [...shipmentDetails];

			newShipmentDetails[currentShipmentIndex] = currentShipment;

			setShipmentDetails([...newShipmentDetails]);

			let modalIsDirty = newShipmentDetails.find((shipment) => {
				return !shipment.id || shipment.isDirty;
			});

			setModalIsDirty(!!modalIsDirty);
		}
	}, [currentShipment]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			(status === OrderStatusesFlow.SHIPPED.code ||
				status === OrderStatusesFlow.PARTIALLY_SHIPPED.code) &&
			orderId
		) {
			getShippingInfo();
		}
	}, [status, orderId]); // eslint-disable-line react-hooks/exhaustive-deps

	// ************************* START - Get shipment details ******************************

	useEffect(() => {
		const { data } = useGetShipmentDetails;
		if (data && data.message && data.message.length > 0) {
			let shipmentDetailsForState = data.message.map((shipment: any) => {
				const { id, shipper_name, tracking_code, comment } = shipment;
				return {
					id,
					shippingMethod: shippingMethodsObj[shipper_name].code,
					trackingCode: tracking_code,
					comment,
					isDirty: false,
				};
			});
			setShipmentDetails([...shipmentDetailsForState]);
			setShipmentDetailsForIsDirty([...shipmentDetailsForState]);
			setCurrentShipment(shipmentDetailsForState[0]);
			setCurrentShipmentIndex(0);
		} else if (!data.message || data.message.length === 0) {
			setShipmentDetails([initialShipment]);
			setShipmentDetailsForIsDirty([initialShipment]);
			setCurrentShipment(initialShipment);
			setCurrentShipmentIndex(0);
		}
	}, [useGetShipmentDetails.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetShipmentDetails;
		if (error) {
			toast.error(`Unable to get shipment details. ${error}`);
		}
	}, [useGetShipmentDetails.error]); // eslint-disable-line react-hooks/exhaustive-deps

	// ************************* END - Get shipment details ******************************

	// ************************* START - Save shipment ******************************

	useEffect(() => {
		const { data } = useSaveShipment;
		if (data.status === "ok") {
			toast.success("Shipment details updated.");
			let currentShipmentDetailsForIsDirty = [...shipmentDetailsForIsDirty];
			currentShipmentDetailsForIsDirty[currentShipmentIndex] = currentShipment;
			setShipmentDetailsForIsDirty(currentShipmentDetailsForIsDirty);
			setCurrentShipment((prevCurrentShipment: any) => {
				return {
					...prevCurrentShipment,
					isDirty: false,
					...(!prevCurrentShipment.id && { id: data.message.shippingId }),
				};
			});
		}
	}, [useSaveShipment.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveShipment;
		if (error) {
			toast.error(`Unable to change shipping part. ${error}`);
		}
	}, [useSaveShipment.error]); // eslint-disable-line react-hooks/exhaustive-deps

	// ************************* END - Save shipment ******************************

	// ************************* START - Delete shipment ******************************

	useEffect(() => {
		const { data } = useDeleteShipment;
		if (data.status === "ok") {
			toast.success("Shipment successfully deleted.");

			let deletedShipmentId = data.message.deletedShipmentId;
			let shipmentDetailsAfterRemove = shipmentDetails.filter(
				(shipment: any) => {
					return deletedShipmentId !== shipment.id;
				}
			);

			setShipmentDetails(shipmentDetailsAfterRemove);

			setShipmentDetailsForIsDirty(
				shipmentDetailsForIsDirty.filter((shipmentForIsDirty: any) => {
					return deletedShipmentId !== shipmentForIsDirty.id;
				})
			);
			setCurrentShipment(shipmentDetailsAfterRemove[0]);
			setCurrentShipmentIndex(0);
		}
	}, [useDeleteShipment.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useDeleteShipment;
		if (error) {
			toast.error(`Unable to delete shipment. ${error}`);
		}
	}, [useSaveShipment.error]); // eslint-disable-line react-hooks/exhaustive-deps

	// ************************* END - Delete shipment ******************************

	const onChangeTrackingCode = (newTrackingCode: string) => {
		let isDirty =
			shipmentDetailsForIsDirty[currentShipmentIndex].trackingCode !==
			newTrackingCode;

		setCurrentShipment((prevCurrentShipment: any) => {
			return { ...prevCurrentShipment, trackingCode: newTrackingCode, isDirty };
		});
	};

	const onChangeComment = (newComment: string) => {
		let isDirty =
			shipmentDetailsForIsDirty[currentShipmentIndex].comment !== newComment;
		setCurrentShipment((prevCurrentShipment: any) => {
			return { ...prevCurrentShipment, comment: newComment, isDirty };
		});
	};

	const onChangeShippingMethod = (newShippingMethod: string) => {
		let isDirty =
			shipmentDetailsForIsDirty[currentShipmentIndex].shippingMethod !==
			newShippingMethod;

		setCurrentShipment((prevCurrentShipment: any) => {
			return {
				...prevCurrentShipment,
				shippingMethod: newShippingMethod,
				isDirty,
			};
		});
	};

	const onAddNewShipment = () => {
		setShipmentDetails([initialShipment, ...shipmentDetails]);
		setShipmentDetailsForIsDirty([
			initialShipment,
			...shipmentDetailsForIsDirty,
		]);
		setCurrentShipment(initialShipment);
		setCurrentShipmentIndex(0);
	};

	const onEditShipment = (shipment: any, index: number) => {
		setCurrentShipment(shipment);
		setCurrentShipmentIndex(index);
	};

	const onRemoveShipment = (shipment: any, index: number) => {
		if (shipment.id) {
			let data = { orderId, shippingId: shipment.id };
			useDeleteShipment.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/shipping`,
				data,
				"DELETE"
			);
		} else {
			let shipmentDetailsAfterRemove = shipmentDetails.filter(
				(shipment: any, shipmentDetailsIndex: number) => {
					return index !== shipmentDetailsIndex;
				}
			);
			setShipmentDetails(shipmentDetailsAfterRemove);

			setShipmentDetailsForIsDirty(
				shipmentDetailsForIsDirty.filter(
					(shipmentForIsDirty: any, shipmentDetailsForIsDirtyIndex: number) => {
						return index !== shipmentDetailsForIsDirtyIndex;
					}
				)
			);
			setCurrentShipment(shipmentDetailsAfterRemove[0]);
			setCurrentShipmentIndex(0);
		}
	};

	const saveShipping = (shipment: any, index: number) => {
		onEditShipment(shipment, index);
		const { shippingMethod, trackingCode, comment } = shipment;
		let data = {
			status: parseInt(status as any, 10),
			orderId,
			shippingMethod,
			trackingCode,
			comment,
		} as any;

		if (!shipment.id) {
			useSaveShipment.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/shipping`,
				data,
				"POST"
			);
		} else {
			data.shippingId = shipment.id;
			useSaveShipment.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/shipping`,
				data,
				"PUT"
			);
		}
	};

	const getShippingInfo = () => {
		useGetShipmentDetails.doFetch(
			`/${EndpointPrefix[account_type_id]}/orders/shipping/${orderId}`
		);
	};

	return (
		<div className="flex-1 flex flex-column">
			<Loading
				show={useSaveShipment.isLoading || useGetShipmentDetails.isLoading}
				text={"Loading..."}
				imgClass="block-center"
				divClass="main__content"
			/>

			{!useSaveShipment.isLoading && !useGetShipmentDetails.isLoading && (
				<>
					{status === OrderStatusesFlow.PARTIALLY_SHIPPED.code && (
						<div style={{ marginBottom: 0 }}>
							<div
								className="button button-xs"
								id="addStyleNumberButton"
								data-toggle="drawer"
								onClick={() => onAddNewShipment()}
							>
								<div className="blueIcon">
									<Icon
										className="icon mr--xs valign-middle"
										name="plus-rounded"
									/>
								</div>
								<span className="strong">Add Shipment</span>
							</div>
						</div>
					)}

					<div style={{ overflowY: "auto", maxHeight: "140px" }}>
						{shipmentDetails &&
							shipmentDetails.length > 0 &&
							shipmentDetails.map((shipment: any, index: number) => {
								const { id, shippingMethod, trackingCode, isDirty } = shipment;
								const shipping = Object.values(shippingMethodsObj).find(
									(method: any) =>
										Number(method.code) === Number(shippingMethod)
								) as any;

								return (
									<div
										className="mb-xs"
										style={{
											display: "flex",
											marginTop: "10px",
											width: "180px",
										}}
										key={`${id}-${index}`}
									>
										<div
											className={`shipmentItem ${
												currentShipmentIndex === index && "currentShipment"
											}`}
											onClick={() => onEditShipment(shipment, index)}
											role="button"
										>
											<span className="strong truncate">
												{shipping || trackingCode
													? `${shipping ? shipping.name : ""}/${trackingCode}`
													: "NEW SHIPPING"}
											</span>
										</div>
										{(!id || isDirty) && (
											<button
												className="btn-no-style btn-link ml--base"
												onClick={() => {
													saveShipping(shipment, index);
												}}
											>
												Save
											</button>
										)}
										{currentShipmentIndex === index &&
											!isStatusShipped &&
											shipmentDetails.length > 1 && (
												<button
													className="btn-no-style btn-link ml--base"
													style={{ color: "red" }}
													onClick={() => {
														onRemoveShipment(shipment, index);
													}}
												>
													Remove
												</button>
											)}
									</div>
								);
							})}
					</div>

					<div className="form-group" style={{ margin: "10px 0 0 0" }}>
						<label htmlFor="shippingMethod">Shipping method:</label>
						<select
							className="form-control"
							value={shippingMethod}
							style={{
								pointerEvents: `${isStatusShipped ? "none" : "auto"}`,
							}}
							id="shippingMethod"
							onChange={(event) => onChangeShippingMethod(event.target.value)}
						>
							<option key={"---"} value={"---"} style={{ display: "none" }}>
								---
							</option>
							{Object.values(shippingMethodsObj).map((value: any) => {
								return (
									<option key={value.code} value={value.code}>
										{value.name}
									</option>
								);
							})}
						</select>

						<label htmlFor="trackingCode">Tracking code:</label>
						<input
							className="form-control"
							placeholder="Tracking code"
							id="trackingCode"
							style={{
								pointerEvents: `${isStatusShipped ? "none" : "auto"}`,
							}}
							value={trackingCode}
							onChange={(e) => onChangeTrackingCode(e.target.value)}
						/>

						<label htmlFor="comment">Comment:</label>
						<textarea
							className="form-control"
							style={{
								overflowX: "hidden",
								pointerEvents: `${isStatusShipped ? "none" : "auto"}`,
							}}
							placeholder="Comment"
							id="comment"
							value={comment ? comment : ""}
							onChange={(e) => onChangeComment(e.target.value)}
						></textarea>
					</div>
					<div className="flex flex-center-both-axis flex-1">
						<button
							className="button button--primary button--block"
							style={{ margin: "0", width: "30%" }}
							onClick={onCloseModal}
						>
							Close
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default LabelMakerShipment;
