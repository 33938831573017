import { useNavigate } from "react-router-dom-v5-compat";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import useDataApi from "Hooks/fetchHook";
import { IOrderState } from "Models/OrderModels";
import { useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { deepCopy } from "Utils/utils";

const useAddNewOrder = (
	order: IOrderState,
	newOrderFirstSavedRef: { current: boolean },
	setOrder: any,
	setLoadedOrder: any,
	saveOrderFinishedSuccessfully: React.MutableRefObject<boolean>
) => {
	const { user } = useContext(GlobalDispatch);

	const navigate = useNavigate();

	const useAddNewOrder = useDataApi();
	const showSaveResultNotificationRef = useRef(true) as any;

	useEffect(() => {
		const { data } = useAddNewOrder;
		if (data.message) {
			if (showSaveResultNotificationRef.current) {
				toast.success("The order was saved successfully.");
			}
			const { order_id } = data.message;

			if (order_id) {
				const savedOrder = {
					...order,
					orderId: order_id,
					log: {
						createdBy: user.name,
						doneAt: "a few moments ago",
						lastChangeBy: user.name,
						lastChangeAt: "a few moments ago",
					},
				};
				setOrder(savedOrder);
				setLoadedOrder(deepCopy(savedOrder));

				newOrderFirstSavedRef.current = true;
				navigate(`/order/${order_id}`, { replace: true });
				document.title = `Edit Order - ${user.name} - Cacotec`;
				if (!showSaveResultNotificationRef.current) {
					saveOrderFinishedSuccessfully.current = true;
				}
			}
		}
	}, [useAddNewOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useAddNewOrder;
		if (error) {
			toast.error(
				`Unable to save order. ${error[0].toUpperCase() + error.slice(1)}.`
			);

			let {
				shippingAddress,
				billingAddress,
				preferredShippingMethod,
				...orderWithoutShippingDetails
			} = order;
			setOrder(orderWithoutShippingDetails);
		}
	}, [useAddNewOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps

	let addNewOrderDoFetch = useAddNewOrder.doFetch;
	let addNewOrderIsLoading = useAddNewOrder.isLoading;

	return {
		showSaveResultNotificationRef,
		addNewOrderDoFetch,
		addNewOrderIsLoading,
	};
};

export default useAddNewOrder;
