import { FC, useContext } from "react";
import { OrderStatusesFlow } from "Models/OrderModels";
import OrderDispatch from "Dispatches/OrderDispatch";

interface IProps {
	sharePill?: boolean;

	onPlaceOrder: () => void;
}

const OrderPlaceButton: FC<IProps> = ({ sharePill, onPlaceOrder }) => {
	const {
		order: { orderItems, status },
	} = useContext(OrderDispatch);

	let isEnabled =
		orderItems.length > 0 && status !== OrderStatusesFlow.CHANGE_REQUESTED.code;

	return (
		<div data-tooltip-id="placeOrder" className="ml--lg">
			<button
				className={` button ${
					isEnabled ? "button--primary" : "button--disabled"
				}
        ${sharePill && "button-custom-place-order"}
          
          `}
				type="button"
				form="orderItemForm"
				onClick={onPlaceOrder}
				data-testid="orderHeader-placeOrder"
			>
				Place
			</button>
		</div>
	);
};

export default OrderPlaceButton;
