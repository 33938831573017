export const FETCH_GET_PARAMS: RequestInit = {
	method: "GET",
	headers: [
		["Content-Type", "application/json"],
		["Version", `${import.meta.env.VITE_APP_VERSION_CUSTOM}`],
	],
	body: null,
	credentials: "include",
	mode: "cors",
};

export const URL_BASE = {
	address: import.meta.env.VITE_APP_BACKEND_URL ?? "http://localhost:8080/l",
};
export class CustomError extends Error {
	status;

	constructor(message: string, status: number) {
		super(message);
		this.status = status;

		Object.setPrototypeOf(this, CustomError.prototype);
	}
}
