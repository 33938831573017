import { useRef, useEffect, useContext, useState, FC } from "react";
import useOnClickOutside from "Hooks/outsideClickHook";
import useOnKeyDown from "Hooks/onKeyDownHook";
import Icon from "./Icon";
import useDataApi from "Hooks/fetchHook";
import { toast } from "react-toastify";
import { EndpointPrefix } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import Loading from "./Loading";
import Popup from "./Popup";

type printerFineTuning = {
	fromLeft: number;
	heatPoints: number;
	printSpeed: number;
};

interface IProps {
	labelTemplateId: number;
	printBatchId: number;

	setShowPrinterFineTuningModal: (showPrinterFineTuningModal: boolean) => void;
}

const PrinterFineTuningModal: FC<IProps> = ({
	labelTemplateId,
	printBatchId,

	setShowPrinterFineTuningModal,
}) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const useGetLabelTemplatePrinterSettings = useDataApi();
	const usePrintTestLabel = useDataApi();
	const useSaveLabelTemplatePrinterSettings = useDataApi();

	const [isEnabled, setIsEnabled] = useState(false);

	const modalRef = useRef(null) as any;

	const heatInputRef = useRef<HTMLInputElement>(null);
	const speedInputRef = useRef<HTMLInputElement>(null);
	const fromLeftInputRef = useRef<HTMLInputElement>(null);

	const [newPrinterFineTuning, setNewPrinterFineTuning] = useState<
		printerFineTuning | undefined
	>(undefined);

	const [newPrinterFineTuningInitial, setNewPrinterFineTuningInitial] =
		useState<printerFineTuning | undefined>(undefined);

	const HEAT_INPUT_MAX = 20;
	const HEAT_INPUT_MIN = 0;

	const FROM_LEFT_MIN = -5;
	const FROM_LEFT_MAX = 5;

	const SPEED_MAX = 100;
	const SPEED_MIN = 50;
	const SPEED_STEP = 25;

	useEffect(() => {
		let isEnabled =
			heatInputRef?.current?.validity.valid &&
			speedInputRef?.current?.validity.valid &&
			fromLeftInputRef?.current?.validity.valid;

		setIsEnabled(!!isEnabled);
	}, [
		heatInputRef?.current?.validity.valid,
		speedInputRef?.current?.validity.valid,
		fromLeftInputRef?.current?.validity.valid,
	]);

	/* ************************* Get Printing Settings ********************************* */

	useEffect(() => {
		useGetLabelTemplatePrinterSettings.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/getTemplatePrinterSettings/${labelTemplateId}`
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetLabelTemplatePrinterSettings;
		if (data && data.message) {
			setNewPrinterFineTuning(data.message.printer_settings);
			setNewPrinterFineTuningInitial(data.message.printer_settings);
			setIsEnabled(true);
		}
	}, [useGetLabelTemplatePrinterSettings.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetLabelTemplatePrinterSettings;
		if (error) {
			toast.error(`Get print settings failed. ${error}`);
		}
	}, [useGetLabelTemplatePrinterSettings.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************************************************************** */

	/* ************************* Print test label ********************************* */

	useEffect(() => {
		const { data } = usePrintTestLabel;
		if (data && data.message) {
		}
	}, [usePrintTestLabel.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = usePrintTestLabel;
		if (error) {
			toast.error(`Unable to print test label. ${error}`);
		}
	}, [usePrintTestLabel.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************************************************************** */

	/* ************************* Save Printing Settings  ********************************* */

	useEffect(() => {
		const { data } = useSaveLabelTemplatePrinterSettings;
		if (data && data.message) {
			toast.success("Print settings successfully saved");
			setShowPrinterFineTuningModal(false);
		}
	}, [useSaveLabelTemplatePrinterSettings.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveLabelTemplatePrinterSettings;
		if (error) {
			toast.error(`Unable to save printer settings. ${error}`);
		}
	}, [useSaveLabelTemplatePrinterSettings.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************************************************************** */

	/* *******************************************************************************/
	const onCancelPrinterFineTuningModal = () => {
		let modalIsDirty = isDirty();

		if (modalIsDirty) {
			if (
				window.confirm(
					"You have unsaved changes, are you sure you want to leave?"
				)
			) {
				setShowPrinterFineTuningModal(false);
			}
		} else {
			setShowPrinterFineTuningModal(false);
		}
	};

	const isDirty = () => {
		let key: keyof printerFineTuning;

		if (newPrinterFineTuning && newPrinterFineTuningInitial) {
			for (key in newPrinterFineTuning) {
				if (newPrinterFineTuning[key] !== newPrinterFineTuningInitial[key]) {
					return true;
				}
			}
		}
		return false;
	};

	/* *******************************************************************************/

	useOnKeyDown("Escape", onCancelPrinterFineTuningModal);

	/* *******************************************************************************/
	useOnClickOutside(modalRef, onCancelPrinterFineTuningModal); // eslint-disable-line react-hooks/exhaustive-deps

	const onPrintTestLabel = () => {
		usePrintTestLabel.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/testPrinting`,
			{ batchId: printBatchId, printerSettings: newPrinterFineTuning },
			"POST"
		);
	};

	const onSaveLabelTemplatePrinterSettings = () => {
		useSaveLabelTemplatePrinterSettings.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/savePrinterSettings`,
			{ batchId: printBatchId, printerSettings: newPrinterFineTuning },
			"POST"
		);
	};

	return (
		<div className="modal flex-center-both-axis">
			<div
				className="modal-content-order-summary modal-content-fine-tuning show"
				ref={modalRef}
				tabIndex={1}
			>
				<div className="flex row reverse" style={{ height: "40px" }}>
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label="Close"
						onClick={onCancelPrinterFineTuningModal}
						style={{ position: "relative" }}
					>
						<Icon name="cross-rounded" />
					</button>
				</div>

				<div className="mt--sm mb--base flex-center-both-axis">
					<h1>Printer fine tuning</h1>
				</div>

				<Loading
					show={useGetLabelTemplatePrinterSettings.isLoading}
					text={`Loading...`}
					imgClass="block-center"
					divClass="printer-fine-settings-modal-loading pd--base"
				/>

				{!useGetLabelTemplatePrinterSettings.isLoading &&
					newPrinterFineTuning && (
						<>
							<div className="pd--base flex">
								<div className="flex-1 form-group mr--sm">
									<Popup
										show={
											newPrinterFineTuning.fromLeft > FROM_LEFT_MAX ||
											newPrinterFineTuning.fromLeft < FROM_LEFT_MIN
										}
										text={`Value must be greater than or equal to ${FROM_LEFT_MIN}, lesser than or equal to  ${FROM_LEFT_MAX}.`}
										testId="unitInformation-quantity"
									/>
									<div className="upcase strong">X displacement (mm)</div>
									<input
										type="number"
										className="form-control"
										ref={fromLeftInputRef}
										value={newPrinterFineTuning.fromLeft}
										onChange={(e) => {
											setNewPrinterFineTuning((prevInfo: any) => {
												return {
													...prevInfo,
													fromLeft: parseInt(
														e.target.value ? e.target.value : "0"
													),
												};
											});
										}}
										min={FROM_LEFT_MIN}
										max={FROM_LEFT_MAX}
										step={1}
									/>
								</div>
								<div className="flex-1 form-group ml--sm mr--sm">
									<Popup
										show={
											newPrinterFineTuning.heatPoints > HEAT_INPUT_MAX ||
											newPrinterFineTuning.heatPoints < HEAT_INPUT_MIN
										}
										text={`Value must be greater than or equal to ${HEAT_INPUT_MIN}, lesser than or equal to  ${HEAT_INPUT_MAX}.`}
										testId="unitInformation-quantity"
									/>
									<div className="upcase strong">Heat</div>
									<input
										type="number"
										className="form-control"
										ref={heatInputRef}
										value={newPrinterFineTuning.heatPoints}
										onChange={(e) => {
											setNewPrinterFineTuning((prevInfo: any) => {
												return {
													...prevInfo,
													heatPoints: parseInt(
														e.target.value ? e.target.value : "0"
													),
												};
											});
										}}
										min={HEAT_INPUT_MIN}
										max={HEAT_INPUT_MAX}
										step={1}
									/>
								</div>
								<div className="flex-1 form-group ml--sm">
									<Popup
										show={
											newPrinterFineTuning.printSpeed > SPEED_MAX ||
											newPrinterFineTuning.printSpeed < SPEED_MIN
										}
										text={`Value must be greater than or equal to ${SPEED_MIN}, lesser than or equal to  ${SPEED_MAX} and in increments of ${SPEED_STEP}.`}
										testId="unitInformation-quantity"
									/>
									<div className="upcase strong">Speed</div>
									<input
										type="number"
										className="form-control"
										ref={speedInputRef}
										value={newPrinterFineTuning.printSpeed}
										onChange={(e) => {
											setNewPrinterFineTuning((prevInfo: any) => {
												return {
													...prevInfo,
													printSpeed: parseInt(
														e.target.value ? e.target.value : "0"
													),
												};
											});
										}}
										min={SPEED_MIN}
										max={SPEED_MAX}
										step={SPEED_STEP}
									/>
								</div>
							</div>
							<div className="flex-center-both-axis">
								{usePrintTestLabel.isLoading ? (
									<Loading
										show={true}
										text={`Loading...`}
										imgClass="printerInfoLoading"
										divClass=""
									/>
								) : (
									<button
										className={`button button--primary button--sm mt--sm mb--sm ${
											!isEnabled && "button--disabled"
										}`}
										onClick={onPrintTestLabel}
									>
										Print Test Label
									</button>
								)}
							</div>
							<div
								className="flex flex-center-both-axis mt--base"
								style={{
									justifyContent: "space-evenly",
									borderTop: "2px solid #dfdfdf",
									padding: "20px 0",
								}}
							>
								<button
									className={`button button--primary button--lg`}
									onClick={onCancelPrinterFineTuningModal}
								>
									Cancel
								</button>

								{useSaveLabelTemplatePrinterSettings.isLoading ? (
									<Loading
										show={true}
										text={`Loading...`}
										imgClass="saveOrderLoading"
										divClass=""
									/>
								) : (
									<button
										className={`button button--primary button--lg ${
											!isEnabled && "button--disabled"
										}`}
										style={{ width: "126px" }}
										onClick={onSaveLabelTemplatePrinterSettings}
									>
										Save
									</button>
								)}
							</div>
						</>
					)}
			</div>
		</div>
	);
};

export default PrinterFineTuningModal;
