import { useContext, useEffect, useState } from "react";

import OrderDispatch from "Dispatches/OrderDispatch";
import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import IncrementalSearchResults from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchResults";
import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import { FUR_ORIGIN_LIST, IDefaultOption } from "Models/OrderModels";

const FurOrigin = () => {
	const { orderItem, setOrderItem } = useContext(OrderDispatch);
	const { extraInfo: orderItemExtraInfo } = orderItem;

	const incrementalSearchHookProps = useIncrementalSearchHook(FUR_ORIGIN_LIST);

	const { setInputText, setShowList } = incrementalSearchHookProps;

	const [focusOnSearchList, setFocusOnSearchList] = useState(false);

	let furOrigin: any;
	if (orderItemExtraInfo) {
		({ furOrigin } = orderItemExtraInfo);
	}

	useEffect(() => {
		// Edit added order item
		const furOriginOption = FUR_ORIGIN_LIST.find(
			(furOriginObject: IDefaultOption) => furOriginObject.id === furOrigin
		);
		if (furOriginOption) {
			const furOriginText = furOriginOption.name;
			setInputText(furOriginText);
		} else {
			setInputText("");
		}
	}, [furOrigin, FUR_ORIGIN_LIST, setInputText]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChooseItemCallback = (item: any) => {
		if (item) {
			setOrderItem({
				extraInfo: { ...orderItemExtraInfo, furOrigin: item.id },
			});
			setInputText(item.name);
		}
	};

	const onChooseItemFromList = (item: any) => {
		onChooseItemCallback(item);
		setShowList(false);
	};

	return (
		<div className="form-group">
			<IncrementalSearchInput
				labelText="Fur Origin"
				name="furOrigin"
				autoFocus={false}
				setFocusOnSearchList={setFocusOnSearchList}
				onChangeInputText={() =>
					setOrderItem({
						extraInfo: { ...orderItemExtraInfo, furOrigin: undefined },
					})
				}
				onInputBlur={onChooseItemCallback}
				incrementalSearchHookProps={incrementalSearchHookProps}
			/>

			<IncrementalSearchResults
				focusOnSearchList={focusOnSearchList}
				testId="furOrigin"
				onChooseItemCallback={onChooseItemFromList}
				incrementalSearchHookProps={incrementalSearchHookProps}
			/>
		</div>
	);
};

export default FurOrigin;
