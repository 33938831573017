import React, { useContext } from "react";

import OrderDispatch from "Dispatches/OrderDispatch";
import { blockFloatingPointNumbers } from "Utils/utils";

const InsulationWeight: React.FunctionComponent = () => {
	const { orderItem, setOrderItem } = useContext(OrderDispatch);
	const { extraInfo: orderItemExtraInfo } = orderItem;

	let insulationWeight;
	if (orderItemExtraInfo) {
		({ insulationWeight } = orderItemExtraInfo);
	}

	return (
		<div className="form-group">
			<input
				className="form-control"
				type="number"
				max="10000"
				min="1"
				name="insulationWeight"
				id="insulationWeight"
				data-empty={!insulationWeight}
				value={!insulationWeight ? "" : insulationWeight}
				onChange={(e) =>
					setOrderItem({
						extraInfo: {
							...orderItemExtraInfo,
							insulationWeight: Number(e.target.value),
						},
					})
				}
				onKeyDown={blockFloatingPointNumbers}
			/>
			<label className="form-control-label" htmlFor="insulationWeight">
				Insulation Weight
			</label>
		</div>
	);
};

export default InsulationWeight;
