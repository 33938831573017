import { useContext, useLayoutEffect } from "react";
import GlobalDispatch from "Dispatches/GlobalDispatch";

const usePageTitle = (page: string) => {
	const {
		user: { name },
	} = useContext(GlobalDispatch);

	useLayoutEffect(() => {
		document.title = `${page} - ${name} - Cacotec`;
	}, [page, name]);
};

export default usePageTitle;
