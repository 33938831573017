import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { toast } from "react-toastify";

import useOnClickOutside from "Hooks/outsideClickHook";
import Icon from "Components/Shared/Icon";
import AddressInput from "./AddressInput";
import { deepIsEqual } from "Utils/utils";
import { IAddress, initialAddress } from "Models/OrderModels";
import useDataApi from "Hooks/fetchHook";
import { EndpointPrefix } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import Loading from "Components/Shared/Loading";
import { ADDRESS_TEXT_LENGTH } from "Models/OrderModels";
import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";
import useOnKeyUp from "Hooks/onKeyUpHook";

interface IProps {
	addresses: IAddress[];
	addressIndex?: number;
	showAddressForm: boolean;

	setAddresses: (addresses: IAddress[]) => void;
	onCloseModal: () => void;
}

const Address: React.FunctionComponent<IProps> = ({
	showAddressForm,
	onCloseModal,
	addressIndex,
	addresses,
	setAddresses,
}) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const addressModalRef = useRef(null) as any;

	const useSaveAddresses = useDataApi();

	const [currentAddress, setCurrentAddress] = useState(
		initialAddress as IAddress
	);
	const [loadedAddress, setLoadedAddress] = useState(
		initialAddress as IAddress
	);
	const [invalidAddress, setInvalidAddress] = useState({}) as any;

	useEffect(() => {
		const onReloadPage = (e: any) => {
			const isDirtyAddress = !deepIsEqual(currentAddress, loadedAddress);

			if (isDirtyAddress) {
				e.preventDefault();
				e.returnValue =
					"All changes will be lost. Are you sure you want to continue?";
			}
		};

		window.addEventListener("beforeunload", onReloadPage);

		return () => {
			window.removeEventListener("beforeunload", onReloadPage);
		};
	}, [currentAddress, loadedAddress]);

	useEffect(() => {
		if (
			showAddressForm &&
			addressIndex !== undefined &&
			addresses[addressIndex]
		) {
			setCurrentAddress(addresses[addressIndex]);
			setLoadedAddress(addresses[addressIndex]);
		}
	}, [addressIndex, showAddressForm, addresses]);

	/* ******************** Save Addresses API call ************************ */
	useEffect(() => {
		const { error } = useSaveAddresses;
		if (error) {
			toast.error(`Unable to save address. ${error}`);
		}
	}, [useSaveAddresses.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useSaveAddresses;
		if (data.status === "ok") {
			toast.success("Address saved successfully.");
			const addresses = saveAddressToList();
			setAddresses(addresses);
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.SHIPPING_DETAILS, account_type_id],
			});
			onCloseModal();
		}
	}, [useSaveAddresses.data]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	const saveAddressToList = () => {
		const addressForList = setAddressForList(currentAddress);
		// Remove the previous default value if the current address is set to default
		const copyOfAddresses = addressForList.default
			? [...addresses].map((address: IAddress) => ({
					...address,
					default: false,
			  }))
			: [...addresses];

		// Edit existing address
		if (addressIndex !== undefined) {
			if (addressForList.default) {
				// Move current address on top if is set to default
				copyOfAddresses.splice(addressIndex, 1);
				copyOfAddresses.splice(0, 0, addressForList);
			} else {
				copyOfAddresses[addressIndex] = addressForList;
			}
			// Add new address
		} else {
			if (addressForList.default) {
				copyOfAddresses.splice(0, 0, addressForList);
			} else {
				// Move after default address
				copyOfAddresses.splice(1, 0, addressForList);
			}
		}
		return copyOfAddresses;
	};

	const onCloseAddressModal = () => {
		const isDirtyAddress = !deepIsEqual(currentAddress, loadedAddress);
		const isFormEmpty = deepIsEqual(currentAddress, initialAddress);
		if (
			(((Object.keys(invalidAddress).length > 0 && !isFormEmpty) ||
				isDirtyAddress) &&
				window.confirm(
					"Unsaved data will be lost. Are you sure you want to leave?"
				)) ||
			!isDirtyAddress
		) {
			onCloseModal();
			setCurrentAddress(loadedAddress);
		}
	};

	useOnClickOutside(
		addressModalRef,
		useCallback(onCloseAddressModal, [onCloseAddressModal])
	);

	const setAddressForList = (address: IAddress) => {
		const {
			company_name,
			full_name,
			phone_number,
			line1,
			line2,
			postal_code,
			city,
			state,
			country,
		} = address;
		const addressForList = {
			company_name,
			full_name,
			line1,
			postal_code,
			city,
			country,
			default: address.default,
		} as IAddress;

		if (phone_number) addressForList.phone_number = phone_number;
		if (line2) addressForList.line2 = address.line2;
		if (state) addressForList.state = address.state;

		return addressForList;
	};

	const onSaveAddress = () => {
		const { company_name, full_name, line1, postal_code, city, country } =
			currentAddress;
		const invalidAddress = {} as any;

		if (!company_name) invalidAddress.company_name = true;
		if (!full_name) invalidAddress.full_name = true;
		if (!line1) invalidAddress.line1 = true;
		if (!postal_code) invalidAddress.postal_code = true;
		if (!city) invalidAddress.city = true;
		if (!country) invalidAddress.country = true;

		if (Object.keys(invalidAddress).length > 0) {
			setInvalidAddress(invalidAddress);
		} else {
			const addresses = saveAddressToList();
			useSaveAddresses.doFetch(
				`/${EndpointPrefix[account_type_id]}/data/addresses`,
				{ addresses },
				"POST"
			);
		}
	};

	useOnKeyUp("Escape", onCloseAddressModal);

	return (
		<div className="modal">
			<div
				className="modal-content block-center modal--address show"
				tabIndex={-1}
				ref={addressModalRef}
			>
				<button
					className="drawer__close btn-no-style"
					data-dismiss="drawer"
					aria-label="Close"
					onClick={onCloseAddressModal}
				>
					<Icon name="cross-rounded" />
				</button>

				<header
					className="drawer__header"
					style={{ marginLeft: "30px", paddingBottom: "10px" }}
				>
					<h1 className="drawer__title">{`${
						addressIndex !== undefined ? "Edit" : "Add New"
					} Address`}</h1>
				</header>

				<fieldset className="box box--bordered modal--address-form">
					<AddressInput
						name="company_name"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText="Company name"
						maxLengthText={ADDRESS_TEXT_LENGTH.COMPANY_NAME}
					/>

					<AddressInput
						name="line1"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText=" Address Line 1 - Street, PO Box"
						maxLengthText={ADDRESS_TEXT_LENGTH.LINE1}
					/>

					<AddressInput
						name="line2"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText="Address Line 2 - Apartment, suit, unit, building (optional)"
						maxLengthText={ADDRESS_TEXT_LENGTH.LINE2}
					/>

					<AddressInput
						name="city"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText="City, district, suburb, town, or village"
						maxLengthText={ADDRESS_TEXT_LENGTH.CITY}
					/>

					<AddressInput
						name="state"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText="State, county, province, or region (optional)"
						maxLengthText={ADDRESS_TEXT_LENGTH.STATE}
					/>

					<AddressInput
						name="postal_code"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText="ZIP or postal code"
						maxLengthText={ADDRESS_TEXT_LENGTH.POSTAL_CODE}
					/>

					<AddressInput
						name="country"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText="Country name"
						maxLengthText={ADDRESS_TEXT_LENGTH.COUNTRY}
					/>

					<AddressInput
						name="full_name"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText="Recipient name"
						maxLengthText={ADDRESS_TEXT_LENGTH.FULL_NAME}
					/>

					<AddressInput
						name="phone_number"
						currentAddress={currentAddress}
						setCurrentAddress={setCurrentAddress}
						invalidAddress={invalidAddress}
						setInvalidAddress={setInvalidAddress}
						labelText="Recipients phone number (optional)"
						maxLengthText={ADDRESS_TEXT_LENGTH.PHONE_NUMBER}
					/>

					<div className="form-check" style={{ marginTop: "20px" }}>
						<input
							className="form-check__input"
							type="checkbox"
							checked={currentAddress?.default ? true : false}
							name="setAsDefault"
							id="setAsDefault"
							onChange={() =>
								setCurrentAddress({
									...currentAddress,
									default: !currentAddress.default,
								})
							}
						/>
						<label className="form-check__label" htmlFor="setAsDefault">
							Set as default
						</label>
					</div>
				</fieldset>

				<div className="button--save-address">
					<Loading
						show={useSaveAddresses.isLoading}
						text="Loading..."
						imgClass="imgLoading"
						divClass=""
					/>
					{!useSaveAddresses.isLoading && (
						<button
							className="button button--primary"
							style={{ width: "50%" }}
							type="submit"
							form="addressForm"
							data-testid="saveAddressButton"
							onClick={onSaveAddress}
						>
							Save address
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Address;
