import { useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";

import { addressJSONToString, isEllipsisActive } from "Utils/utils";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import ShippedPartShippedIconTooltip from "Components/Shared/ShippedPartShippedIconTooltip";
import Icon from "Components/Shared/Icon";

import { EndpointPrefix, isBrand } from "Models/UserModels";
import { OrderStatusesFlow, isPrintStatus } from "Models/OrderModels";

import useDataApi from "Hooks/fetchHook";
import { useFetchBrands } from "Hooks/queryHooks/useFetchBrands";

import jsFileDownload from "js-file-download";

interface IProps {
	order: any;
}

const OrderPreviewDetails = ({ order }: IProps) => {
	const noteRef = useRef<HTMLDivElement>(null);

	const [showNoteTooltip, setShowNoteTooltip] = useState(false);

	const {
		user: { account_type_id, org_name },
		setError,
	} = useContext(GlobalDispatch);

	const { brands } = useFetchBrands(account_type_id, setError);

	const useGetPdf = useDataApi();

	const { orderId, lotNumber, brandId, status, log } = order;

	let lastEditOn = new Date(log[1].done_at).toLocaleDateString();

	useEffect(() => {
		if (isEllipsisActive(noteRef)) {
			setShowNoteTooltip(true);
		}
	}, [noteRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************************* Get PDF *********************************** */
	useEffect(() => {
		const { data } = useGetPdf;

		if (data.type === "application/pdf") {
			let orderBrandName: string;

			if (isBrand(account_type_id)) {
				orderBrandName = org_name;
			} else {
				let orderBrand = brands?.find((brand: any) => {
					return brand.id === brandId;
				});
				orderBrandName = orderBrand?.name;
			}

			const fileDownload = jsFileDownload;
			fileDownload(
				data,
				`${orderBrandName || "Cacotec"} - ${lotNumber}.pdf`.replace("/", "_")
			);
		}
	}, [useGetPdf.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPdf;
		if (error) {
			toast.error(
				"Unable to download layouts at this time. Please try again later."
			);
		}
	}, [useGetPdf.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************************************************************************* */

	const downloadPdf = () => {
		const parameterizeOrderId = "?ids[]=" + orderId;

		useGetPdf.doFetch(
			`/${EndpointPrefix[account_type_id]}/orders/pdf${parameterizeOrderId}`
		);
	};

	const getOrderStatusName = () => {
		let statuses: any[] = Object.values(OrderStatusesFlow);

		for (const status of statuses) {
			if (status.code === order.status) {
				return status.name;
			}
		}
	};

	return (
		<div>
			<div className="orderPreviewInfo mt--md">
				<div className="pretitle flex-center-secondary-axis">
					<span className="line-height-1">ORDER DETAILS</span>
				</div>
				<div className="bg--light pd--base">
					<div className="flex">
						<div className="mb--sm pr--base width-50">
							<div className="text-title-order-preview">Layout:</div>
							{order.labelTemplate?.name}
						</div>
						<div className="mb--sm width-50">
							<div className="text-title-order-preview">Reference number: </div>
							{order.lotNumber}
						</div>
					</div>
					<div className="flex mb--sm">
						<div className="width-50 pr--base">
							<div className="text-title-order-preview">Note: </div>
							<div
								className="truncate"
								ref={noteRef}
								data-tooltip-id="note"
								data-event="none"
								data-iscapture="true"
							>
								{order.note || "/"}
								{/* Empty div added to prevent default title behavior on Safari with text-overflow: ellipsis  */}
								<div></div>
							</div>
						</div>
						{order.note && showNoteTooltip && (
							// Note tooltip
							<Tooltip
								id={"note"}
								className="reactTooltip whiteBackground"
								variant="light"
								offset={5}
								clickable={true}
								place="bottom"
								delayShow={400}
							>
								<div className="ml--xs" style={{ maxWidth: "300px" }}>
									<strong>Note: </strong>
									{order.note}
								</div>
							</Tooltip>
						)}
						<div className="width-50">
							{!isPrintStatus(order.status) ? (
								<>
									<div className="text-title-order-preview">Status:</div>
									<div>
										<span>{getOrderStatusName()}</span>
										{(order.status === OrderStatusesFlow.SHIPPED.code ||
											order.status ===
												OrderStatusesFlow.PARTIALLY_SHIPPED.code) && (
											<div className="ml--sm">
												<ShippedPartShippedIconTooltip
													id={order.orderId}
													status={order.status}
												/>
											</div>
										)}
										<span className="text--xs text--quiet">
											{" "}
											({lastEditOn})
										</span>
									</div>
								</>
							) : (
								<div className="mb--sm width-50">
									<div className="text-title-order-preview">Label images:</div>
									<button
										className="block btn-no-style"
										title="Download PDF"
										onClick={downloadPdf}
									>
										{!useGetPdf?.isLoading && (
											<div>
												<span>Download PDF</span>
												<span>
													<span className="inline-block ml--xs valign-middle ml--sm">
														<Icon name="download" />
													</span>
												</span>
											</div>
										)}
										{useGetPdf?.isLoading && <span>Downloading PDF...</span>}
									</button>
								</div>
							)}
						</div>
					</div>
					{!isPrintStatus(order.status) && (
						<div className="flex mb--sm">
							<div className="width-50 pr--base">
								<div className="text-title-order-preview">
									Shipping address:
								</div>
								{addressJSONToString(order.shippingAddress)
									.split("; ")
									.map((addressPart: any, index: number) => (
										<div key={`${addressPart}-${index}`}>{addressPart}</div>
									))}
							</div>
							<div className="width-50">
								<div className="text-title-order-preview">Billing address:</div>
								{addressJSONToString(order.billingAddress)
									.split("; ")
									.map((addressPart: any, index: number) => (
										<div key={`${addressPart}-${index}`}>{addressPart}</div>
									))}
							</div>
						</div>
					)}

					<div className="flex">
						{!isPrintStatus(order.status) && (
							<div className="width-50 pr--base">
								<div className="text-title-order-preview">Shipping method:</div>
								{order.shippingMethod}
							</div>
						)}

						{!isPrintStatus(order.status) &&
							status !== OrderStatusesFlow.CANCELED.code && (
								<div className="mb--sm width-50">
									<div className="text-title-order-preview">Label images:</div>
									<button
										className="block btn-no-style"
										title="Download PDF"
										onClick={downloadPdf}
									>
										{!useGetPdf?.isLoading && (
											<div>
												<span>Download PDF</span>
												<span>
													<span className="inline-block ml--xs valign-middle ml--sm">
														<Icon name="download" />
													</span>
												</span>
											</div>
										)}
										{useGetPdf?.isLoading && <span>Downloading PDF...</span>}
									</button>
								</div>
							)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderPreviewDetails;
