import React, { useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { TemplateStatuses } from "Models/OrderModels";
import { fullTimeFormat } from "Utils/utils";
import { useNavigate, Link } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";
import useDataApi from "Hooks/fetchHook";
import Icon from "../Shared/Icon";

interface IStateProps {
	template: any;
	refVar: any;

	setListWidth: (listWidth: number) => void;
	refreshTemplates: (
		forceRefresh?: boolean,
		resetTemplatesList?: boolean,
		forceOffset?: number
	) => void;
	setOffset: (offset: number) => void;
}

const TemplatesListItem: React.FunctionComponent<IStateProps> = ({
	template,
	refVar,

	setListWidth,
	refreshTemplates,
	setOffset,
}) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const {
		id,
		name,
		last_edit_on,
		last_edit_by,
		reference_number,
		status,
		last_used_on,
	} = template;

	const [tooltipFor, setTooltipFor] = useState(undefined) as any;

	const useUpdateTemplateStatus = useDataApi();
	const useDeleteTemplate = useDataApi();

	const listItem = useRef<any>(null);

	const observer = useRef<any>(
		new ResizeObserver((entries) => {
			const { width } = entries[0].contentRect;
			setListWidth(width);
		})
	);

	const navigate = useNavigate();

	const handleArchiveClick = (e: any, id: number) => {
		e.stopPropagation();
		const data = { status: TemplateStatuses.ARCHIVED.code };
		useUpdateTemplateStatus.doFetch(
			`/brand/data/updateTemplateStatus/${id}`,
			data,
			"PUT"
		);
	};

	const handleUnarchiveClick = (e: any, id: number) => {
		e.stopPropagation();
		const data = { status: TemplateStatuses.ACTIVE.code };
		useUpdateTemplateStatus.doFetch(
			`/brand/data/updateTemplateStatus/${id}`,
			data,
			"PUT"
		);
	};

	const handleDeleteClick = (e: any, id: number) => {
		e.stopPropagation();
		if (window.confirm("Are you sure you want to delete this template?")) {
			useDeleteTemplate.doFetch(
				`/brand/data/deleteLabelTemplate/${id}`,
				{},
				"DELETE"
			);
		}
	};

	useEffect(() => {
		if (listItem.current) {
			observer.current.observe(listItem.current);
		}

		let el = listItem.current;
		let obs = observer.current;
		return () => {
			obs.unobserve(el);
		};
	}, [listItem, observer]);

	useEffect(() => {
		const { error } = useUpdateTemplateStatus;
		if (error) {
			toast.error(`Unable to update template status. ${error}`);
		}
	}, [useUpdateTemplateStatus.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useUpdateTemplateStatus;

		if (data.status === "ok") {
			if (data.message === TemplateStatuses.ACTIVE.code) {
				toast.success("Template unarchived.");
			} else if (data.message === TemplateStatuses.ARCHIVED.code) {
				toast.success("Template archived.");
			}
			refreshTemplates(true, false, 0);
			setOffset(0);
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.LABEL_TEMPLATES, account_type_id],
			});
		}
	}, [useUpdateTemplateStatus.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useDeleteTemplate;
		if (error) {
			toast.error(`Unable to delete template. ${error}`);
		}
	}, [useDeleteTemplate.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useDeleteTemplate;
		if (data.status === "ok") {
			toast.success("Template deleted.");
			refreshTemplates(true, false, 0);
			setOffset(0);
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.LABEL_TEMPLATES, account_type_id],
			});
		}
	}, [useDeleteTemplate.data]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div
				style={{
					borderBottom: "2px solid #f4f4f4",
					fontSize: "14px",
				}}
				ref={refVar}
			>
				<article
					className={`list-item list-item--templates`}
					style={{ height: "74px" }}
					ref={listItem}
				>
					<Link
						to={`/labelTemplate/${id}`}
						className="list-item__details flex flex-center-secondary-axis"
						style={{ paddingRight: "30px", height: "100%" }}
						data-iscapture="true"
					>
						{name ?? ""}
					</Link>

					<Link
						to={`/labelTemplate/${id}`}
						className="list-item__details"
						data-iscapture="true"
						style={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						{(last_edit_by ?? "") + "\n"}
						<div className="small">
							{last_edit_on ? fullTimeFormat(last_edit_on) : last_edit_on}
						</div>
					</Link>

					<Link
						to={`/labelTemplate/${id}`}
						className="list-item__details"
						data-iscapture="true"
						style={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						{(reference_number ?? "") + "\n"}
						<div className="small">
							{last_used_on ? fullTimeFormat(last_used_on) : last_used_on}
						</div>
					</Link>

					<div
						onClick={() => {
							navigate(`/labelTemplate/${id}`);
						}}
						className={"list-item__details flex flex-center-secondary-axis"}
						style={{ height: "100%" }}
						data-tooltip-content={
							"No order has been created with this template."
						}
						data-tooltip-id={`status-${name}`}
						data-iscapture="true"
						onMouseOver={() =>
							status === TemplateStatuses.INACTIVE.code &&
							setTooltipFor(`status-${name}`)
						}
						onMouseLeave={() => setTooltipFor(undefined)}
					>
						<div style={{ display: "flex" }}>
							{status === TemplateStatuses.ACTIVE.code && (
								<span className="statusQuiet statusCompleted">
									{TemplateStatuses.ACTIVE.name}
								</span>
							)}

							{status === TemplateStatuses.INACTIVE.code && (
								<div className="flex flex-center-both-axis">
									<span className="statusQuiet">
										{TemplateStatuses.INACTIVE.name}
									</span>
								</div>
							)}

							{status === TemplateStatuses.ARCHIVED.code && (
								<div className="flex-center-secondary-axis">
									<span className="statusQuiet voidOrder">
										{TemplateStatuses.ARCHIVED.name}
									</span>
								</div>
							)}
						</div>
					</div>

					<div
						onClick={() => {
							navigate(`/labelTemplate/${id}`);
						}}
						data-event="none"
						data-iscapture="true"
						className="list-item__details ordersStatusListItem"
						style={{ height: "100%", paddingRight: "30px" }}
					>
						{status === TemplateStatuses.ARCHIVED.code && (
							<div
								className="flex-center-secondary-axis"
								style={{
									cursor: "pointer",
									marginRight: "11px",
									marginTop: "25px",
									marginBottom: "25px",
								}}
								onClick={(e) => {
									e.stopPropagation();
									handleUnarchiveClick(e, id);
								}}
							>
								<Icon
									name="unarchive"
									className="unarchive-icon actionTemplateIcon"
								/>
							</div>
						)}

						{status === TemplateStatuses.ACTIVE.code && (
							<div
								className="flex-center-secondary-axis"
								style={{
									cursor: "pointer",
									marginRight: "11px",
									marginTop: "25px",
									marginBottom: "25px",
								}}
								onClick={(e) => {
									e.stopPropagation();
									handleArchiveClick(e, id);
								}}
							>
								<Icon
									name="archive"
									className="archive-icon actionTemplateIcon"
								/>
							</div>
						)}

						{status === TemplateStatuses.INACTIVE.code && (
							<div
								className="flex-center-secondary-axis"
								style={{
									cursor: "pointer",
									marginRight: "11px",
									marginTop: "25px",
									marginBottom: "25px",
								}}
								onClick={(e) => {
									e.stopPropagation();
									handleDeleteClick(e, id);
								}}
							>
								<Icon
									name="delete"
									className="delete-icon actionTemplateIcon"
								/>
							</div>
						)}
					</div>
				</article>
			</div>

			<Tooltip
				className="reactTooltip whiteBackground"
				variant="light"
				id={tooltipFor}
				clickable={true}
				float
				isOpen={tooltipFor ? true : false}
			></Tooltip>
		</>
	);
};

export default TemplatesListItem;
