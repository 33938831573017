import { FC, useContext, useEffect } from "react";
import useDataApi from "Hooks/fetchHook";
import { toast } from "react-toastify";

import ReleaseDec23 from "Components/Help/HelpWhatIsNew/Dec2023/Dec23";
import AppDispatch from "Dispatches/AppDispatch";

const WhatIsNewModal: FC<{}> = () => {
	const { setUser } = useContext(AppDispatch);
	const useReadWhatIsNew = useDataApi();

	/* *************************************************************************** */

	useEffect(() => {
		const { data } = useReadWhatIsNew;
		if (data.status === "ok") {
			setUser((prevUser) => {
				if (prevUser) {
					return { ...prevUser, whats_new_seen: true };
				} else return undefined;
			});
		}
	}, [useReadWhatIsNew.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useReadWhatIsNew;
		if (error) {
			// @Milan advice
			toast.error("Unable to confirm reading new releases.");
		}
	}, [useReadWhatIsNew.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *************************************************************************** */

	const onSeenWhatIsNew = () => {
		useReadWhatIsNew.doFetch("/users/whatsNewSeen", {}, "POST");
	};

	return (
		<>
			<div className="modal flex-center-both-axis">
				<div className="modal-content-order-summary show">
					<div className="mt--base">
						<ReleaseDec23 />
					</div>
					<div className="mt--base mb--base flex-center-primary-axis">
						<button
							className={"button button--primary"}
							type="button"
							form="orderItemForm"
							onClick={() => onSeenWhatIsNew()}
						>
							Ok
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default WhatIsNewModal;
