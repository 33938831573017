import React, { useContext } from "react";

import OrderDispatch from "Dispatches/OrderDispatch";
import { IDefaultOption } from "Models/OrderModels";

import IncrementalSearchComponent from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchComponent";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import {
	checkMissingTranslationByName,
	isDpp,
	languagesForTranslationsCheck,
} from "Utils/utils";

interface IProps {
	careInstructionsList: IDefaultOption[];
}

const CareInstructions: React.FunctionComponent<IProps> = ({
	careInstructionsList,
}) => {
	const {
		orderItem,
		setOrderItem,
		order: { labelTemplate },
	} = useContext(OrderDispatch);
	const { setMissingTranslations } = useContext(GlobalDispatch);

	const { qr_phase } = labelTemplate?.settings?.default_values;

	const {
		translations,
		selectedCareInstructions,
		allLabelLanguages,
		chosenLabelLanguages,
	} = orderItem;

	const onChooseItemCallback = (item: any) => {
		if (item) {
			let filteredListItems = selectedCareInstructions || [];
			if (!selectedCareInstructions.includes(item.id)) {
				filteredListItems = [...selectedCareInstructions, item.id];
				setOrderItem({ selectedCareInstructions: filteredListItems });
				let languagesArrayForTranslationsCheck = languagesForTranslationsCheck(
					allLabelLanguages,
					chosenLabelLanguages,
					isDpp(qr_phase)
				);
				if (languagesArrayForTranslationsCheck.length > 0) {
					let missingCareInstructionTranslations =
						checkMissingTranslationByName(
							careInstructionsList,
							[item.name],
							languagesArrayForTranslationsCheck
						);
					if (Object.keys(missingCareInstructionTranslations).length > 0) {
						setMissingTranslations({
							missingCareInstructions: missingCareInstructionTranslations,
						});
					}
				}
			}
		}
	};

	const onChangeOrderOfSelectedItems = (ids: any) => {
		setOrderItem({
			selectedCareInstructions: ids.map((id: string) => Number(id)),
		});
	};

	const onRemoveItem = (itemId: number) => {
		const filteredItems = selectedCareInstructions.filter(
			(chosenItemId: number) => chosenItemId !== itemId
		);
		if (translations.careInstructionsTranslations) {
			delete translations.careInstructionsTranslations[itemId];
		}
		setOrderItem({
			selectedCareInstructions: filteredItems,
			translations: {
				...translations,
				careInstructionsTranslations: translations.careInstructionsTranslations,
			},
		});
	};

	return (
		<IncrementalSearchComponent
			list={careInstructionsList}
			buttonName="Care Instructions"
			selectedItemsValues={selectedCareInstructions}
			fieldName="selectedCareInstructions"
			onChooseItemCallbackCustom={onChooseItemCallback}
			onChangeOrderOfSelectedItems={onChangeOrderOfSelectedItems}
			onRemoveItem={onRemoveItem}
		/>
	);
};

export default CareInstructions;
