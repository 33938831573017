import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { toast } from "react-toastify";

import useDataApi from "Hooks/fetchHook";

import Loading from "Components/Shared/Loading";

import { EndpointPrefix } from "Models/UserModels";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import PrintBatchComponent from "Components/Shared/PrintBatchComponent";
import Icon from "Components/Shared/Icon";

const OrderPreview: React.FunctionComponent = () => {
	const { printBatchId: printBatchIdFromParams } = useParams() as any;

	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const navigate = useNavigate();

	const useGetPrinters = useDataApi();
	const useGetPrintBatch = useDataApi();

	const [globalLoadingGetPrintBatches, setGlobalLoadingGetPrintBatches] =
		useState(true);

	const [preparingForPrint, setPreparingForPrint] = useState(false);

	const [printBatch, setPrintBatch] = useState<any>(undefined);
	const [currentLabelTemplate, setCurrentLabelTemplate] = useState<
		undefined | { id: number; settings: any }
	>(undefined);
	const [printers, setPrinters] = useState([]);

	useEffect(() => {
		fetchPrinters();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** Get Printers START *********************************************** */

	useEffect(() => {
		const { data } = useGetPrinters;
		if (data && data.message) {
			setPrinters(data.message.printers);
			fetchPrintBatch(printBatchIdFromParams);
		}
	}, [useGetPrinters.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrinters;
		if (error) {
			toast.error("Unable to get Printers list.");
		}
	}, [useGetPrinters.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** Get Printers END *********************************************** */

	/* *********************************** Get Print Batch START *********************************************** */

	useEffect(() => {
		const { data } = useGetPrintBatch;
		if (data && data.message) {
			setPrintBatch(data.message);

			setGlobalLoadingGetPrintBatches(false);

			let { template_id, template_settings } = data.message;

			let currentLabelTemplate = {
				id: template_id,
				settings: template_settings,
			};

			setCurrentLabelTemplate(currentLabelTemplate);
		}
	}, [useGetPrintBatch.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrintBatch;
		if (error) {
			toast.error("Unable to get Print batch info.");
		}
	}, [useGetPrintBatch.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** Get Print Batch END *********************************************** */

	/* *********************************** Functions START   *********************************************** */

	const fetchPrinters = () => {
		useGetPrinters.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printersList`
		);
	};

	const fetchPrintBatch = (printBatchId: number) => {
		useGetPrintBatch.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printBatch/${printBatchId}`
		);
	};

	/* *********************************** Functions END   *********************************************** */

	return (
		<div className="orderPreviewContainer">
			<Loading
				show={
					globalLoadingGetPrintBatches || !printBatch || !currentLabelTemplate
				}
				text={`Loading...`}
				imgClass="block-center"
				divClass=""
			/>
			{!globalLoadingGetPrintBatches && printBatch && currentLabelTemplate && (
				<div className="orderPreviewContent">
					<div className="orderPreviewContentHeader">
						<div className="pos-relative">
							<div className="flex">
								<div className="mt--xs">
									<button
										className="link--back btn-no-style pos-relative mt--xs"
										style={{ top: 0, whiteSpace: "nowrap" }}
										// onClick={() => {
										// 	if (fromOrders.current) {
										// 		navigate(-1);
										// 		fromOrders.current = false;
										// 	} else {
										// 		navigate("/orders");
										// 	}
										// }}
										// TODO should we implement fromPrintBatch, same in Orders - fromOrders, to keep filters
										onClick={() => {
											navigate("/printBatches");
										}}
									>
										<Icon name="arrow-left-rounded" />
										Back to print batches
									</button>
								</div>
								<h1
									className="mt--xs flex-1 truncate"
									style={{ paddingLeft: "25px" }}
								>
									Print batch
								</h1>
							</div>
						</div>
					</div>
					<div className="orderPreviewInfo" style={{ marginTop: "110px" }}>
						<div
							className="pretitle flex-center-secondary-axis"
							style={{
								justifyContent: "space-between",
								height: "18px",
							}}
						>
							<span className="line-height-1">PRINTING DETAILS</span>
							{preparingForPrint && (
								<span className="animated-gradient-generating-layouts">
									Preparing for printing
								</span>
							)}
						</div>

						<PrintBatchComponent
							labelTemplate={currentLabelTemplate}
							printBatch={printBatch}
							isLoadingPrintBatch={useGetPrintBatch.isLoading}
							printers={printers}
							isLoadingFetchPrinters={useGetPrinters.isLoading}
							fetchPrinters={fetchPrinters}
							fetchPrintBatch={fetchPrintBatch}
							setPrintBatch={setPrintBatch}
							preparingForPrint={preparingForPrint}
							setPreparingForPrint={setPreparingForPrint}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default OrderPreview;
