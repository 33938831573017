import { FC, useContext, useEffect, useLayoutEffect } from "react";
import { Link, Routes, Route, useLocation } from "react-router-dom-v5-compat";

import GlobalDispatch from "Dispatches/GlobalDispatch";

import APIIntegrationHelp from "HelpArticles/APIIntegrationHelp";
import AddExistingStyleHelp from "HelpArticles/AddExistingStyleHelp";
import AdditionalInformationHelp from "HelpArticles/AdditionalInformationHelp";
import ContentCompositionHelp from "HelpArticles/ContentCompositionHelp";
import FactoriesIntroHelp from "HelpArticles/FactoriesIntroHelp";
import ImportStylesHelp from "HelpArticles/ImportStylesHelp";
import LabelSettingsHelp from "HelpArticles/LabelSettingsHelp";
import MissingTranslationsHelp from "HelpArticles/MissingTranslationsHelp";
import OrderActionsHelp from "HelpArticles/OrderActionsHelp";
import OrderApprovalHelp from "HelpArticles/OrderApprovalHelp";
import OrdersPageHelp from "HelpArticles/OrdersPageHelp";
import PlacingTheOrderHelp from "HelpArticles/PlacingTheOrderHelp";
import ReorderingHelp from "HelpArticles/ReorderingHelp";
import Icon from "Components/Shared/Icon";

import { isFactory } from "Models/UserModels";

const HelpArticles: FC = () => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const location = useLocation();

	useLayoutEffect(() => {
		if (location.pathname === "/help/articles") {
			document.title = `Cacotec Help Articles`;
		}
	}, [location.pathname]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	let articlesLinks = (
		<>
			<h2 className="helpTitle">Help articles</h2>
			<h2 className="helpSubtitle">General</h2>
			<p>
				<Link className="link" to="ordersPage">
					Orders page
				</Link>
			</p>
			<p>
				<Link className="link" to="apiIntegration">
					API integration
				</Link>
			</p>
			<h2 className="helpSubtitle">Order</h2>
			<p>
				<Link className="link" to="orderActions">
					Order actions
				</Link>
			</p>
			<p>
				<Link className="link" to="orderApproval">
					Order approval
				</Link>
			</p>
			<p>
				<Link className="link" to="contentComposition">
					Content composition
				</Link>
			</p>
			<p>
				<Link className="link" to="additionalInformation">
					Additional information
				</Link>
			</p>
			<p>
				<Link className="link" to="addingExistingStyle">
					Adding existing style to an order
				</Link>
			</p>
			<p>
				<Link className="link" to="labelSettings">
					Label Settings
				</Link>
			</p>
			<p>
				<Link className="link" to="missingTranslations">
					Missing translations
				</Link>
			</p>
			<p>
				<Link className="link" to="placingTheOrder">
					Placing the order
				</Link>
			</p>
			<p>
				<Link className="link" to="reordering">
					Reordering
				</Link>
			</p>
			<h2 className="helpSubtitle">Styles</h2>
			<p>
				<Link className="link" to="importStyles">
					Import styles
				</Link>
			</p>
			{isFactory(account_type_id) && (
				<>
					<h2 className="helpSubtitle">Factories</h2>
					<p>
						<Link className="link" to="factoriesIntro">
							Basic info
						</Link>
					</p>
				</>
			)}
		</>
	);

	return (
		<div>
			{location.pathname !== "/help/articles" && (
				<Link className="link--back" to="/help/articles">
					<Icon name="arrow-left-rounded" />
					Back
				</Link>
			)}

			<div style={{ paddingLeft: "60px" }}>
				<Routes>
					<Route index element={articlesLinks} />
				</Routes>
			</div>

			<div
				className="helpFullSizeMainContent mb--lg"
				style={{ margin: "auto" }}
			>
				<Routes>
					<Route path="labelSettings" element={<LabelSettingsHelp />} />
					<Route
						path="addingExistingStyle"
						element={<AddExistingStyleHelp />}
					/>
					<Route
						path="contentComposition"
						element={<ContentCompositionHelp />}
					/>
					<Route
						path="additionalInformation"
						element={<AdditionalInformationHelp />}
					/>
					<Route
						path="missingTranslations"
						element={<MissingTranslationsHelp />}
					/>
					<Route path="placingTheOrder" element={<PlacingTheOrderHelp />} />
					<Route path="reordering" element={<ReorderingHelp />} />
					<Route path="ordersPage" element={<OrdersPageHelp />} />
					<Route path="importStyles" element={<ImportStylesHelp />} />
					<Route path="orderActions" element={<OrderActionsHelp />} />
					<Route path="orderApproval" element={<OrderApprovalHelp />} />
					<Route path="apiIntegration" element={<APIIntegrationHelp />} />
					{isFactory(account_type_id) && (
						<Route path="factoriesIntro" element={<FactoriesIntroHelp />} />
					)}
					<Route path="orderApproval" element={<OrderApprovalHelp />} />
				</Routes>
			</div>
		</div>
	);
};

export default HelpArticles;
