import { useState, useRef, useEffect, useContext, FC } from "react";
import LabelTemplateDispatch from "Dispatches/LabelTemplateDispatch";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import Icon from "Components/Shared/Icon";
import { ITemplateState } from "Models/TemplateModels";

function useOutsideAlerter(ref: any, setShowSelection: any) {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowSelection(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]); // eslint-disable-line react-hooks/exhaustive-deps
}
const LanguagePicker: FC<{ languages: any }> = ({ languages }) => {
	const { template, setTemplate, showMissingData, checkGeneralLabelSettings } =
		useContext(LabelTemplateDispatch);

	const [showSections, setShowSections] = useState(false);

	const [languagesForDropdown, setLanguagesForDropdown] = useState(languages);

	const isDataMissing = (itemToCheck: string) => {
		return showMissingData && checkGeneralLabelSettings().includes(itemToCheck);
	};

	const listRef = useRef(null);
	useOutsideAlerter(listRef, setShowSections);

	const changeOrderOfLanguages = (filteredLanguages: any) => {
		setTemplate((prevTemplate: ITemplateState) => ({
			...prevTemplate,
			languages: filteredLanguages,
		}));
	};

	const removeLanguage = (lang: any) => {
		let currentLanguagesList = [...template.languages];
		let newLanguagesList = currentLanguagesList.filter((l: any) => lang !== l);

		setTemplate((prevTemplate: ITemplateState) => ({
			...prevTemplate,
			languages: newLanguagesList,
		}));
	};

	const handleOnDragEnd = (result: any) => {
		if (!result.destination) return;

		const items = Array.from(template.languages);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		enableScroll();
		changeOrderOfLanguages(items);
	};

	const handleOnDragStart = () => {
		disableScroll();
	};

	const disableScroll = () => {
		// Get the current page scroll position
		let scrollTop = window.scrollY || document.documentElement.scrollTop;
		let scrollLeft = window.scrollX || document.documentElement.scrollLeft;
		// if any scroll is attempted,
		// set this to the previous value
		window.onscroll = () => {
			window.scrollTo(scrollLeft, scrollTop);
		};
	};

	const enableScroll = () => {
		window.onscroll = function () {};
	};

	useEffect(() => {
		let newLanguagesForDropdown = languages.filter(
			(lang: any) => !template.languages.includes(lang.id)
		);

		setLanguagesForDropdown(newLanguagesForDropdown);
	}, [template.languages.length]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div style={{ padding: "0px 20px 20px", height: "130px" }}>
			{/* <div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>Languages selection</span>
				<HelpTooltip helpFor="LanguagesSelection" />
			</div> */}
			<fieldset className="box--light width-100">
				<div className="mb--xs">
					<div
						role="button"
						className={`flex-center-secondary-axis add-languages-button  ${
							isDataMissing("Languages") && "add-languages-button-no-languages"
						}`}
						onClick={() => setShowSections(!showSections)}
					>
						<Icon
							name="plus-rounded"
							className={`add-language-icon mr--xs ${
								isDataMissing("Languages") && "add-language-icon-no-languages"
							}`}
						/>
						<div
							style={{ height: "16px", paddingTop: "2px" }}
							className="flex-center-secondary-axis"
						>
							<div>Add language</div>
						</div>
					</div>
				</div>
				{showSections && (
					<div
						className="form-control"
						id="dropdownList"
						style={{ width: "300px", left: "auto", right: "auto" }}
					>
						<ul
							className="dropdown-menu show width-100"
							style={{ maxHeight: "250px" }}
							ref={listRef}
						>
							{languagesForDropdown.map((lang: any) => {
								return (
									<li
										key={lang.id}
										value={lang.id}
										className="dropdown-menu__item"
										role="button"
										onClick={() => {
											setTemplate((prevTemplate: ITemplateState) => ({
												...prevTemplate,
												languages: [...prevTemplate.languages, lang.id],
											}));
											setShowSections(false);
										}}
									>
										{lang.name}
									</li>
								);
							})}
						</ul>
					</div>
				)}
				<DragDropContext
					onDragEnd={handleOnDragEnd}
					onDragStart={handleOnDragStart}
				>
					<Droppable droppableId="selectedItems" direction="horizontal">
						{(provided) => (
							<div
								className="language-picker-droppable"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{template?.languages?.map((itemId: number, index: number) => {
									const item = languages.find(
										(item: any) => item.id === itemId
									);
									let itemName = undefined as any;
									if (item) {
										itemName = item.name;
									}
									return (
										<Draggable
											key={itemId}
											draggableId={`${itemId}`}
											index={index}
										>
											{(provided) => (
												<div
													id={`${index}`}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className="language-item mr--xs flex flex-center-secondary-axis"
													style={{
														cursor: "move",
														...provided.draggableProps.style,
													}}
												>
													<div
														style={{
															width: "24px",
															height: "10px",
														}}
													></div>
													<span>{itemName}</span>

													<button
														className="language-item-remove btn-no-style"
														style={{ position: "relative" }}
														onClick={() => removeLanguage(itemId)}
													>
														<Icon name="cross-rounded-filled" />
													</button>
												</div>
											)}
										</Draggable>
									);
								})}

								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</fieldset>
		</div>
	);
};

export default LanguagePicker;
