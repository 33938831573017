import React, { useContext } from "react";
import { Tooltip } from "react-tooltip";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import sprite from "images/icons.svg";

interface IProps {
	helpFor: string;
}

const HelpTooltip: React.FunctionComponent<IProps> = ({ helpFor }) => {
	const { setCurrentHelpTopic } = useContext(GlobalDispatch);

	let content = null;
	let helpTopic: any = null;

	switch (helpFor) {
		case "OrderDetails":
			content = (
				<>
					<p>Global settings for this order</p>
					<b>Label layout: </b>Chosen label layout
					<br />
					<b>Reference number: </b>The number you are using to track your orders
					<br />
					<b>Factory: </b>Choose a factory that will handle this order, if
					applicable
					<br />
					<b>Note: </b>Add notes about the orders
					<br />
				</>
			);
			break;
		case "BasicStyleInformation":
			content = (
				<>
					<p>Basic information about the current style</p>
					<b>Style number: </b>The number you are using to track your orders
					<br />
					<b>Style description: </b>Organize orders by the stage they are in, if
					applicable
					<br />
					<b>Made in: </b>Choose a factory that will handle this order, if
					applicable
					<br />
				</>
			);
			break;
		case "UnitsInformation":
			content = (
				<>
					<p>Units information about the current style</p>
					<b>Quantity: </b>Type in the quantity
					<br />
					<b>Size : </b>Type in or select the size, if applicable
					<br />
					<b>Black Ground Label : </b>Check this option if you need a black
					ground label, if applicable
					<br />
					<b>Add Another : </b>Click to add more units, if applicable
					<br />
					<b>Remove : </b>Click to remove units, if applicable
					<br />
				</>
			);
			break;
		case "LogIn":
			content = (
				<>
					If you don't have credentials, please contact your sales
					representative.
				</>
			);
			break;
		case "StylesInThisOrder":
			content = (
				<>
					<b>Add New: </b>Add a new style that you haven't used before
					<br />
					<b>Add Existing: </b>Add a style that was previously imported
					<br />
					<br />
					Once you add styles to the order, they will show up below
					<br />
				</>
			);
			helpTopic = "addingExistingStyle";
			break;
		case "ContentComposition":
			content = (
				<>
					<b>Content Composition: </b>Click to choose from a list of existing
					content compositions
					<br />
					<b>Edit: </b>Click to edit the chosen content composition
					<br />
					<b>Create New Composition: </b>Click to create a new content
					composition
					<br />
					<b>Add Additional Components : </b>Click to add additional components,
					if applicable
					<br />
					<b>Add Care Instructions: </b>Click to add care instructions
					<br />
				</>
			);
			helpTopic = "contentComposition";
			break;
		case "AdditionalInformation":
			content = (
				<>
					Use this option to add additional information required on your label.
					<br />
					Choose the options you want from the dropdown menu.
					<br />
				</>
			);
			break;
		case "LabelSettings":
			content = (
				<>
					<b>Include QR code: </b>Check this option if you want to include a QR
					code on your label
					<br />
				</>
			);
			helpTopic = "labelSettings";
			break;
		case "SuggestedCareInstruction":
			content = (
				<>
					These are the suggested care instructions for the content composition
					you entered. Click <i>Use this one</i>
					next to the group of care instructions you want to use or click{" "}
					<i>I will enter care instructions manually</i>
					if you want to choose by hand.
				</>
			);
			break;
		case "ImportStyles":
			content = (
				<>
					<li>You have to use the Cacotec format for importing styles.</li>
					<li>
						Styles from the file will be imported only if information for{" "}
						<b>all</b> styles are valid.
					</li>
					<li>
						If you upload a style which is already in the database, you will get
						a confirmation window asking if
						<br />
						you want to overwrite the existing styles. You can cancel, edit the
						file and try again or choose ok to continue.
					</li>
				</>
			);
			helpTopic = "importStyles";
			break;
		case "PrintQuantity":
			content = (
				<>
					Print quantity might be higher than the quantity you entered,
					<br /> if there is an overflow and a new page needs to be created.
				</>
			);
			break;
		case "LabelDimensions":
			content = (
				<>
					<p>Label dimensions</p>
					<b>Label width : </b>Type in the label width in millimeters
					<br />
					<b>Fixed label height : </b>Check this option if you want to manually
					enter label height
					<br />
					<b>Label height : </b>Type in label height in millimeters
					<br />
				</>
			);
			break;
		case "LabelMargins":
			content = (
				<>
					<p>Label margins</p>
					<b>Margin top : </b>Type in the top margin in millimeters
					<br />
					<b>Margin left : </b>Type in the left margin in millimeters
					<br />
					<b>Margin right : </b>Type in the right margin in millimeters
					<br />
					<b>Margin bottom : </b>Type in the bottom margin in millimeters
					<br />
				</>
			);
			break;
		case "FontSettings":
			content = (
				<>
					<p>Font settings</p>
					<b>Font : </b>Pick the font for your label
					<br />
					<b>Font size : </b>Type in the font size in points
					<br />
					<b>Line space : </b>Type in the desired space between the lines in
					points
					<br />
					<b>Black ground label : </b>Check this option if you need a black
					ground label
					<br />
				</>
			);
			break;
		case "FormSettings":
			content = (
				<>
					<p>Form settings</p>
					<b>WRITE SOMETHING HERE</b>
					{/* TODO - FIll this help pop up */}
				</>
			);
			break;
		case "LanguagesSelection":
			content = (
				<>
					<p>Languages Selection</p>
					<b>Add language : </b>Click to add new language.
					<br />
				</>
			);
			break;
		case "NameSection":
			content = (
				<>
					<p>Name Selection</p>
					<b>Name : </b>Type in the name for the template.
					<br />
				</>
			);
			break;
		case "SectionSelection":
			content = (
				<>
					<p>Section selection</p>
					<b>Add section : </b>Click to add section
					<br />
				</>
			);
			break;
		case "MADE IN SECTION":
			content = (
				<>
					<p>Made in</p>
					<b>Capitalization : </b>Pick the capitalization for your made in
					section
					<br />
					<b>Alignment : </b>Pick the alignment for your made in section
					<br />
				</>
			);
			break;
		case "CONTENT COMPOSITION SECTION":
			content = (
				<>
					<p>Content composition</p>
					<b>Capitalization : </b>Pick the capitalization for your content
					composition section
					<br />
					<b>Alignment : </b>Pick the alignment for your content composition
					section
					<br />
					<b>Add new line between translations : </b>Check if you want to
					separate content compostion translations by new line
					<br />
					<b>Split character : </b>Pick the split character between 2 languages
					for your content composition section
					<br />
				</>
			);
			break;
		case "CARE INSTRUCTIONS SECTION":
			content = (
				<>
					<p>Care instructions</p>
					<b>Capitalization : </b>Pick the capitalization for your content
					composition section
					<br />
					<b>Alignment : </b>Pick the alignment for your content composition
					section
					<br />
					<b>Split character : </b>Pick the split character between 2 languages
					for your content composition section
					<br />
					<b>Include care instruction symbols : </b>Check this option if you
					wanted care instruction symbols printed on label.
					<br />
				</>
			);
			break;
		case "SEPARATOR SECTION":
			content = (
				<>
					<p>Separator</p>
					<b>Height : </b>Type in the separator height in millimeters.
					<br />
				</>
			);
			break;
		case "IMAGE SECTION":
			content = (
				<>
					<p>Image</p>
					<b>Image : </b>Drop or upload the image.
					<br />
				</>
			);
			break;
		case "FIXED INFO SECTION":
			content = (
				<>
					<p>Fixed info</p>
					<b>Text : </b>Type in the text for fixed info.
					<br />
				</>
			);
			break;
		case "LINE SECTION":
			content = (
				<>
					<p>Line</p>
					<b>Style : </b>Pick the style for the line.
					<br />
				</>
			);
			break;
		case "PrintSummaryModal":
			content = (
				<>
					<p>
						Here you can see how your order is split for printing, <br />
						based on material width and color.
						<br /> You can expand each line to see the layout images.
					</p>
				</>
			);
			break;
	}

	return (
		<>
			{content && (
				<div
					className="flex-center-both-axis"
					style={{ textTransform: "uppercase" }}
				>
					<svg
						className="icon"
						data-event="click"
						data-tooltip-id={helpFor}
						style={{
							marginLeft: "5px",
							opacity: "0.5",
							width: "12px",
						}}
					>
						<use xlinkHref={`${sprite}#icon-info-rounded`} />
					</svg>
				</div>
			)}
			<Tooltip
				className="reactTooltip box--shadowed fixed"
				id={helpFor}
				aria-haspopup="true"
				clickable={true}
				openOnClick
				style={{ width: "auto" }}
			>
				{content}
				{helpTopic && (
					<>
						<br />
						<button
							className="btn-no-style btn-link text-uppercase"
							onClick={() => {
								setCurrentHelpTopic(helpTopic);
							}}
						>
							read more...
						</button>
					</>
				)}
			</Tooltip>
		</>
	);
};

export default HelpTooltip;
