import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import useDataApi from "Hooks/fetchHook";
import HelpTooltip from "Components/Shared/HelpTooltip";
import Loading from "Components/Shared/Loading";
import OrderDispatch from "Dispatches/OrderDispatch";

const LabelSettings: React.FunctionComponent = () => {
	const { orderItem, setOrderItem } = useContext(OrderDispatch) as any;

	const useGetQRCode = useDataApi();
	const { includeQRCode, qrCodeId } = orderItem;

	useEffect(() => {
		if (!qrCodeId && includeQRCode) {
			useGetQRCode.doFetch("/qr/id");
		}
	}, [includeQRCode, orderItem]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetQRCode;
		if (error) {
			setOrderItem({ includeQRCode: false });
			toast.error("Unable to create QR code at the moment.");
		}
	}, [useGetQRCode.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetQRCode;
		if (data.message) {
			setOrderItem({ qrCodeId: data.message.id });
		}
	}, [useGetQRCode.data]); // eslint-disable-line react-hooks/exhaustive-deps

	const toggleIncludeQRCode = () => {
		setOrderItem({ includeQRCode: !includeQRCode });
	};

	const { isLoading } = useGetQRCode;

	return (
		<>
			<div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>Label settings</span>
				<HelpTooltip helpFor="LabelSettings" />
			</div>
			<fieldset className="box box--light box--shadowed mb--md">
				<div className="form-check">
					<Loading
						show={isLoading}
						text={`Loading...`}
						imgClass="saveOrderLoading"
						divClass=""
					/>
					{!isLoading && (
						<React.Fragment>
							<input
								className="form-check__input"
								type="checkbox"
								checked={includeQRCode ? true : false}
								name="includeQRCode"
								id="includeQRCode"
								onChange={(e) => toggleIncludeQRCode()}
							/>
							<label className="form-check__label" htmlFor="includeQRCode">
								Include QR code
							</label>
						</React.Fragment>
					)}
				</div>
			</fieldset>
		</>
	);
};

export default LabelSettings;
