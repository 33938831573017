import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";

import useOnClickOutside from "Hooks/outsideClickHook";

import Icon from "Components/Shared/Icon";
import useDataApi from "Hooks/fetchHook";
import { languagesCodeFullname } from "Models/GlobalModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import { toast } from "react-toastify";
import Loading from "Components/Shared/Loading";

interface IProps {
	editParams: any;
	currentDataType: string;

	setShowAdminTranslationsModal: (show: boolean) => void;
	onSearchData: () => void;
}

const AdminTranslationsModal: React.FunctionComponent<IProps> = ({
	editParams,
	currentDataType,

	setShowAdminTranslationsModal,
	onSearchData,
}) => {
	const { user } = useContext(GlobalDispatch);

	const { id, name, account_type_id } = user;
	const { currentDataId, currentLanguageCode, currentTranslation, dataName } =
		editParams;

	const [newTranslation, setNewTranslation] = useState({
		translation: currentTranslation,
		translation_source: "",
		currentLanguageCode,
	});

	const modalRef = useRef(null) as any;

	const useSaveTranslation = useDataApi();
	const useAddTranslation = useDataApi();

	/* *************************************************************************** */

	useEffect(() => {
		const { data } = useSaveTranslation;

		if (data.status === "ok") {
			toast.success("Successfully saved translations.");
			setShowAdminTranslationsModal(false);
			onSearchData();
		}
	}, [useSaveTranslation.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveTranslation;
		if (error) {
			toast.error(`Unable to save translations. ${error}`);
		}
	}, [useSaveTranslation.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */

	useEffect(() => {
		const { data } = useAddTranslation;

		if (data.status === "ok") {
			toast.success("Successfully added translations.");
			setShowAdminTranslationsModal(false);
			onSearchData();
		}
	}, [useAddTranslation.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useAddTranslation;
		if (error) {
			toast.error(`Unable to add translations. ${error}`);
		}
	}, [useAddTranslation.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************************************************** */
	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCancel();
		}
	};

	const onCancel = () => {
		let isDirty =
			currentTranslation !== newTranslation.translation ||
			newTranslation.translation_source.length > 0;

		if (isDirty) {
			if (
				window.confirm(
					"You have unsaved translations, are you sure you want to leave?"
				)
			) {
				setShowAdminTranslationsModal(false);
			}
		} else {
			setShowAdminTranslationsModal(false);
		}
	};

	const onSave = () => {
		if (!newTranslation.translation_source) {
			alert("Please enter translation source");
			return;
		}
		useSaveTranslation.doFetch(
			`/maker/admin/translation`,
			{
				data_id: currentDataId,
				data_type: currentDataType,
				...newTranslation,
				added_by: `${name}(${id}), ${account_type_id}`,
			},
			"PUT"
		);
	};

	const onAdd = () => {
		if (!newTranslation.currentLanguageCode) {
			alert("Please select language");
			return;
		} else if (!newTranslation.translation) {
			alert("Please enter translation");
			return;
		} else if (!newTranslation.translation_source) {
			alert("Please enter translation source");
			return;
		}

		useAddTranslation.doFetch(
			`/maker/admin/translations`,
			{
				data_type: currentDataType,
				data_name: dataName,
				translations: {
					[newTranslation.currentLanguageCode]: newTranslation.translation,
				},
				translation_source: newTranslation.translation_source,
			},
			"POST"
		);
	};

	useOnClickOutside(
		modalRef,
		useCallback(onCancel, [setShowAdminTranslationsModal]) // eslint-disable-line react-hooks/exhaustive-deps
	);

	const onInputchange = (e: any) => {
		setNewTranslation({ ...newTranslation, [e.target.name]: e.target.value });
	};

	return (
		<div className="modal" onKeyDown={onKeyDown}>
			<div
				className="modal-content block-center show"
				ref={modalRef}
				id="adminTranslationsModal"
				tabIndex={1}
			>
				<button
					className="drawer__close btn-no-style"
					data-dismiss="drawer"
					aria-label="Close"
					onClick={onCancel}
				>
					<Icon name="cross-rounded" />
				</button>

				<p className="text--md mt--lg">
					{currentDataId ? "Updating" : "Adding new"} translations for{" "}
					{dataName} :
				</p>
				<div className="mb--base">
					<div className="mt--base flex">
						{currentDataId ? (
							<span
								className="flex"
								style={{ alignItems: "center", width: "150px" }}
							>
								<span className="flex" style={{ flexShrink: 0 }}>
									{languagesCodeFullname[currentLanguageCode]}:
								</span>
							</span>
						) : (
							<select
								onChange={(e) => {
									setNewTranslation({
										...newTranslation,
										currentLanguageCode: e.target.value,
									});
								}}
								className="form-control"
								style={{ width: "150px" }}
							>
								<option value="">Select language</option>
								{Object.keys(languagesCodeFullname).map((key: any) => {
									return (
										<option key={key} value={key}>
											{languagesCodeFullname[key]}
										</option>
									);
								})}
							</select>
						)}
						<input
							type="text"
							name="translation"
							defaultValue={currentTranslation}
							onChange={(e) => {
								onInputchange(e);
							}}
							className="form-control ml--sm"
							style={{
								flexGrow: 1,
								width: "auto",
							}}
						/>
					</div>

					<div className="mt--base flex">
						<span
							className="flex"
							style={{ alignItems: "center", width: "150px" }}
						>
							<span className="flex" style={{ flexShrink: 0 }}>
								Translation source:
							</span>
						</span>
						<input
							type="text"
							name="translation_source"
							onChange={(e) => {
								onInputchange(e);
							}}
							className="form-control ml--sm"
							style={{
								flexGrow: 1,
								width: "auto",
							}}
						/>
					</div>
				</div>
				<div
					className="mt--md mb--md flex"
					style={{ justifyContent: "space-around" }}
				>
					<div>
						{!useSaveTranslation.isLoading && (
							<div
								className="button button--primary button--md"
								onClick={currentDataId ? onSave : onAdd}
							>
								&nbsp;&nbsp;Save translations&nbsp;&nbsp;
							</div>
						)}
						<Loading
							show={useSaveTranslation.isLoading}
							text={"Loading..."}
							imgClass="saveOrderLoading"
							divClass=""
						/>
					</div>
					<div
						className="button button--primary button--md"
						onClick={onCancel}
						style={{ marginLeft: "30px" }}
					>
						Cancel
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminTranslationsModal;
