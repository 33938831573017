import { FC, useContext, useState } from "react";
import LabelTemplateDispatch from "Dispatches/LabelTemplateDispatch";
import ContentEditor from "./LabelTemplateDrawingAreaComponents/ContentEditor";
import TemplateImagesPreview from "./LabelTemplateDrawingAreaComponents/TemplateImagesPreview";
import Zoom from "./LabelTemplateDrawingAreaComponents/Zoom";
import orientation from "../../images/orientation.png";

const LabelTemplateDrawingArea: FC = () => {
	const {
		template,
		accordionIsOpen,
		checkGeneralLabelSettings,
		setTemplate,
		getImages,
		images,
	} = useContext(LabelTemplateDispatch);

	const [imagesHoveredSectionId, setImagesHoveredSectionId] = useState<
		number | undefined
	>(undefined);

	const [zoom, setZoom] = useState(100);
	const [horizontalOrientation, setHorizontalOrientation] = useState(true);

	const removeSectionById = (id: number, getImagesFlag: boolean = true) => {
		const items = Array.from(template.sections);
		let index = items.findIndex((item: any) => {
			return item.id === id;
		});
		items.splice(index, 1);
		let newTemplate = {
			...template,
			sections: [...items],
		};
		setTemplate(newTemplate);

		if (getImagesFlag) {
			getImages(newTemplate);
		}
	};

	const changeOrientation = () => {
		setHorizontalOrientation((prevOrientation: boolean) => !prevOrientation);
	};

	return (
		<div
			className="drawing-area"
			style={{ marginTop: accordionIsOpen ? "-7.5px" : "0px" }}
		>
			<div className="pos-relative flex height-100">
				{checkGeneralLabelSettings().length === 0 &&
					template?.sections?.length > 0 &&
					images.length > 1 && (
						<div
							className={`row-column-image-orientation ${
								horizontalOrientation && "orientation-icons-rotate"
							}`}
							onClick={changeOrientation}
						>
							<img src={orientation} alt="Orientation" />
						</div>
					)}

				<ContentEditor
					imagesHoveredSectionId={imagesHoveredSectionId}
					setImagesHoveredSectionId={setImagesHoveredSectionId}
					removeSectionById={removeSectionById}
				/>
				{checkGeneralLabelSettings().length === 0 &&
					template?.sections?.length > 0 && (
						<Zoom zoom={zoom} setZoom={setZoom} />
					)}

				<TemplateImagesPreview
					zoom={zoom}
					imagesHoveredSectionId={imagesHoveredSectionId}
					setImagesHoveredSectionId={setImagesHoveredSectionId}
					removeSectionById={removeSectionById}
					horizontalOrientation={horizontalOrientation}
				/>
			</div>
		</div>
	);
};

export default LabelTemplateDrawingArea;
