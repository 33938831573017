import { FC, useContext, useEffect, useRef, useState } from "react";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import {
	PrintStatusesFlow,
	PrinterStatuses,
	getPrintStatusesFlowName,
} from "Models/OrderModels";
import ChoosePrinterModal from "./ChoosePrinterModal";
import useDataApi from "Hooks/fetchHook";
import { EndpointPrefix } from "Models/UserModels";
import { toast } from "react-toastify";
import Icon from "./Icon";
import sprite from "images/icons.svg";
import Printing from "images/printing.gif";
import HelpTooltip from "./HelpTooltip";
import Drawer from "./Drawer";
import PrinterInfo from "./PrinterInfo";
import PrinterFineTuningModal from "./PrinterFineTuningModal";
import PrinterEditModalForPrintBatchOrOrder from "./PrinterEditModalForPrintBatchOrOrder";
import { Tooltip } from "react-tooltip";

interface IProps {
	labelTemplate: any;
	printBatch: any;
	printers: any;
	isLoadingPrintBatch: boolean;
	isLoadingFetchPrinters: boolean;
	preparingForPrint: boolean;

	fetchPrinters: () => void;
	fetchPrintBatch: (printBatchId: number) => void;
	setPrintBatch: React.Dispatch<React.SetStateAction<any>>;
	setPreparingForPrint: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrintBatchComponent: FC<IProps> = ({
	labelTemplate,
	printBatch,
	printers,
	isLoadingPrintBatch,
	isLoadingFetchPrinters,
	preparingForPrint,

	fetchPrinters,
	fetchPrintBatch,
	setPrintBatch,
	setPreparingForPrint,
}) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const timer = useRef(null) as any;
	const intervalIdForContinue = useRef(null) as any;
	const intervalIdForStop = useRef(null) as any;
	const intervalIdForRefreshPrintBatch = useRef(null) as any;

	const REFRESH_PRINT_BATCH_INTERVAL = 60000;

	const labelTemplatePaperSettings = labelTemplate?.settings?.default_values;

	const labelTemplateDoubleSided = labelTemplate?.settings?.double_sided;

	let labelTemplatePaperWidth =
		labelTemplatePaperSettings?.label_orientation === "horizontal"
			? labelTemplatePaperSettings?.height
			: labelTemplatePaperSettings?.width;

	const [showChoosePrinterModal, setShowChoosePrinterModal] = useState(false);
	const [showPrinterFineTuningModal, setShowPrinterFineTuningModal] =
		useState(false);
	const [showDrawer, setShowDrawer] = useState(false);
	const [showPrinterEditModal, setShowPrinterEditModal] = useState(undefined);
	const [isLoading, setIsLoading] = useState<undefined | string>(undefined);

	const useStartPrinting = useDataApi();
	const useContinuePrinting = useDataApi();
	const useStopPrinting = useDataApi();
	const useGetPrintBatchForGenLayoutsStatusCheck = useDataApi();

	const [chosenPrinter, setChosenPrinter] = useState<any>(undefined);
	const [chosenPrinterForEditing, setChosenPrinterForEditing] =
		useState<any>(undefined);
	const [compatiblePrinters, setCompatiblePrinters] = useState<any>([]);
	const [incompatiblePrinters, setIncompatiblePrinters] = useState<any>({});

	const numberOfRetriesForPrintBatchStatus = useRef(0);

	useEffect(() => {
		if (labelTemplateDoubleSided !== null && labelTemplatePaperWidth) {
			let compatiblePrinters: any[] = [];
			let incompatiblePrinters: any[] = [];

			printers.forEach((printer: any) => {
				let printerPaperWidth = printer?.settings?.paper?.width;

				if (
					printer.settings.paper.color.toLowerCase() === printBatch.color &&
					labelTemplatePaperWidth === printerPaperWidth &&
					(printer.settings.double_sided || !labelTemplateDoubleSided)
				) {
					compatiblePrinters.push(printer);
				} else {
					incompatiblePrinters.push(printer);
				}
			});

			setCompatiblePrinters(compatiblePrinters);
			setIncompatiblePrinters(incompatiblePrinters);
		}
	}, [printers]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// set chosen printer on mount
		if (printers.length > 0) {
			let printer = printers.find((printer: { id: any }) => {
				return printer.id === printBatch.printer_id;
			});

			setChosenPrinter(printer);
		}
	}, [printers.length]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// set printer with fresh status
		if (chosenPrinter) {
			setChosenPrinter((prevPrinter: any) => {
				return {
					...prevPrinter,
					status_code: printBatch.printer_status_code,
					status_name: printBatch.printer_status_name,
				};
			});
		}
	}, [printBatch]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** Get Print batch for status check  START *********************************************** */

	useEffect(() => {
		const { data } = useGetPrintBatchForGenLayoutsStatusCheck;
		if (data && data.message) {
			let printBatchForGenLayoutsStatusCheck = data.message;

			// if print batch status is Print ready we must check for that status change
			let setTimeoutDelayArray = [
				1000, 1000, 1000, 1000, 2000, 2000, 3000, 3000, 30000,
			];

			if (
				(printBatchForGenLayoutsStatusCheck.print_batch_status ===
					PrintStatusesFlow.PRINT_READY.code ||
					printBatchForGenLayoutsStatusCheck.print_batch_status ===
						PrintStatusesFlow.GENERATING_LAYOUTS.code) &&
				numberOfRetriesForPrintBatchStatus.current <
					setTimeoutDelayArray.length - 1
			) {
				timer.current = setTimeout(() => {
					fetchPrintBatchForStatusCheck(printBatch.print_batch_id);
				}, setTimeoutDelayArray[numberOfRetriesForPrintBatchStatus.current]);

				numberOfRetriesForPrintBatchStatus.current =
					numberOfRetriesForPrintBatchStatus.current + 1;
			} else if (
				printBatchForGenLayoutsStatusCheck.print_batch_status <=
					PrintStatusesFlow.PRINT_READY.code &&
				numberOfRetriesForPrintBatchStatus.current ===
					setTimeoutDelayArray.length - 1
			) {
				timer.current = setTimeout(() => {
					fetchPrintBatchForStatusCheck(printBatch.print_batch_id);
				}, setTimeoutDelayArray[numberOfRetriesForPrintBatchStatus.current]);
			} else if (
				printBatchForGenLayoutsStatusCheck.print_batch_status ===
					PrintStatusesFlow.PRINTING.code ||
				printBatchForGenLayoutsStatusCheck.print_batch_status ===
					PrintStatusesFlow.GENERATING_LAYOUTS_FAILED.code
			) {
				setPreparingForPrint(false);
				setPrintBatch(data.message);
				setIsLoading(undefined);
			}
		}
	}, [useGetPrintBatchForGenLayoutsStatusCheck.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrintBatchForGenLayoutsStatusCheck;
		if (error) {
			toast.error("Unable to get Print batch info.");
		}
	}, [useGetPrintBatchForGenLayoutsStatusCheck.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** Get Print batch for status check END   *********************************************** */

	/* *********************************** Check timeouts and intervals batch refresh START   *********************************************** */

	useEffect(() => {
		// in case we click refresh and check print batch with fetchPrintBatch function between intervals in setTimeoutDelayArray,
		// we will use printBatch data for stopping preparingForPrint animation
		if (
			timer.current &&
			printBatch.print_batch_status === PrintStatusesFlow.PRINTING.code
		) {
			clearTimeout(timer.current);
			timer.current = null;
			setPreparingForPrint(false);
			setIsLoading(undefined);
		}

		// in case we click continue printing
		if (
			intervalIdForContinue?.current &&
			printBatch.print_batch_status === PrintStatusesFlow.PRINTING.code
		) {
			clearInterval(intervalIdForContinue.current);
			intervalIdForContinue.current = null;
			setIsLoading(undefined);
		}

		// in case we click stop printing
		if (
			intervalIdForStop?.current &&
			(printBatch.print_batch_status ===
				PrintStatusesFlow.PRINTING_STOPPED.code ||
				printBatch.print_batch_status === PrintStatusesFlow.PRINTED.code)
		) {
			clearInterval(intervalIdForStop.current);
			intervalIdForStop.current = null;
			setIsLoading(undefined);
		}

		// remove global refresh print batch interval
		if (
			printBatch.printer_status_code >=
				PrinterStatuses.ERROR_OUT_OF_PAPER.code &&
			intervalIdForRefreshPrintBatch.current
		) {
			clearInterval(intervalIdForRefreshPrintBatch.current);
			intervalIdForRefreshPrintBatch.current = null;
		}
	}, [printBatch.print_batch_status]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** Check timeouts and intervals batch refresh END   *********************************************** */

	/* *************************************** Start Printing START ******************************** */
	useEffect(() => {
		const { data } = useStartPrinting;
		if (data && data.message) {
			// and start checking is print batch status change to printing
			fetchPrintBatchForStatusCheck(printBatch.print_batch_id);
			intervalIdForRefreshPrintBatch.current = setInterval(
				onRefresh,
				REFRESH_PRINT_BATCH_INTERVAL
			);
		}
	}, [useStartPrinting.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useStartPrinting;
		if (error) {
			onRefresh();
			setIsLoading(undefined);
			setPreparingForPrint(false);
			toast.error(`Start printing failed. ${error}`);
		}
	}, [useStartPrinting.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *************************************** Start Printing END ******************************** */

	/* ************************* Continue Printing ********************************* */

	useEffect(() => {
		const { data } = useContinuePrinting;
		if (data && data.message) {
			intervalIdForContinue.current = setInterval(onRefresh, 1000);
			intervalIdForRefreshPrintBatch.current = setInterval(
				onRefresh,
				REFRESH_PRINT_BATCH_INTERVAL
			);
		}
	}, [useContinuePrinting.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useContinuePrinting;
		if (error) {
			onRefresh();
			setIsLoading(undefined);
			toast.error(`Continue printing failed. ${error}`);
		}
	}, [useContinuePrinting.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************************************************************** */

	/* ************************* Stop Printing ********************************* */

	useEffect(() => {
		const { data } = useStopPrinting;
		if (data && data.message) {
			intervalIdForStop.current = setInterval(onRefresh, 1000);
			if (intervalIdForRefreshPrintBatch.current) {
				clearInterval(intervalIdForRefreshPrintBatch.current);
				intervalIdForRefreshPrintBatch.current = null;
			}
		}
	}, [useStopPrinting.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useStopPrinting;
		if (error) {
			onRefresh();
			setIsLoading(undefined);
			toast.error(`Stop printing failed. ${error}`);
		}
	}, [useStopPrinting.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************************************************************** */

	// Update print batch printer info

	useEffect(() => {
		if (chosenPrinter?.status_code && chosenPrinter?.id) {
			setPrintBatch((prevPrintBatch: any) => {
				return {
					...prevPrintBatch,
					printer_id: chosenPrinter?.id,
					printer_status_code: chosenPrinter?.status_code,
					printer_status_name: chosenPrinter?.status_name,
				};
			});
		}
	}, [chosenPrinter?.status_code, chosenPrinter?.id]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************** FUNCTIONS START ************************************************ */

	const isChosenPrinterCompatible = () => {
		let printerPaperWidth = chosenPrinter?.settings?.paper?.width;
		if (
			chosenPrinter?.settings?.paper?.color.toLowerCase() ===
				printBatch?.color &&
			labelTemplatePaperWidth === printerPaperWidth &&
			(chosenPrinter?.settings?.double_sided || !labelTemplateDoubleSided)
		) {
			return true;
		}
		return false;
	};

	const fetchPrintBatchForStatusCheck = (printBatchId: number) => {
		useGetPrintBatchForGenLayoutsStatusCheck.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printBatch/${printBatchId}`
		);
	};

	const onStartPrinting = () => {
		setIsLoading("start");
		// when start printing api call is finished we turn on Preparing for print animation by
		setPreparingForPrint(true);

		if (!isLoading) {
			useStartPrinting.doFetch(
				`/${EndpointPrefix[account_type_id]}/printer/startPrinting`,
				{
					batchId: printBatch.print_batch_id,
				},
				"POST"
			);
		}
	};

	const onStopPrinting = () => {
		setIsLoading("stop");

		if (!isLoading) {
			useStopPrinting.doFetch(
				`/${EndpointPrefix[account_type_id]}/printer/stopPrinting`,
				{ batchId: printBatch.print_batch_id },
				"POST"
			);
		}
	};

	const onContinuePrinting = () => {
		setIsLoading("continue");

		if (!isLoading) {
			useContinuePrinting.doFetch(
				`/${EndpointPrefix[account_type_id]}/printer/startPrinting`,
				{ batchId: printBatch.print_batch_id },
				"POST"
			);
		}
	};

	const onRefresh = () => {
		fetchPrintBatch(printBatch.print_batch_id);
	};

	/* ********************************** FUNCTIONS END ************************************************ */

	let totalQuantity = 0;
	let totalPrintQuantity = 0;
	let totalPrintedQuantity = 0;

	let printerInfoTitle = `Click to see Printer info. Printer status: ${chosenPrinter?.status_name}`;

	return (
		<div className="bg--light pd--base print-batch-component">
			<div>
				<div className="pd--base" style={{ border: "2px solid #DFDFDF" }}>
					<div
						className="flex flex-center-secondary-axis mb--base "
						style={{
							justifyContent: "space-between",
						}}
					>
						<h2
							style={{
								marginBottom: 0,
								justifyContent: "space-between",
							}}
						>
							{printBatch.color === "white" ? "White" : "Black"} /{" "}
							{labelTemplatePaperWidth} mm
							<div
								className={`pill pill--outline-quiet pill--toolbar ${
									isLoadingPrintBatch && "refresh-loader"
								}`}
							>
								<div className="toolbarIconContainer" onClick={onRefresh}>
									<Icon name="refresh" className="toolbarIcon" />
								</div>
							</div>
						</h2>
						{/* buttons START */}
						<div>
							{printBatch.print_batch_status <
								PrintStatusesFlow.PRINTED.code && (
								<button
									className={`button button--md mt--xs button--primary ${
										(preparingForPrint ||
											!isChosenPrinterCompatible() ||
											!chosenPrinter ||
											printBatch.print_batch_status ===
												PrintStatusesFlow.PRINTING.code) &&
										"button--disabled"
									}`}
									onClick={() => {
										setShowPrinterFineTuningModal(true);
									}}
								>
									Printing settings
								</button>
							)}

							{printBatch.print_batch_status <=
								PrintStatusesFlow.PRINT_READY.code && (
								<button
									className={`animated-button button button--md button--${
										!chosenPrinter ||
										preparingForPrint ||
										!isChosenPrinterCompatible() ||
										isLoading === "start"
											? "disabled"
											: "primary"
									}  mt--xs ml--base
                  `}
									type="button"
									onClick={onStartPrinting}
								>
									Start printing
								</button>
							)}

							{printBatch.print_batch_status ===
								PrintStatusesFlow.PRINTING.code && (
								<button
									className={`animated-button button button--md 
                    button--${preparingForPrint ? "disabled" : "warning"}
                    mt--xs ml--base 
                    ${
											isLoading === "stop" && "animated-gradient-warning-button"
										}`}
									type="button"
									onClick={onStopPrinting}
								>
									Stop printing
								</button>
							)}

							{printBatch.print_batch_status ===
								PrintStatusesFlow.PRINTING_STOPPED.code && (
								<button
									className={`animated-button button button--${
										!isChosenPrinterCompatible() ? "disabled" : "primary"
									} button--md mt--xs ml--base ${
										isLoading === "continue" &&
										"animated-gradient-primary-button"
									}`}
									type="button"
									onClick={onContinuePrinting}
								>
									Continue printing
								</button>
							)}
							{printBatch.print_batch_status ===
								PrintStatusesFlow.PRINTED.code && (
								<span className="statusQuiet statusCompleted">FINISHED</span>
							)}
						</div>
						{/* buttons END */}
					</div>
					<div className="mb--base flex">
						<div className="font-weight-500">
							Print
							{printBatch.print_batch_status === PrintStatusesFlow.PRINTED.code
								? "ed "
								: " "}
							on:{" "}
						</div>
						{printBatch.print_batch_status !==
						PrintStatusesFlow.PRINTED.code ? (
							<>
								{chosenPrinter && chosenPrinter.name ? (
									<div className="flex">
										<div
											onClick={() => {
												setShowChoosePrinterModal(true);
											}}
											className="cursor-pointer mr--sm ml--sm"
											title="Change printer"
										>
											<svg
												className="edit-printer-icon-blue"
												style={{ marginBottom: "-2px" }}
											>
												<use xlinkHref={`${sprite}#icon-edit`} />
											</svg>
										</div>
										<div className="mr--sm">{chosenPrinter.name}</div>
										<div
											onClick={() => {
												setShowDrawer(true);
											}}
											className="cursor-pointer"
											title={printerInfoTitle}
										>
											<div
												style={{
													height: "18px",
													marginTop: "3px",
												}}
											>
												<svg
													className={` ${
														chosenPrinter?.status_code <
														PrinterStatuses.ERROR_OUT_OF_PAPER.code
															? "edit-printer-icon-blue"
															: "edit-printer-icon-red"
													}`}
												>
													<use xlinkHref={`${sprite}#icon-info-rounded`} />
												</svg>
											</div>
										</div>
									</div>
								) : (
									<span
										className={`btn-link cursor-pointer ml--xs`}
										style={
											preparingForPrint
												? { pointerEvents: "none", color: "#999999" }
												: undefined
										}
										onClick={() => {
											setShowChoosePrinterModal(true);
										}}
									>
										Choose printer
									</span>
								)}
							</>
						) : (
							<span className="ml--xs">{printBatch.printer_name}</span>
						)}

						{printBatch.print_batch_status < PrintStatusesFlow.PRINTED.code &&
							chosenPrinter &&
							!isChosenPrinterCompatible() && (
								<>
									<div className="flex flex-center-secondary-axis ml--sm ">
										<div
											className="chosen-printer-incompatible"
											data-tooltip-id="printerIncompatible"
										>
											<Icon
												name="exclamation"
												className="printerIncompatible"
											/>
										</div>
									</div>
									<Tooltip
										id="printerIncompatible"
										className="react-tooltip-printer-incompatible box--shadowed"
										float
										offset={10}
									>
										<span>
											Chosen printer is not compatible with print batch
											settings.
										</span>
									</Tooltip>
								</>
							)}
					</div>
					<div className="flex text--quiet print-summary-column-titles">
						<span className="flex-1">Style</span>
						<span className="flex-1">Size</span>
						<span className="flex-1 flex-center-secondary-axis">
							<span>Quantity (Print)</span>
							<div style={{ display: "inline-block" }}>
								<HelpTooltip helpFor="PrintQuantity" />
							</div>
						</span>
						<span className="flex-1">Printed Quantity</span>
						<span className="flex-1">Status</span>
					</div>
					{printBatch.printJobs.map((printJob: any, index: number) => {
						totalQuantity += printJob.qty;

						totalPrintQuantity += printJob.print_qty;

						totalPrintedQuantity += printJob.result.printed_qty;

						return (
							<div
								style={{
									borderBottom: `${
										index < printBatch.printJobs.length - 1
											? "2px solid rgb(244, 244, 244)"
											: ""
									}`,
									background: `${
										printJob.status === PrintStatusesFlow.PRINTING.code
											? "#b4fac3"
											: ""
									}`,
								}}
								key={printJob.style + "-" + printJob.size}
							>
								<div
									className="flex"
									style={{
										height: "40px",
										alignItems: "center",
									}}
								>
									<span className="flex-1">{printJob.style}</span>
									<span className="flex-1">{printJob.size}</span>
									<span className="flex-1">
										{printJob.qty} ({printJob.print_qty})
									</span>{" "}
									<span className="flex-1">
										{printJob.status === PrintStatusesFlow.PRINTING.code
											? "-"
											: printJob.result.printed_qty}
									</span>
									<span
										className="flex-1 flex"
										style={{ alignItems: "center" }}
										title={getPrintStatusesFlowName(printJob.status)}
									>
										{printJob.status === PrintStatusesFlow.PRINT_READY.code && (
											<svg
												style={{
													height: "25px",
													width: "25px",
												}}
												className=""
											>
												<use xlinkHref={`${sprite}#icon-sand-clock`} />
											</svg>
										)}
										{printJob.status === PrintStatusesFlow.PRINTING.code && (
											<img
												style={{
													width: "60px",
													height: "60px",
													margin: "-14px",
													overflow: "visible",
													filter: "grayscale(100%)",
												}}
												alt="Printing"
												src={Printing}
											/>
										)}

										{printJob.status ===
											PrintStatusesFlow.PRINTING_STOPPED.code && (
											<span>PRINTING STOPPED</span>
										)}
										{printJob.status === PrintStatusesFlow.PRINTED.code && (
											<svg
												className="checkmark"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 52 52"
												style={{
													width: "24px",
													height: "24px",
													margin: "0",
												}}
											>
												<circle
													className="checkmark__circle"
													cx="26"
													cy="26"
													r="25"
													fill="none"
												/>
												<path
													className="checkmark__check"
													fill="none"
													d="M14.1 27.2l7.1 7.2 16.7-16.8"
												/>
											</svg>
										)}
									</span>
								</div>
							</div>
						);
					})}
					<div className="flex font-weight-500 print-summary-total">
						<span className="flex-1"></span>
						<span className="flex-1">Total</span>
						<span className="flex-1">
							{totalQuantity} ({totalPrintQuantity})
						</span>
						<span className="flex-1">{totalPrintedQuantity}</span>
						<span className="flex-1">{/*status*/}</span>
					</div>
				</div>
			</div>
			{showChoosePrinterModal && (
				<ChoosePrinterModal
					setShowChoosePrinterModal={setShowChoosePrinterModal}
					setChosenPrinter={setChosenPrinter}
					compatiblePrinters={compatiblePrinters}
					incompatiblePrinters={incompatiblePrinters}
					chosenPrinter={chosenPrinter}
					printBatch={printBatch}
					fetchPrinters={fetchPrinters}
					isLoadingFetchPrinters={isLoadingFetchPrinters}
				/>
			)}

			{showPrinterFineTuningModal && (
				<PrinterFineTuningModal
					labelTemplateId={labelTemplate.id}
					printBatchId={printBatch.print_batch_id}
					setShowPrinterFineTuningModal={setShowPrinterFineTuningModal}
				/>
			)}

			<Drawer
				showDrawer={showDrawer}
				orientation="left"
				isModalActive={showPrinterEditModal}
				setShowDrawer={setShowDrawer}
			>
				<PrinterInfo
					chosenPrinter={chosenPrinter}
					chosenPrinterForEditing={chosenPrinterForEditing}
					setChosenPrinter={setChosenPrinter}
					setChosenPrinterForEditing={setChosenPrinterForEditing}
					setShowPrinterEditModal={setShowPrinterEditModal}
					showDrawer={showDrawer}
					fetchPrinters={fetchPrinters}
				/>
			</Drawer>
			{showPrinterEditModal && (
				<PrinterEditModalForPrintBatchOrOrder
					showPrinterEditModal={showPrinterEditModal}
					setShowPrinterEditModal={setShowPrinterEditModal}
					setChosenPrinterForEditing={setChosenPrinterForEditing}
				/>
			)}
		</div>
	);
};

export default PrintBatchComponent;
