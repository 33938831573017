import React, {
	useEffect,
	useState,
	useRef,
	useContext,
	useReducer,
} from "react";
import { useParams, useNavigate } from "react-router-dom-v5-compat";
import { Prompt } from "react-router-dom";

import {
	orderToCompareForDirty,
	deepIsEqual,
	transformOrderItemForPost,
} from "Utils/utils";
import { checkOrder, prepareErrorMessage } from "Utils/orderCheck";
import {
	IOrderState,
	initialOrder,
	IOrderItemState,
	OrderStatusesFlow,
	initialOrderItemState,
	IOrderItemAction,
} from "Models/OrderModels";
import {
	EndpointPrefix,
	isBrand,
	isFactory,
	isLabelMaker,
} from "Models/UserModels";

import Loading from "Components/Shared/Loading";
import OrderItem from "Components/Order/OrderItem/OrderItem";
import OrderItemsPreview from "Components/Order/OrderItemsPreview/OrderItemsPreview";
import OrderLog from "Components/Order/OrderLog/OrderLog";
import OrderHeader from "Components/Order/OrderHeader/OrderHeader";
import ChooseOption from "Components/Shared/ChooseOption";
import QuickPreview from "Components/Order/QuickPreview/QuickPreview";
import AddExistingStyleModal from "Components/Order/OrderItemsPreview/components/AddExistingStyleModal";
import NoteModal from "Components/Order/OrderItemsPreview/components/NoteModal";

import useReorderHook from "Hooks/reorderHook";
import usePageTitle from "Hooks/pageTitleHook";

import useAddNewOrder from "Hooks/orderHooks/addNewOrderHook";
import useUpdateOrder from "Hooks/orderHooks/updateOrderHook";
import useGetLabelTemplates from "Hooks/orderHooks/getLabelTemplatesHook";
import useGetOrder from "Hooks/orderHooks/getOrderHook";

import { useFetchBrands } from "Hooks/queryHooks/useFetchBrands";
import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";
import { useFetchLabelTemplates } from "Hooks/queryHooks/useFetchLabelTemplates";
import { useFetchLabelTemplatesInitialValues } from "Hooks/queryHooks/useFetchLabelTemplateInitialValues";
import { useFetchShippingDetails } from "Hooks/queryHooks/useFetchShippingDetails";

import "./styles/Order.css";
import OrderPrintSummaryModal from "Components/Order/OrderHeader/Components/OrderSummaryModals/OrderPrintSummaryModal";
import Wizard from "Components/Shared/Wizard";
import OrderPlaceWizardShippingDetails from "Components/Order/OrderPlaceWizardComponents/01.OrderPlaceWizardShippingDetails";
import OrderPlaceWizardSummary from "Components/Order/OrderPlaceWizardComponents/02.OrderPlaceWizardSummary";
import Address from "Components/Shared/Address";
import OrderDispatch from "Dispatches/OrderDispatch";
import GlobalDispatch from "Dispatches/GlobalDispatch";

const Order: React.FunctionComponent = () => {
	const navigate = useNavigate();
	const { orderId: orderIdFromParams } = useParams() as any;
	const { user, setError } = useContext(GlobalDispatch);
	const { account_type_id } = user;

	// *********************** states START ****************************************

	const [order, setOrder] = useState(initialOrder as IOrderState);
	const [loadedOrder, setLoadedOrder] = useState(initialOrder as IOrderState);

	const [localNote, setLocalNote] = useState("") as any;

	const [showPlaceWizard, setShowPlaceWizard] = useState(false);
	const [showAddressForm, setShowAddressForm] = useState(false);

	const { shippingDetails: initialAddresses } = useFetchShippingDetails(
		account_type_id,
		setError
	);

	const [addresses, setAddresses] = useState(initialAddresses);

	useEffect(() => {
		setAddresses(initialAddresses);
	}, [initialAddresses.length]); // eslint-disable-line react-hooks/exhaustive-deps

	// Temporary storage, only occupied while modal for choosing an option is shown
	const [brandsForFactory, setBrandsForFactory] = useState([]);

	// Temporary storage, only occupied while modal for choosing an option is shown
	const [labelTemplatesForBrand, setLabelTemplates] = useState([]);

	// Index of the order item that is currently shown in the form
	const [currentOrderItemIndex, setCurrentOrderItemIndex] = useState(0);

	const [isDirty, setIsDirty] = useState(false);
	const [showPreviewOrder, setShowPreviewOrder] = useState(0);
	const [itemsWithMissingInfo, setItemsWithMissingInfo] = useState({}) as any;
	const [currentBrandId, setCurrentBrandId] = useState(undefined) as any;

	const [showAddExistingStyleModal, setShowAddExistingStyleModal] =
		useState(false);
	const [showNoteModal, setShowNoteModal] = useState(false);
	const [printData, setPrintData] = useState<any>(undefined);

	const saveOrderFinishedSuccessfully = useRef(false);

	const orderItemDataReducer = (
		state: IOrderItemState,
		action: IOrderItemAction
	) => {
		switch (action.type) {
			default:
				return { ...state, ...action.payload };
		}
	};

	const [orderItem, dispatch] = useReducer(
		orderItemDataReducer,
		initialOrderItemState
	);

	// set current order item shown in order form
	const setOrderItem = (payload: any) => {
		dispatch({ type: "default", payload });
	};

	// *********************** states END ****************************************

	// *********************** React Query START *********************************

	const { brands, isLoadingBrands } = useFetchBrands(account_type_id, setError);
	const { labelTemplates, isLoadingLabelTemplates } = useFetchLabelTemplates(
		account_type_id,
		setError
	);
	const { isLoadingLabelTemplateInitialValues } =
		useFetchLabelTemplatesInitialValues(order);
	const { initialValues, isLoadingInitialValues } = useFetchInitialValues(
		order,
		currentBrandId
	);

	// *********************** React Query END ***********************************

	const orderRef = useRef(null) as any;
	const newOrderFirstSavedRef = useRef(false) as any;

	const isNewOrder = !orderIdFromParams;

	// ************************ Hooks START **************************************

	const { copyOrReorderWholeOrder, useReorder } = useReorderHook(order);

	usePageTitle(isNewOrder ? "New Order" : "Edit order");

	const {
		showSaveResultNotificationRef,
		addNewOrderDoFetch,
		addNewOrderIsLoading,
	} = useAddNewOrder(
		order,
		newOrderFirstSavedRef,
		setOrder,
		setLoadedOrder,
		saveOrderFinishedSuccessfully
	);

	const { updateOrderDoFetch, updateOrderIsLoading } = useUpdateOrder(
		order,
		showSaveResultNotificationRef,
		setOrder,
		setLoadedOrder,
		saveOrderFinishedSuccessfully
	);

	const {
		getLabelTemplatesDoFetch,
		getLabelTemplatesIsLoading,
		onCreateNewOrder,
	} = useGetLabelTemplates(
		setLabelTemplates,
		orderIdFromParams,
		setOrder,
		setLoadedOrder,
		setOrderItem
	);

	const { getOrderDoFetch, getOrderIsLoading } = useGetOrder(
		orderIdFromParams,
		currentOrderItemIndex,
		setCurrentBrandId,
		setOrder,
		setLoadedOrder,
		setOrderItem
	);

	// ************************ Hooks END **************************************

	// ************************ useEffects START **************************************

	useEffect(() => {
		const onReloadPage = (e: any) => {
			if (isDirty) {
				e.preventDefault();
				e.returnValue =
					"Unsaved data will be lost. Are you sure you want to leave this page?";
			}
		};

		window.addEventListener("beforeunload", onReloadPage);
		return () => {
			window.removeEventListener("beforeunload", onReloadPage);
		};
	}, [isDirty]);

	// Initiate appropriate flow
	// - on first load of the component
	// - once orderIdFromParams is changed (reorder for example)
	useEffect(() => {
		if (newOrderFirstSavedRef.current) {
			newOrderFirstSavedRef.current = false;
		} else if (isBrand(account_type_id)) {
			handleOnLoadBrand();
		} else if (isFactory(account_type_id)) {
			handleOnLoadFactory();
		} else if (isLabelMaker(account_type_id)) {
			navigate(`/preview/${orderIdFromParams}`, { replace: true });
		}
	}, [orderIdFromParams]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (order?.labelTemplate?.id > 0) {
			if (!order?.labelTemplate?.settings?.form_settings?.quantity) {
				setError({
					title: "Something Went Wrong",
					text: "Label template not valid.",
					primary: {
						text: "Back to orders",
						action: () => navigate(`/orders`),
					},
					secondary: {
						text: "",
						action: () => {},
					},
				});
				return;
			}
		}
	}, [order?.labelTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

	// Saves current changes made to the order item to the order
	useEffect(() => {
		const { orderItems } = order;

		if (orderItems.length > 0) {
			const newItems = [...orderItems];
			newItems[currentOrderItemIndex] = orderItem;
			setOrder((prevOrder: any) => {
				return { ...prevOrder, orderItems: newItems };
			});
		}
	}, [orderItem]); // eslint-disable-line react-hooks/exhaustive-deps

	// Update isDirty when order or loadedOrder changes
	useEffect(() => {
		const currentOrderWithoutPhotos = orderToCompareForDirty(order);
		const loadedOrderWithoutPhotos = orderToCompareForDirty(loadedOrder);

		if (!deepIsEqual(currentOrderWithoutPhotos, loadedOrderWithoutPhotos)) {
			setIsDirty(true);
		} else {
			setIsDirty(false);
		}
		if (!isLoadingInitialValues)
			setItemsWithMissingInfo(checkOrder(initialValues, order));

		orderRef.current = order;
	}, [order, loadedOrder, isLoadingInitialValues]); // eslint-disable-line react-hooks/exhaustive-deps

	// *********************************  useEffects END  *************************************

	// *********************************  Functions START  *************************************

	const handleOnLoadBrand = () => {
		setCurrentBrandId(user.brand_id);
		if (isNewOrder) {
			setLabelTemplates(labelTemplates);
			if (labelTemplates && labelTemplates.length === 1) {
				onCreateNewOrder(labelTemplates[0]);
			}
		} else {
			getOrderDoFetch(
				`/${EndpointPrefix[account_type_id]}/orders/${orderIdFromParams}`
			);
		}
	};

	const handleOnLoadFactory = () => {
		if (isNewOrder) {
			setBrandsForFactory(brands);
		} else {
			getOrderDoFetch(
				`/${EndpointPrefix[account_type_id]}/orders/${orderIdFromParams}`
			);
		}
	};

	const brandChosen = (brand: any) => {
		const { id: brandId } = brand;
		setCurrentBrandId(brandId);
		setBrandsForFactory([]);
		setOrder(initialOrder);
		setLoadedOrder(initialOrder);
		getLabelTemplatesDoFetch(
			`/${EndpointPrefix[account_type_id]}/data/labelTemplates/${brandId}`
		);
	};

	const labelTemplateChosen = (labelTemplate: any) => {
		setLabelTemplates([]);
		onCreateNewOrder(labelTemplate);
	};

	const saveOrder = (shouldShowSaveResultNotification?: boolean) => {
		const { status } = order;

		// Confirm if we want to save already approved Order
		// Skip confirm if order has shipping details
		let orderWithoutShippingDetails =
			!order.shippingAddress &&
			!order.billingAddress &&
			!order.preferredShippingMethod;

		if (
			status === OrderStatusesFlow.APPROVED.code &&
			isFactory(account_type_id) &&
			orderWithoutShippingDetails
		) {
			if (
				window.confirm(
					"This order is approved. If you save it, you will have to request approval again. Do you want to proceed?"
				)
			) {
				checkOrderForItemsWithMissingInfo(shouldShowSaveResultNotification);
			} else return;
		}
		// alert if some item has missing info
		else {
			checkOrderForItemsWithMissingInfo(shouldShowSaveResultNotification);
		}
	};

	const checkOrderForItemsWithMissingInfo = (
		shouldShowSaveResultNotification?: boolean
	) => {
		const { message, indexOfFirstItemWithError } =
			prepareErrorMessage(itemsWithMissingInfo);

		if (message.length > 0) {
			if (indexOfFirstItemWithError) {
				setCurrentOrderItemIndex(indexOfFirstItemWithError);
				setOrderItem(order?.orderItems[indexOfFirstItemWithError]);
			}

			alert(message);
		} else {
			showSaveResultNotificationRef.current = shouldShowSaveResultNotification;
			saveOrderApiCall();
		}
	};

	const saveOrderApiCall = () => {
		const {
			orderId,
			labelTemplate,
			lotNumber,
			orderItems,
			brandId,
			factoryId,
			note,
			shippingAddress,
			billingAddress,
			preferredShippingMethod,
			extraInfo: orderExtraInfo,
		} = order;

		const orderItemsForPost = orderItems
			.map((item: IOrderItemState) => transformOrderItemForPost(item))
			.map((item: any) => {
				item.label_images = [];
				return item;
			});

		const baseData: any = {
			order: {
				label_template_id: labelTemplate?.id,
				brand_id: brandId,
				lot_number: lotNumber,
				order_items: orderItemsForPost,
				note: note ? note : undefined,
				shipping_address: shippingAddress,
				billing_address: billingAddress,
				preferred_shipping_method: preferredShippingMethod,
			},
		};

		if (factoryId) baseData.order.factory_id = factoryId;

		if (orderExtraInfo && Object.keys(orderExtraInfo).length > 0)
			baseData.order.extra_info = orderExtraInfo;

		if (!orderId) {
			addNewOrderDoFetch(
				`/${EndpointPrefix[account_type_id]}/orders`,
				baseData,
				"POST"
			);
		} else {
			baseData.order.order_id = orderId;
			updateOrderDoFetch(
				`/${EndpointPrefix[account_type_id]}/orders`,
				baseData,
				"PUT"
			);
		}
	};

	const addedStyleNumbers =
		order && order.orderItems
			? order.orderItems.map((item: any) => item.styleNumber)
			: [];

	// ********************************* Functions END **************************************

	return (
		<OrderDispatch.Provider
			value={{
				order,
				setOrder,
				orderItem,
				setOrderItem,
				itemsWithMissingInfo,
				copyOrReorderWholeOrder,
				showPreviewOrder,
				setShowPreviewOrder,
				saveOrderFinishedSuccessfully,
			}}
		>
			{/* =================== Loading ===================*/}
			<Loading
				show={
					getOrderIsLoading ||
					isLoadingLabelTemplates ||
					isLoadingLabelTemplateInitialValues ||
					getLabelTemplatesIsLoading ||
					isLoadingInitialValues ||
					isLoadingBrands ||
					useReorder.isLoading
				}
				text="Loading..."
				imgClass="block-center"
				divClass="col-sm-12"
			/>

			{/* =================== Choose dialogs ======================== */}
			{brandsForFactory && brandsForFactory.length > 0 && (
				<ChooseOption
					options={brandsForFactory}
					onChooseOption={brandChosen}
					type="Brand"
					action="Create Order"
				/>
			)}

			{labelTemplatesForBrand && labelTemplatesForBrand.length > 1 && (
				<ChooseOption
					options={labelTemplatesForBrand}
					onChooseOption={labelTemplateChosen}
					type="Label Template"
					action="Create Order"
				/>
			)}

			{/* =================== Prompt ===================*/}
			<Prompt
				when={isDirty}
				message={(location) => {
					if (location.pathname.startsWith("/order/preview")) {
						return "Order not saved. Are you sure you want to leave?";
					}

					return location.pathname.startsWith("/order/")
						? true
						: "Order not saved. Are you sure you want to leave?";
				}}
			/>

			{(addNewOrderIsLoading || updateOrderIsLoading) && (
				<div className="blurPageOnSaveOrder" />
			)}

			{order &&
				order.labelTemplate?.id !== -1 &&
				order?.labelTemplate.settings?.form_settings?.quantity &&
				!getOrderIsLoading &&
				!getLabelTemplatesIsLoading &&
				!isLoadingLabelTemplates &&
				!isLoadingLabelTemplateInitialValues &&
				!isLoadingBrands &&
				!isLoadingInitialValues &&
				!useReorder.isLoading && (
					<>
						<div
							className={`main__content ${
								showPreviewOrder && "orderFormWithOpenPreview"
							}`}
							id="orderForm"
						>
							<div className="container container-narrow">
								<OrderHeader
									saveOrder={saveOrder}
									loadedOrder={loadedOrder}
									isLoadingSaveOrder={
										addNewOrderIsLoading || updateOrderIsLoading
									}
									isDirty={isDirty}
									setPrintData={setPrintData}
									setShowPlaceWizard={setShowPlaceWizard}
									currentOrderItemIndex={currentOrderItemIndex}
									setCurrentOrderItemIndex={setCurrentOrderItemIndex}
								/>

								<div className="row" style={{ marginTop: "30px" }}>
									<div className="col-sm-12 col-md-8 col-lg-3">
										<OrderItemsPreview
											order={order}
											setOrder={setOrder}
											currentOrderItemIndex={currentOrderItemIndex}
											setShowAddExistingStyleModal={
												setShowAddExistingStyleModal
											}
											setCurrentOrderItemIndex={setCurrentOrderItemIndex}
											showNoteModal={showNoteModal}
											setShowNoteModal={setShowNoteModal}
											setLocalNote={setLocalNote}
										/>
									</div>

									<div className="col-sm-12 col-md-8 col-lg-6">
										<OrderItem
											order={order}
											saveOrder={saveOrder}
											isLoadingSaveOrder={
												addNewOrderIsLoading || updateOrderIsLoading
											}
											setShowAddExistingStyleModal={
												setShowAddExistingStyleModal
											}
											currentOrderItemIndex={currentOrderItemIndex}
										/>
									</div>

									<div className="col-sm-12 col-md-8 col-lg-2">
										<OrderLog order={order} />
									</div>
								</div>
							</div>
						</div>

						<QuickPreview
							order={order}
							setOrder={setOrder}
							currentOrderItemIndex={currentOrderItemIndex}
						/>

						{!!printData && (
							<OrderPrintSummaryModal
								order={order}
								isDirty={isDirty}
								saveOrder={saveOrder}
								printData={printData}
								setPrintData={setPrintData}
								isLoadingSaveOrder={
									addNewOrderIsLoading || updateOrderIsLoading
								}
							/>
						)}

						{showAddExistingStyleModal && (
							<AddExistingStyleModal
								setShowAddExistingStyleModal={setShowAddExistingStyleModal}
								setCurrentOrderItemIndex={setCurrentOrderItemIndex}
								addedStyleNumbers={addedStyleNumbers}
							/>
						)}
						{showNoteModal && (
							<NoteModal
								setShowNoteModal={setShowNoteModal}
								localNote={localNote}
								setLocalNote={setLocalNote}
							/>
						)}
						{showPlaceWizard && (
							<Wizard
								setShowWizard={setShowPlaceWizard}
								finishButtonText="Place Order"
							>
								<OrderPlaceWizardShippingDetails
									addresses={addresses}
									showAddressForm={showAddressForm}
									setShowAddressForm={setShowAddressForm}
								/>
								<OrderPlaceWizardSummary
									saveOrder={saveOrder}
									isLoadingSaveOrder={
										addNewOrderIsLoading || updateOrderIsLoading
									}
								/>
							</Wizard>
						)}
						{showAddressForm && (
							<Address
								showAddressForm={showAddressForm}
								onCloseModal={() => setShowAddressForm(false)}
								addresses={addresses}
								setAddresses={setAddresses}
							/>
						)}
					</>
				)}
		</OrderDispatch.Provider>
	);
};

export default Order;
