import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";

import { EndpointPrefix } from "Models/UserModels";
import { PSEUDO_URL_QUERY_VALUES } from "Models/OrderModels";

import useDataApi from "Hooks/fetchHook";
import useWindowSize from "Hooks/windowSizeHook";
import usePageTitle from "Hooks/pageTitleHook";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import Loading from "Components/Shared/Loading";
import SearchFilter from "Components/Shared/SearchFilter";

import "./styles/Orders.css";
import Icon from "Components/Shared/Icon";
import { toast } from "react-toastify";
import PrintBatchesHeaderActionsToolbar from "Components/PrintBatches/PrintBatchesHeaderActionsToolbar";
import PrintBatchesHeaderFilters from "Components/PrintBatches/PrintBatchesHeaderFilters";
import PrintBatchesListHeader from "Components/PrintBatches/PrintBatchesListHeader";
import PrintBatchesListItem from "Components/PrintBatches/PrintBatchesListItem";

// use Cases for Print Batches page
// 1. go to /printBatches page - on mount - step_1, step_2
// 2. scroll down ( infinite scroll ) - step_scroll_down, step_4

// 3. apply filter
//       a) while offset = 0   - step_3a
//       b) while offset > 0   - step_3b, step_4

// 4. reset by clicking Print Batches link in nav bar
//       a) while offset = 0 and no filter is set    - step_5
//       b) while offset = 0 and some filter is set  - step_5, step_3a
//       c) while offset > 0 and no filter is set    - step_5, step_4
//       d) while offset > 0 and some filter is set  - step_5, step_3b, step_4

// 5. force refresh on app refresh button
//       a) while offset = 0 and no filter is set    - step_6a
//       b) while offset > 0 and no filter is set    - step_6b

// 6. window size change - step_1, step_2, step_4

// 7. refresh browser tab ( handled with use Case 1 ) - step_1, step_2
// 8. copy/paste link ( handled with use Case 1 ) - step_1, step_2

const PrintBatches: React.FunctionComponent = () => {
	const {
		user: { account_type_id },
		setResetPrintBatchesFilters,
		resetPrintBatchesFilters,
	} = useContext(GlobalDispatch);

	const navigate = useNavigate();
	const location = useLocation();

	const useGetPrintBatches = useDataApi();

	const windowSize = useWindowSize();

	const [printBatches, setPrintBatches] = useState<any[]>([]);
	const [isLoadingPrintBatches, setIsLoadingPrintBatches] = useState(false);

	const [filteredStatus, setFilteredStatus] = useState(undefined) as any;
	const [filteredPrinter, setFilteredPrinter] = useState(undefined) as any;
	const [searchText, setSearchText] = useState("");

	const [offset, setOffset] = useState<any>(0);
	const [limitPrintBatchesPerPage, setLimitPrintBatchesPerPage] = useState(0);

	const [
		selectedPrintBatchesForBulkAction,
		setSelectedPrintBatchesForBulkAction,
	] = useState({}) as any;

	const [ordersHeight, setPrintBatchesHeight] = useState(0);

	const [previousFilterQuery, setPreviousFilterQuery] = useState(
		undefined
	) as any;

	const ordersRef = useRef(null) as any;
	const ordersListRef = useRef(null) as any;

	usePageTitle("Print Batches");

	/* ******************** Infinite scroll START ************************ */

	const observer = useRef<IntersectionObserver>();

	// we use useCallback so that we can detect when the ref element (node) is changed
	const lastOrderElementRef = useCallback(
		(node: Element) => {
			if (isLoadingPrintBatches) return;

			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					printBatches.length > offset &&
					useGetPrintBatches.data &&
					useGetPrintBatches.data.message.length === limitPrintBatchesPerPage
				) {
					// step_scroll_down
					setOffset(printBatches.length);
				}
			});

			if (node) observer.current.observe(node);
		},
		[
			isLoadingPrintBatches,
			printBatches.length,
			offset,
			useGetPrintBatches.data,
			limitPrintBatchesPerPage,
		]
	);

	/* ******************** Infinite scroll END ************************ */

	/* ******************** Align heading and columns in table START ************************ */

	const headerRef = useRef<any>(null);

	const [listWidth, setListWidth] = useState<any>(0);

	const resizeObserver = useRef<any>(
		new ResizeObserver((entries) => {
			const { width } = entries[0].contentRect;

			setListWidth(width);
		})
	);

	const [scrollPadding, setScrollPadding] = useState(0);

	useEffect(() => {
		if (ordersListRef.current) {
			resizeObserver.current.observe(ordersListRef.current);
		}
		let el = ordersListRef.current;
		let obs = resizeObserver.current;
		return () => {
			if (el) {
				obs.unobserve(el);
			}
		};
	}, [ordersListRef, printBatches]);

	useEffect(() => {
		let scrollPadding = headerRef.current?.offsetWidth + 30 - listWidth;

		if (scrollPadding < 100) {
			setScrollPadding(Math.round(scrollPadding));
		}
	}, [listWidth]);

	/* ******************** Align heading and columns in table END ************************ */

	/* ******************** Calculate Print Batches table height for limit START ************************ */

	const resizeObserverPrintBatches = useRef<any>(
		new ResizeObserver((entries) => {
			const { height } = entries[0].contentRect;

			setPrintBatchesHeight(height);
		})
	);

	useEffect(() => {
		if (ordersRef.current) {
			resizeObserverPrintBatches.current.observe(ordersRef.current);
		}
		let el = ordersRef.current;
		let obs = resizeObserverPrintBatches.current;
		return () => {
			if (el) {
				obs.unobserve(el);
			}
		};
	}, [ordersRef]);

	/* ******************** Calculate Print Batches table height for limit END ************************ */

	/* ******************** Use cases START ************************ */
	// step_1

	useEffect(() => {
		const LIST_ITEM_ROW_SIZE = 72;

		// on mount
		if (ordersHeight && limitPrintBatchesPerPage === 0) {
			setLimitPrintBatchesPerPage(
				// get round down number of printBatches and add two more so we have scroll
				((ordersHeight / LIST_ITEM_ROW_SIZE) >> 0) + 2
			);
		}
	}, [ordersHeight]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const LIST_ITEM_ROW_SIZE = 72;

		// on window height change
		if (ordersHeight) {
			setLimitPrintBatchesPerPage(
				// get round down number of printBatches and add two more so we have scroll
				((ordersHeight / LIST_ITEM_ROW_SIZE) >> 0) + 2
			);
		}
	}, [windowSize[1]]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_2
	useEffect(() => {
		if (
			limitPrintBatchesPerPage > 0 &&
			!getAndSetFilters() &&
			printBatches &&
			printBatches.length === 0
		) {
			refreshPrintBatches();
		} else if (limitPrintBatchesPerPage > 0 && printBatches.length > 0) {
			setOffset(printBatches.length);
		}
	}, [limitPrintBatchesPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_3
	useEffect(() => {
		// step_3a
		if (offset === 0 && limitPrintBatchesPerPage > 0) {
			refreshPrintBatches(false, true);
		}
		//step_3b
		else if (offset > 0 && limitPrintBatchesPerPage > 0) {
			setPrintBatches([]);
			setOffset(0);
		}
	}, [searchText, filteredStatus, filteredPrinter]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_4
	useEffect(() => {
		if (limitPrintBatchesPerPage > 0) {
			refreshPrintBatches();
		}
	}, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_5
	useEffect(() => {
		if (resetPrintBatchesFilters) {
			setResetPrintBatchesFilters(false);

			if (filteredStatus || filteredPrinter || searchText) {
				setFilteredStatus(undefined);
				setFilteredPrinter(undefined);
				setSearchText("");
			} else if (offset > 0) {
				setPrintBatches([]);
				setOffset(0);
			}

			setSelectedPrintBatchesForBulkAction({});

			navigate(
				{
					pathname: location.pathname,
					search: `${PSEUDO_URL_QUERY_VALUES.OFFSET}=0&${PSEUDO_URL_QUERY_VALUES.LIMIT}=${limitPrintBatchesPerPage}`,
				},
				{ replace: true }
			);
		}
	}, [resetPrintBatchesFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ******************** Use cases END ************************ */

	/* ******************** Get orders API call ************************ */
	useEffect(() => {
		const { error } = useGetPrintBatches;
		if (error) {
			toast.error(`Unable to get print batches. ${error}`);
		}
	}, [useGetPrintBatches.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetPrintBatches;
		if (data.message) {
			setPrintBatches((prevPrintBatches) => [
				...prevPrintBatches,
				...data.message,
			]);
		}
	}, [useGetPrintBatches.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setIsLoadingPrintBatches(useGetPrintBatches.isLoading);
	}, [useGetPrintBatches.isLoading]);
	/* ****************************************************************** */

	/* ******************** functions START ***************************/

	const getAndSetFilters = () => {
		const params = new URLSearchParams(location.search);

		const status = params.get(PSEUDO_URL_QUERY_VALUES.STATUS);
		const printer = params.get(PSEUDO_URL_QUERY_VALUES.PRINTER);
		const searchText = params.get(PSEUDO_URL_QUERY_VALUES.SEARCH_TEXT);

		status && setFilteredStatus(status);
		printer && setFilteredPrinter(printer);
		searchText && setSearchText(searchText);

		return status || searchText;
	};

	const refreshPrintBatches = (
		forceRefresh: boolean = false,
		resetPrintBatchesList: boolean = false
	) => {
		const searchTextCleared = searchText?.trim();
		const filterBySearchText =
			searchTextCleared?.length > 0
				? `&filter=${encodeURIComponent(searchTextCleared)}`
				: "";
		const filterByStatus = filteredStatus
			? `&batchStatus=${encodeURIComponent(filteredStatus)}`
			: "";

		const filterByPrinter = filteredPrinter
			? `&printerId=${encodeURIComponent(filteredPrinter)}`
			: "";

		const filterQuery = `/${EndpointPrefix[account_type_id]}/data/printBatches?offset=${offset}&limit=${limitPrintBatchesPerPage}${filterBySearchText}${filterByStatus}${filterByPrinter}`;

		if (resetPrintBatchesList && filterQuery !== previousFilterQuery)
			setPrintBatches([]);

		if (filterQuery !== previousFilterQuery || forceRefresh) {
			if (forceRefresh) {
				setPrintBatches([]);
			}

			// set pseudo URL
			navigate(
				{
					pathname: location.pathname,
					search: toPseudonymUrlQuery(filterQuery),
				},
				{ replace: true }
			);

			useGetPrintBatches.doFetch(filterQuery);
			setPreviousFilterQuery(filterQuery);
		}
	};

	const toPseudonymUrlQuery = (filterQuery: string) => {
		const params = new URLSearchParams(filterQuery);

		const offset = params.get(
			`/${EndpointPrefix[account_type_id]}/data/printBatches?offset`
		);
		const searchText = params.get("filter")
			? `&${PSEUDO_URL_QUERY_VALUES.SEARCH_TEXT}=${params.get("filter")}`
			: "";

		const status = params.get("batchStatus")
			? `&${PSEUDO_URL_QUERY_VALUES.STATUS}=${params.get("batchStatus")}`
			: "";

		const printer = params.get("printerId")
			? `&${PSEUDO_URL_QUERY_VALUES.PRINTER}=${params.get("printerId")}`
			: "";

		const pseudoFilterQuery = `${PSEUDO_URL_QUERY_VALUES.OFFSET}=${offset}&${PSEUDO_URL_QUERY_VALUES.LIMIT}=${limitPrintBatchesPerPage}${searchText}${status}${printer}`;

		return pseudoFilterQuery;
	};

	const forceRefreshPrintBatches = () => {
		//step_6b
		if (offset > 0) {
			setPrintBatches([]);
			setOffset(0);
		}
		// step_6a
		else {
			refreshPrintBatches(true);
		}
	};

	/* ******************** functions END ***************************/

	const [printers, setPrinters] = useState([]);

	const useGetPrinters = useDataApi();

	useEffect(() => {
		fetchPrinters();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetPrinters;
		if (data && data.message) {
			setPrinters(data.message.printers);
		}
	}, [useGetPrinters.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrinters;
		if (error) {
			toast.error("Unable to get Printers list.");
		}
	}, [useGetPrinters.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchPrinters = () => {
		useGetPrinters.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printersList`
		);
	};

	return (
		<>
			<div className="orders-main__content" data-testid="ordersScreen">
				<div className="orders-container">
					<header data-testid="ordersHeader" className="orders-header">
						<div className="row">
							<div className="col-sm-4">
								<h1 className="section__title">Your Print Batches</h1>
							</div>
							<div className="col-xs-offset-5 col-sm-3 ordersSearchContainer">
								<SearchFilter
									setSearchText={setSearchText}
									searchTextValue={searchText}
									placeholderText="Enter style number or reference number"
								/>
							</div>
						</div>
						<div className="toolbarAndFiltersContainer">
							<PrintBatchesHeaderActionsToolbar
								setSelectedPrintBatchesForBulkAction={
									setSelectedPrintBatchesForBulkAction
								}
								selectedPrintBatchesForBulkAction={
									selectedPrintBatchesForBulkAction
								}
								forceRefreshPrintBatches={forceRefreshPrintBatches}
							/>
							<PrintBatchesHeaderFilters
								filteredStatus={filteredStatus}
								filteredPrinter={filteredPrinter}
								printers={printers}
								setFilteredStatus={setFilteredStatus}
								setFilteredPrinter={setFilteredPrinter}
								setSelectedPrintBatchesForBulkAction={
									setSelectedPrintBatchesForBulkAction
								}
							/>
						</div>
					</header>

					{isLoadingPrintBatches && printBatches.length === 0 && (
						<div style={{ height: "100%" }} className="flex-center-both-axis">
							<Loading
								show={true}
								text={`Loading...`}
								imgClass=""
								divClass=""
							/>
						</div>
					)}

					<div className="flex flex-1 pos-relative" ref={ordersRef}>
						{printBatches && printBatches.length > 0 && (
							<div className="orders-list">
								<PrintBatchesListHeader
									headerRef={headerRef}
									scrollPadding={scrollPadding}
								/>
								<section className="overflow-y-auto flex-1" ref={ordersListRef}>
									{printBatches.map((printBatch: any, index) => {
										return (
											<div
												key={printBatch.print_batch_id}
												style={{ height: "74px" }}
												data-testid={`orders-row-${index}`}
											>
												<PrintBatchesListItem
													refVar={
														printBatches.length === index + 1
															? lastOrderElementRef
															: null
													}
													printBatch={printBatch}
													selectedPrintBatchesForBulkAction={
														selectedPrintBatchesForBulkAction
													}
													setSelectedPrintBatchesForBulkAction={
														setSelectedPrintBatchesForBulkAction
													}
													filteredPrinter={filteredPrinter}
												/>
											</div>
										);
									})}
									<div>
										<Loading
											show={isLoadingPrintBatches}
											text={`Loading...`}
											imgClass=""
											divClass="center-lg"
										/>
									</div>
								</section>
							</div>
						)}

						{!isLoadingPrintBatches &&
							printBatches &&
							printBatches.length === 0 && (
								<div className="emptyOrdersContainer flex-1">
									<div className="box box--sm box--bordered" id="emptyOrders">
										<h1 className="text--quiet">No Print Batches</h1>
										<Icon name="printer" className="noOrdersIcon" />
										<div
											className="txtc text-sm text--quiet mt--base"
											style={{ whiteSpace: "pre-line" }}
										>
											You have no print batches at the moment.
										</div>
									</div>
								</div>
							)}
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintBatches;
