import React, { useContext, useState, useEffect } from "react";

import useIncrementalSearchHook from "Hooks/incrementalSearchHook";

import GlobalDispatch from "Dispatches/GlobalDispatch";
import OrderDispatch from "Dispatches/OrderDispatch";

import HelpTooltip from "Components/Shared/HelpTooltip";

import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import IncrementalSearchResults from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchResults";
import Popup from "Components/Shared/Popup";

import { IDefaultOption } from "Models/OrderModels";
import {
	checkMissingTranslationByName,
	isDpp,
	languagesForTranslationsCheck,
} from "Utils/utils";

interface IProps {
	madeInsList: IDefaultOption[];
	currentOrderItemIndex: number;
}

const BasicInformation: React.FunctionComponent<IProps> = ({
	madeInsList,
	currentOrderItemIndex,
}) => {
	const {
		orderItem,
		setOrderItem,
		order: { labelTemplate },
	} = useContext(OrderDispatch);
	const { setMissingTranslations } = useContext(GlobalDispatch);

	const { qr_phase } = labelTemplate?.settings?.default_values;

	const {
		styleNumber,
		styleDescription,
		season,
		customInformation,
		madeIn,
		allLabelLanguages,
		chosenLabelLanguages,
	} = orderItem;

	const [invalidMadeInMessage, setInvalidMadeInMessage] = useState("");
	const [focusOnSearchList, setFocusOnSearchList] = useState(false);

	const incrementalSearchHookProps = useIncrementalSearchHook(madeInsList);

	const { setInputText, setShowList } = incrementalSearchHookProps;

	useEffect(() => {
		// empty input without popup message - resolves missing first letter bug
		if (madeIn === -1) {
			setInputText("");
		}
		// empty input with popup message - resolves reset values bug
		if (!madeIn) {
			setInputText("");
			setOrderItem({ madeIn: -1 });
		}
	}, [currentOrderItemIndex]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// Edit added order item
		if (madeInsList) {
			const madeInOption = madeInsList.find(
				(madeInObject: IDefaultOption) => madeInObject.id === madeIn
			);
			if (madeInOption) {
				const madeInText = madeInOption.name;
				setInputText(madeInText);
				setInvalidMadeInMessage("");
			} else {
				if (madeIn === -1) {
					setInputText("");
				} else if (madeIn === undefined) {
					setInvalidMadeInMessage(
						"You must choose a valid made in from the list!"
					);
				} else {
					setInvalidMadeInMessage("");
				}
			}
		}
	}, [madeIn, madeInsList, setInputText]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChooseItemCallback = (item: any) => {
		if (item) {
			let languagesArrayForTranslationsCheck = languagesForTranslationsCheck(
				allLabelLanguages,
				chosenLabelLanguages,
				isDpp(qr_phase)
			);

			if (languagesArrayForTranslationsCheck.length > 0) {
				let missingMadeInTranslations = checkMissingTranslationByName(
					madeInsList,
					[item.name],
					languagesArrayForTranslationsCheck
				);
				if (Object.keys(missingMadeInTranslations).length > 0) {
					setMissingTranslations({
						missingMadeIn: missingMadeInTranslations,
					});
					setInputText("");
				} else {
					setOrderItem({ madeIn: item.id });
					setInputText(item.name);
				}
			}
		}
	};

	const onChooseItemFromList = (item: any) => {
		onChooseItemCallback(item);
		setShowList(false);
	};

	return (
		<>
			<div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>Basic Style Information</span>
				<HelpTooltip helpFor="BasicStyleInformation" />
			</div>
			<fieldset className="box box--light box--shadowed mb--md">
				<div className="form-group">
					<Popup
						show={styleNumber.length > 49}
						text={"Max number of characters is 50."}
						testId={"styleNumberInvalidMessage"}
					/>
					<input
						type="text"
						className="form-control"
						name="styleNumber"
						id="styleNumber"
						value={styleNumber}
						maxLength={50}
						data-empty={!styleNumber}
						onChange={(e) => setOrderItem({ styleNumber: e.target.value })}
						onBlur={(e) => setOrderItem({ styleNumber: e.target.value.trim() })}
					/>
					<label className="form-control-label" htmlFor="styleNumber">
						Style Number
					</label>
				</div>

				{!labelTemplate?.settings?.form_settings?.hide_style_description && (
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							name="styleDescription"
							id="styleDescription"
							value={styleDescription ? styleDescription : ""}
							data-empty={!styleDescription}
							onChange={(e) =>
								setOrderItem({ styleDescription: e.target.value })
							}
							onBlur={(e) =>
								setOrderItem({ styleDescription: e.target.value.trim() })
							}
						/>
						<label className="form-control-label" htmlFor="styleDescription">
							Style Description
						</label>
					</div>
				)}

				<div className="form-group">
					<input
						type="text"
						className="form-control"
						name="season"
						id="season"
						value={season ? season : ""}
						data-empty={!season}
						onChange={(e) => setOrderItem({ season: e.target.value })}
						onBlur={(e) => setOrderItem({ season: e.target.value.trim() })}
					/>
					<label className="form-control-label" htmlFor="season">
						Season
					</label>
				</div>

				{!labelTemplate?.settings?.form_settings?.hide_custom_info && (
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							name="customInformation"
							id="customInformation"
							value={customInformation ? customInformation : ""}
							data-empty={!customInformation}
							onChange={(e) =>
								setOrderItem({ customInformation: e.target.value })
							}
							onBlur={(e) =>
								setOrderItem({ customInformation: e.target.value.trim() })
							}
						/>
						<label className="form-control-label" htmlFor="customInformation">
							Custom Information
						</label>
					</div>
				)}

				<div className={`form-group ${invalidMadeInMessage && "has-danger"}`}>
					<IncrementalSearchInput
						labelText="Made In"
						name="madeIn"
						autoFocus={false}
						invalidMessage={invalidMadeInMessage}
						setFocusOnSearchList={setFocusOnSearchList}
						onChangeInputText={() => {
							setOrderItem({ madeIn: undefined });
						}}
						onInputBlur={onChooseItemCallback}
						incrementalSearchHookProps={incrementalSearchHookProps}
					/>

					<IncrementalSearchResults
						focusOnSearchList={focusOnSearchList}
						testId="madeIn"
						onChooseItemCallback={onChooseItemFromList}
						incrementalSearchHookProps={incrementalSearchHookProps}
					/>
				</div>
			</fieldset>
		</>
	);
};

export default BasicInformation;
