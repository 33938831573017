import AppDispatch from "Dispatches/AppDispatch";
import { useState, useEffect, useReducer, useRef, useContext } from "react";

import { useNavigate, useLocation } from "react-router-dom-v5-compat";

interface IFetchState {
	isLoading: boolean;
	error?: string;
	data: any;
	details: any;
}

interface IAction {
	type: string;
	payload?: any;
	errorMessage?: string;
	errorDetails?: any;
}

const dataFetchReducer = (state: IFetchState, action: IAction) => {
	switch (action.type) {
		case "FETCH_INIT":
			return {
				...state,
				isLoading: true,
				error: undefined,
				details: undefined,
			};
		case "FETCH_SUCCESS":
			return {
				...state,
				isLoading: false,
				error: undefined,
				details: undefined,
				data: action.payload,
			};
		case "FETCH_FAILURE":
			return {
				...state,
				isLoading: false,
				error: action.errorMessage,
				details: action.errorDetails,
			};
		default:
			throw new Error();
	}
};

const useDataApi = (): any => {
	const { setUser, setNewVersion } = useContext(AppDispatch);

	let URL_BASE = import.meta.env.VITE_APP_BACKEND_URL;
	if (!URL_BASE) {
		URL_BASE = "http://localhost:8080/l";
	}

	const navigate = useNavigate();
	const location = useLocation();

	const [parameters, setParameters] = useState({
		url: "",
		data: {},
		method: "GET",
	});

	const [state, dispatch] = useReducer(dataFetchReducer, {
		isLoading: false,
		error: "",
		data: {},
		details: undefined,
	});

	const customUrl = useRef<string>("");

	useEffect(() => {
		let didCancel = false;

		if (
			location.pathname !== "/login" &&
			location.pathname + location?.search !== "/orders"
		) {
			customUrl.current = location.pathname + location?.search;
		}

		const { url, method, data } = parameters;

		const fetchData = async () => {
			dispatch({ type: "FETCH_INIT" });

			try {
				const result = await fetch(url, {
					method,
					headers: [
						["Content-Type", "application/json"],
						["Version", `${import.meta.env.VITE_APP_VERSION_CUSTOM}`],
					],
					body: method === "GET" ? null : JSON.stringify(data),
					credentials: "include",
					mode: "cors",
				});
				if (result.status === 409) {
					setNewVersion(true);
				} else if (result.status === 403) {
					const json = await result.json();
					const { message } = json;

					dispatch({
						type: "FETCH_FAILURE",
						errorMessage: "Unauthorized access.",
						errorDetails: message,
					});
				} else if (
					!didCancel &&
					(result.status === 400 || result.status === 500)
				) {
					const json = await result.json();
					const { message, details } = json;
					dispatch({
						type: "FETCH_FAILURE",
						errorMessage: message,
						errorDetails: details,
					});
				} else if (result.status === 401) {
					// TODO
					// This is a quick fix.
					// We should handle this in a nicer way in the future,
					// working together with the backend and correct information in every situation.
					// Currently, we send 401 when user is not logged in,
					// which can happen on wrong credentials but also when user logs out in another
					// window and then tries to make a call from the current window.
					if (url.endsWith("/login")) {
						dispatch({
							type: "FETCH_FAILURE",
							errorMessage: "Incorrect email address or password.",
						});
					} else {
						dispatch({ type: "FETCH_FAILURE", errorMessage: "Not logged in." });
					}

					if (!url.endsWith("/users/current") && !url.endsWith("/login")) {
						setUser(undefined);
					}
					navigate("/login", { state: { destinationPath: customUrl.current } });
				} else if (result.status >= 400) {
					dispatch({ type: "FETCH_FAILURE", errorMessage: "Unknown error." });
				} else if (!didCancel) {
					if (result.headers.get("Content-Type") === "application/pdf") {
						const blob = await result.blob();
						dispatch({ type: "FETCH_SUCCESS", payload: blob });
					} else {
						const json = await result.json();
						// console.log('Fetch succeeded, result:', json)
						dispatch({ type: "FETCH_SUCCESS", payload: json });
					}
				}
			} catch (error) {
				console.log("Fetch failed, error:", error);

				if (!didCancel) {
					if (!navigator.onLine) {
						dispatch({
							type: "FETCH_FAILURE",
							errorMessage: "You are offline.",
						});
					} else {
						dispatch({
							type: "FETCH_FAILURE",
							errorMessage: "Error",
						});
					}
				}
			}
		};

		if (url.length > 0) {
			fetchData();
		}

		return () => {
			didCancel = true;
		};
	}, [parameters]); // eslint-disable-line react-hooks/exhaustive-deps

	const doFetch = (
		urlHandle: string,
		requestData = {},
		requestMethod = "GET"
	) => {
		const fullUrl = `${URL_BASE}${urlHandle}`;

		setParameters({
			url: fullUrl,
			data: requestData,
			method: requestMethod,
		});
	};

	return { ...state, doFetch };
};

export default useDataApi;
