import React, {
	useRef,
	useEffect,
	useCallback,
	useContext,
	useState,
	FC,
} from "react";
import { toast } from "react-toastify";

import Icon from "Components/Shared/Icon";
import useOnClickOutside from "Hooks/outsideClickHook";
import useDataApi from "Hooks/fetchHook";
import { EndpointPrefix } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import useOnKeyDown from "Hooks/onKeyDownHook";

import sprite from "images/icons.svg";
import Accordion from "./Accordion";
import { capitalize } from "Utils/utils";
import { Link } from "react-router-dom-v5-compat";
import Loading from "./Loading";

interface IProps {
	compatiblePrinters: any;
	incompatiblePrinters: any;
	chosenPrinter: any;
	printBatch: any;
	isLoadingFetchPrinters: boolean;

	setChosenPrinter: (printer: any) => void;
	setShowChoosePrinterModal: (showChoosePrinterModal: boolean) => void;
	fetchPrinters: () => void;
}

const ChoosePrinterModal: FC<IProps> = ({
	compatiblePrinters,
	incompatiblePrinters,
	chosenPrinter,
	printBatch,
	isLoadingFetchPrinters,

	setChosenPrinter,
	setShowChoosePrinterModal,
	fetchPrinters,
}) => {
	const modalRef = useRef(null) as any;

	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const useSetPrinter = useDataApi();

	const [showIncompatiblePrinters, setShowIncompatiblePrinters] =
		useState(false);

	useEffect(() => {
		fetchPrinters();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useSetPrinter;
		if (data && data.message) {
			const { printer_status_code, printer_status_name } = data.message;

			setChosenPrinter((prevPrinter: any) => {
				return {
					...prevPrinter,
					status_code: printer_status_code,
					status_name: printer_status_name,
				};
			});
			onCancelChoosePrinterModal();
			toast.success("Printer successfully chosen.");
		}
	}, [useSetPrinter.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSetPrinter;
		if (error) {
			setChosenPrinter(undefined);
			toast.error(`Choosing printer failed. ${error}`);
		}
	}, [useSetPrinter.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *******************************************************************************/
	const onChoosePrinter = (printer: any) => {
		useSetPrinter.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/assignPrinterToBatch`,
			{ print_batch_id: printBatch.print_batch_id, printer_id: printer.id },
			"POST"
		);
		setChosenPrinter(printer);
	};

	/* *******************************************************************************/
	const onCancelChoosePrinterModal = () => {
		setShowChoosePrinterModal(false);
	};

	/* *******************************************************************************/

	useOnKeyDown("Escape", onCancelChoosePrinterModal);

	/* *******************************************************************************/
	useOnClickOutside(
		modalRef,
		useCallback(onCancelChoosePrinterModal, [setShowChoosePrinterModal])
	); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="modal flex-center-both-axis">
			<div
				className="modal-content-order-summary show"
				ref={modalRef}
				tabIndex={1}
			>
				<div className="flex row reverse" style={{ height: "40px" }}>
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label="Close"
						onClick={onCancelChoosePrinterModal}
						style={{ position: "relative" }}
					>
						<Icon name="cross-rounded" />
					</button>
				</div>

				<div className="mt--md flex-center-both-axis">
					<h1>Choose Printer</h1>
				</div>

				<Loading
					show={isLoadingFetchPrinters}
					text={`Loading...`}
					imgClass="block-center"
					divClass="choose-printer-modal-loading pd--base"
				/>
				{!isLoadingFetchPrinters && (
					<>
						{/* Compatible printers */}
						<div className="mb--base">
							<div className="pretitle flex-center-secondary-axis">
								<span>Compatible printers</span>
							</div>
							{compatiblePrinters?.length > 0 ? (
								<>
									<div
										className="flex font-weight-500 mb--sm"
										style={{ padding: "0 10px" }}
									>
										<span className="flex-1"> Name</span>
										<span className="flex-1"> Materials</span>
										<span className="flex-1"> Status</span>
									</div>
									{compatiblePrinters.map((printer: any) => {
										const { color, material, width } = printer.settings.paper;
										return (
											<div
												key={printer.id}
												style={{
													cursor: `${
														chosenPrinter?.id === printer.id
															? "not-allowed"
															: ""
													}`,
												}}
											>
												<div
													className="choose-printer"
													key={printer.name}
													onClick={() => {
														onChoosePrinter(printer);
													}}
													style={{
														border: `${
															chosenPrinter?.id === printer.id
																? "2px solid lightgreen"
																: ""
														}`,
														pointerEvents: `${
															chosenPrinter?.id === printer.id ? "none" : "auto"
														}`,
														cursor: `${
															chosenPrinter?.id === printer.id
																? "not-allowed"
																: "pointer"
														}`,
													}}
												>
													<span className="flex-1"> {printer.name}</span>
													<span className="flex-1">
														{capitalize(color) +
															" " +
															capitalize(material) +
															" " +
															width +
															" mm"}
													</span>
													<span className="flex-1"> {printer.status_name}</span>
												</div>
											</div>
										);
									})}
								</>
							) : (
								<div
									className="font-weight-500 mb--sm"
									style={{ padding: "0 10px" }}
								>
									<div className="flex-1"> Missing compatible printers</div>
									<div>
										<Link
											data-testid="navigation-logout"
											className="link "
											to="/printers"
										>
											Printers page
										</Link>
									</div>
								</div>
							)}
						</div>

						{/* Incompatible printers */}
						<div className="mb--base">
							<div className="pretitle flex-center-secondary-axis">
								<span
									className="cursor-pointer flex flex-center-secondary-axis"
									onClick={() => {
										setShowIncompatiblePrinters((prev) => !prev);
									}}
								>
									<span>Incompatible printers</span>
									<span
										className="flex flex-center-secondary-axis"
										style={{
											paddingLeft: "10px",
										}}
									>
										<svg
											style={{
												height: "20px",
												width: "20px",
												fill: "black",
												margin: 0,
											}}
											className={`accordion-arrow-transform ${
												showIncompatiblePrinters ? "rotate-90" : "rotate-270"
											}`}
										>
											<use xlinkHref={`${sprite}#icon-double-left-arrow`} />
										</svg>
									</span>
								</span>
							</div>

							<Accordion isOpen={showIncompatiblePrinters}>
								{incompatiblePrinters?.length > 0 ? (
									<>
										<div
											className="flex font-weight-500 mb--sm"
											style={{ padding: "0 10px" }}
										>
											<span className="flex-1"> Name</span>
											<span className="flex-1"> Materials</span>
											<span className="flex-1"> Status</span>
										</div>
										{incompatiblePrinters.map((printer: any) => {
											const { color, material, width } = printer.settings.paper;
											return (
												<div key={printer.id} className="flex choose-printer">
													<span className="flex-1"> {printer.name}</span>
													<span className="flex-1">
														{capitalize(color) +
															" " +
															capitalize(material) +
															" " +
															width +
															" mm"}
													</span>
													<span className="flex-1"> {printer.status_name}</span>
												</div>
											);
										})}
									</>
								) : (
									<div
										className="flex font-weight-500 mb--sm"
										style={{ padding: "0 10px" }}
									>
										<span className="flex-1"> No incompatible printers</span>
									</div>
								)}
							</Accordion>
						</div>
						<div
							className="flex flex-center-both-axis mt--base"
							style={{ justifyContent: "space-evenly" }}
						>
							<button
								className="button button--primary button--lg mb--lg"
								onClick={onCancelChoosePrinterModal}
							>
								Cancel
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ChoosePrinterModal;
