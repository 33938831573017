import { useContext, useEffect, useState } from "react";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../react-query/constants";
import {
	FETCH_GET_PARAMS,
	URL_BASE,
	CustomError,
} from "../../Models/QueryModels";
import useErrorHandler from "Hooks/queryHooks/useErrorHandler";
import { toast } from "react-toastify";
import {
	EndpointPrefix,
	USER_ACCOUNT_TYPE_VALUES,
} from "../../Models/UserModels";

async function manageError(response: Response) {
	if (!response.ok) {
		let responseJSON = await response.json();
		throw new CustomError(responseJSON.message, response.status);
	}
	return response;
}

let url = "";

async function getHelpVideoNames(
	account_type_id: USER_ACCOUNT_TYPE_VALUES
): Promise<any> {
	url = `${URL_BASE.address}/${EndpointPrefix[account_type_id]}/help/videos`;
	const result = await fetch(url, FETCH_GET_PARAMS).then(manageError);
	return await result.json();
}

const transformData = (data: string[]) => {
	let transformedData: { id: number; name: string }[] = [];

	data.forEach((videoName: string, index: number) => {
		transformedData.push({ id: index, name: videoName });
	});

	return transformedData;
};

export function useFetchHelpVideoNames(): any {
	const errorHandler = useErrorHandler();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const { data, isError, error, isSuccess } = useQuery({
		queryKey: [QUERY_KEYS.HELP_VIDEO_NAMES],
		queryFn: () => getHelpVideoNames(account_type_id),
		...{
			gcTime: 86400000, // 1 day
			staleTime: 1800000, // 30 minutes
		},
	});

	const [transformedData, setTransformedData] = useState<
		{ id: number; name: string }[] | undefined
	>(undefined);

	if (isError) {
		let customError = error as CustomError;
		let errorMessage = errorHandler(
			customError.status,
			customError.message,
			url
		);
		toast.error(`Unable to get Help videos names list. ${errorMessage}`);
	}

	useEffect(() => {
		if (isSuccess) {
			let transformedData = transformData([...data.message]);

			setTransformedData(transformedData);
		}
	}, [isSuccess]);

	return transformedData;
}
